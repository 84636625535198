<template>
  <div class="h-full flex flex-col account-page">
    <PageTitle>
      {{ t('Account') }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" />
    <RouterView />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { computed, ref } from "vue"
import { ArrowDownOnSquareStackIcon, BriefcaseIcon, QueueListIcon, SparklesIcon } from "@heroicons/vue/24/outline"

const { t } = useI18n()
const activeTab = ref('/settings/company/profile')

const tabs = computed(() => {
  return [
    {
      label: t('Company details'),
      path: `/settings/company/details`,
      icon: BriefcaseIcon,
    },
    {
      label: t('AI Context'),
      path: `/settings/company/ai-context`,
      icon: SparklesIcon,
    },
    {
      label: t('Modules'),
      path: `/settings/company/modules`,
      icon: QueueListIcon,
    },
    {
      label: t('Data'),
      path: `/settings/company/data`,
      icon: ArrowDownOnSquareStackIcon,
    },
  ]
})
</script>

<route lang="yaml">
name: Company Settings
meta:
  permission: manageCompany
</route>
