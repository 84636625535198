<template>
  <div
    class="fixed left-0 bottom-0 top-0 w-[280px] bg-white z-[5000] transition-all duration-150"
    :class="{
      'translate-x-0': modelValue,
      '-translate-x-full': !modelValue,
    }"
  >
    <div class="relative">
      <div>
        <BaseButton size="sm" variant="link" class="!absolute right-0 top-0" @click="close">
          <XMarkIcon class="w-5 h-5 text-gray-600 hover:text-gray-900" />
        </BaseButton>
      </div>
      <BaseFormCard
        :show-actions="false"
        class="flex-1"
      >
        <FormCardSection class="h-full border-none !px-4">
          <div
            v-if="isCalendarConnected"
            v-loading="personalSharedTimelineEventsLoading"
            class="col-span-6"
          >
            <div
              class="text-gray-600 col-span-6 text-sm mt-2 mb-1"
            >
              {{ title }}
            </div>
            <div class="max-w-md">
              <FormKit
                :model-value="selectedCalendar"
                type="googleCalendarSelect"
                :placeholder="$t('Choose calendar to sync with')"
                :provider="Providers.GoogleEmployee"
                class="col-span-3 z-[5000]"
                @update:model-value="onCalendarChange"
              />
            </div>
            <div class="space-y-2 mt-4 overflow-y-auto max-h-[calc(100vh-120px)]">
              <div
                v-for="event in eventList"
                :key="event.id"
                class="border border-gray-200 rounded-sm px-4 py-2 hover:bg-primary/10 hover:cursor-pointer"
                @click="onEventClick(event)"
              >
                <span class="font-semibold text-sm">{{ event.attributes.title }}</span>
                <div class="text-sm">
                  {{ formatDate(event.attributes.start_date, 'HH:mm') }} - {{ formatDate(event.attributes.end_date, 'HH:mm') }}
                </div>
                <div class="text-sm">
                  {{ getDuration(event) }} {{ t('hours') }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isCalendarConnected" class="col-span-6 pb-2">
            <IconBox size="sm" class="mb-3">
              <GoogleCalendarIcon class="w-7 h-7" />
            </IconBox>
            <div class="prose prose-sm mb-6">
              <h5>
                {{
                  $t(`You haven't connected any Google Calendar yet. Connecting a Google Calendar account will:`)
                }}
              </h5>
              <ul>
                <li>
                  {{ $t('Allow you to sync your Google Calendar events to your Growee account') }}
                </li>
                <li>
                  {{ $t('Allow you to pull in calendar events and log time for them in an easy way') }}
                </li>
              </ul>
            </div>
            <BaseButton
              outline
              variant="primary"
              :loading="calendarsDataLoading"
              @click="onConnect"
            >
              <GoogleCalendarIcon class="w-5 h-5 mr-2" />
              <span>{{ $t('Connect Calendar') }}</span>
            </BaseButton>
          </div>
        </FormCardSection>
      </BaseFormCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/outline"
import { useStorage } from "@vueuse/core"
import { startCase } from "lodash-es"
import { computed, onMounted, watch } from "vue"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { differenceInMinutes, parseISO } from "date-fns"
import { formatDate } from "@/modules/common/utils/dateUtils"
import { Providers, useCalendarIntegration } from "@/modules/settings/composables/useCalendarIntegration"
import GoogleCalendarIcon from "@/modules/settings/components/icons/GoogleCalendarIcon.vue"
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore"

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'eventClick'])
const { t } = useI18n()

const title = computed(() => {
  const date = startCase(formatDate(props.date, 'EEEE, dd MMM'))
  return `${date} - ${t('events from')}`
})

function close() {
  emit('update:modelValue', false)
}

const {
  calendarsDataLoading,
  selectedCalendar,
  isCalendarConnected,
  onConnect,
  connect,
  disconnect,
} = useCalendarIntegration(Providers.GoogleEmployee)

const dashboardStore = useDashboardStore()
const { personalSharedTimelineEvents, personalSharedTimelineEventsLoading } = storeToRefs(dashboardStore)

const eventList = computed(() => {
  return personalSharedTimelineEvents.value.google_calendar_events || []
})

async function onCalendarChange(calendarId: string) {
  await connect(calendarId)
  await getData(true)
}

function getDurationInMinutes(event: any) {
  const endDate = parseISO(event.attributes.end_date)
  const startDate = parseISO(event.attributes.start_date)
  return differenceInMinutes(endDate, startDate)
}

function getDuration(event: any) {
  const durationInMinutes = getDurationInMinutes(event)
  return (durationInMinutes / 60).toFixed(2)
}

async function getData(invalidateCache = false) {
  if (!isCalendarConnected.value) {
    return
  }
  await dashboardStore.getPersonalCalendarEvents({
    invalidateCache,
    start_date: props.date,
    end_date: props.date,
    type: 'personal-calendar',
  })
}

watch(() => props.date, () => {
  getData(true)
})

const mappedEvents = useStorage<Record<string, any>>('mappedPersonalCalendarEvents', {}, localStorage)

function onEventClick(event: any) {
  const duration = getDurationInMinutes(event)
  const description = event?.attributes?.title
  const { project_id, task_id } = mappedEvents.value[description] || {}
  emit('eventClick', {
    event,
    duration,
    description,
    project_id,
    task_id,
  })
}

onMounted(() => {
  getData()
})
</script>

<style>
</style>
