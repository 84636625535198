<template>
  <BaseButton
    variant="primary"
    outline
    size="sm"
    @click="switchViewMode"
  >
    <RectangleGroupIcon v-if="modelValue === ViewModes.List" class="w-4 h-4 mr-2"/>
    <ListBulletIcon v-else-if="modelValue === ViewModes.Group" class="w-4 h-4 mr-2"/>
    {{ viewModeText }}
  </BaseButton>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ListBulletIcon, RectangleGroupIcon } from "@heroicons/vue/24/outline"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import { ViewModes } from "@/modules/expenses/utils/expenseUtils"

const props = defineProps({
  modelValue: {
    type: String,
    default: ViewModes.List,
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const viewModeText = computed(() => {
  const map = {
    [ViewModes.List]: t('Switch to bundle view'),
    [ViewModes.Group]: t('Switch to grid view'),
  }
  return map[props.modelValue] || map[ViewModes.Group]
})

function switchViewMode() {
  const newValue = props.modelValue === ViewModes.List ? ViewModes.Group : ViewModes.List
  emit('update:modelValue', newValue)
}
</script>

<style>
</style>
