<template>
  <BaseFormCard
    :disabled="loading"
    :submit-text="isEdit ? $t('Update') : $t('Create')"
    :is-dialog="isDialog"
    width="70vw"
    v-bind="$attrs"
    @submit="emit('submit')"
    @close="emit('cancel', $event)"
  >
    <FormCardSection
      :isDialog="isDialog"
    >
      <slot></slot>
    </FormCardSection>
    <template #footer>
      <slot name="footer"/>
    </template>
    <template #footer-before>
      <slot name="footer-before"/>
    </template>
  </BaseFormCard>
</template>
<script lang="ts" setup>
const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDialog: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel', 'close'])
</script>
