import axios from 'axios'
import { defineStore } from 'pinia'
import Cache from '@/modules/common/Cache'
import { useAuthStore } from '@/modules/auth/store/authStore'
import { DashboardData } from "@/modules/dashboard/models/dashboardModels"
import i18n from "@/i18n"
import { error, success } from "@/components/common/NotificationPlugin"
import { NewsStatus } from "@/modules/dashboard/components/news/newsUtils"

export const DataTypes = {
  Holiday: 'holidays',
  Evaluation: 'evaluations',
  Document: 'documents',
  Root: 'timelines',
  UpcomingMembers: 'upcomingMembers',
  UpcomingBirthdays: 'upcomingBirthdays',
}

export const TimelineTypes = {
  Holiday: 'holiday',
  NationalHoliday: 'national_holiday',
  Announcement: 'announcement',
  News: 'news',
  Personal: 'personal',
  Evaluation: 'evaluation',
  Birthday: 'birthday',
  LegalHoliday: 'legal_holiday',
  ExternalCalendar: 'external_calendar',
  Other: 'other',
}

export const useDashboardStore = defineStore('dashboard', {
  state: () => {
    return {
      timelineEvents: [] as any,
      dashboardData: {
        legal_holidays: [],
        holidays: [],
        evaluations: [],
        birthdays: [],
        timelines_personal: [],
      } as DashboardData,
      sharedTimelineEvents: [] as any,
      sharedTimelineEventsLoading: false,
      personalSharedTimelineEvents: [] as any,
      personalSharedTimelineEventsLoading: false,
      timelineEventsLoading: false,
      myActivity: {} as any,
      myActivityLoading: false,
      companyNews: {} as any,
      companyNewsLoading: false,
    }
  },
  actions: {
    async getCalendarEvents({ project_ids, start_date, end_date }: {
      project_ids: string[]
      start_date?: string
      end_date?: string
    }) {
      try {
        this.timelineEventsLoading = true
        const projectIds = JSON.stringify(project_ids)
        const query = `?event_types=["holidays", "evaluations", "legal_holidays", "birthdays", "timelines_personal"]&project_ids=${projectIds}`
        const res = await axios.get(`/restify/employees/getters/dashboard-events${query}`, {
          params: {
            start_date,
            end_date,
          },
        })
        this.dashboardData = res.data
      } finally {
        this.timelineEventsLoading = false
      }
    },
    async getSharedCalendarEvents(params: any) {
      try {
        this.sharedTimelineEventsLoading = true
        this.sharedTimelineEvents = await Cache.getRequest(`/calendar/${params.id}`, {
          params,
        })
      } finally {
        this.sharedTimelineEventsLoading = false
      }
    },
    async getPersonalSharedCalendarEvents(params: any) {
      try {
        this.personalSharedTimelineEventsLoading = true
        const { data } = await Cache.getRequest(`/calendar/${params.id}`, {
          params: {
            ...params,
            id: undefined,
          },
        })
        this.personalSharedTimelineEvents = data
      } finally {
        this.personalSharedTimelineEventsLoading = false
      }
    },
    async getPersonalCalendarEvents(params: any) {
      try {
        this.personalSharedTimelineEventsLoading = true
        const { data } = await Cache.getRequest(`/calendar`, {
          params,
        })
        this.personalSharedTimelineEvents = data
      } finally {
        this.personalSharedTimelineEventsLoading = false
      }
    },
    async shareCalendar() {
      const { data } = await axios.post('/restify/shareables', {
        type: 'calendar',
      })
      return data
    },
    async getMyActivity(force = false) {
      try {
        if (force) {
          this.myActivity = {}
        }
        const authStore = useAuthStore()
        this.myActivityLoading = true
        const page = this.myActivity?.meta?.current_page + 1 || 1
        const lastPage = this.myActivity?.meta?.last_page
        if (lastPage && page > lastPage) {
          return
        }
        const data: any = await axios.get('/restify/timelines', {
          params: {
            employee_id: authStore.userEmployeeId,
            related: 'timelineable',
            page: this.myActivity?.meta?.current_page + 1 || 1,
          },
        })
        if (page === 1) {
          this.myActivity = data
        } else {
          this.myActivity.meta = data?.meta
          this.myActivity.data = this.myActivity.data.concat(data.data)
        }
      } finally {
        this.myActivityLoading = false
      }
    },
    async getCompanyNews(force = false) {
      try {
        if (force) {
          this.companyNews = {}
        }
        this.companyNewsLoading = true
        const page = this.companyNews?.meta?.current_page + 1 || 1
        const lastPage = this.companyNews?.meta?.last_page
        if (lastPage && page > lastPage) {
          return
        }
        const data: any = await axios.get('/restify/news', {
          params: {
            temporary_urls: true,
            sort: '-created_at',
            page: this.companyNews?.meta?.current_page + 1 || 1,
          },
        })
        if (page === 1) {
          this.companyNews = data
        } else {
          this.companyNews.meta = data?.meta
          this.companyNews.data = this.companyNews.data.concat(data.data)
        }
      } finally {
        this.companyNewsLoading = false
      }
    },
    async createCompanyNews(requestData: any) {
      try {
        const { data } = await axios.post(`/restify/news`, requestData, {
          params: {
            temporary_urls: true,
          },
        })
        this.companyNews.data.unshift(data)
        success(i18n.t('The post has been created as draft. If you want to publish it, use the 3 dots menu.'))
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('Could not create the post'))
      }
    },
    async publishNews(news: any) {
      try {
        await axios.post(`/restify/news/${news.id}/actions?action=publish-news`, {})
        const index = this.companyNews.data.findIndex((item: any) => item.id === news.id)
        news.attributes.published_at = new Date().toISOString()
        news.attributes.status = NewsStatus.Published
        if (index) {
          this.companyNews.data[index] = news
        }
        success(i18n.t('The post has been published'))
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('Could not create the post'))
      }
    },
    async setNewsAsFeatured(news: any, featured = true) {
      try {
        const requestData = {
          featured,
        }
        await axios.patch(`/restify/news/${news.id}`, requestData)
        const index = this.companyNews.data.findIndex((item: any) => item.id === news.id)
        news.attributes.featured = featured
        if (index) {
          this.companyNews.data[index] = news
        }
        if (featured) {
          success(i18n.t('The post has been set as featured'))
        } else {
          success(i18n.t('The post has been removed from featured'))
        }
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('Could not update the post'))
      }
    },
    async deleteNews(news: any) {
      try {
        await axios.delete(`/restify/news/${news.id}`)
        const index = this.companyNews.data.findIndex((item: any) => item.id === news.id)
        this.companyNews.data.splice(index, 1)
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('Could not delete the post'))
      }
    },
  },
  getters: {
    featuredNews(): any[] {
      return this.companyNews.data?.filter((news: any) => news.attributes.featured) || []
    },
  },
})
