<template>
  <BaseContentCard
    v-if="featuredNews.length"
    class="featured-news relative overflow-hidden !pb-0"
  >
    <BackgroundBubble class="absolute z-0 left-[-90px] top-[-217px] rotate-[16deg] h-[390px] w-auto"/>
    <div class="flex items-center space-x-5 mb-5">
      <IconBox size="sm">
        <SparklesIcon class="w-4 h-4"/>
      </IconBox>
      <h3>{{ $t('Featured News') }}</h3>
    </div>
    <NewsEventsList
      :data="featuredNews"
      :loading="companyNewsLoading"
      class="h-auto"
    />
  </BaseContentCard>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { SparklesIcon } from "@heroicons/vue/24/outline"
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore"
import PostNewsInput from "@/modules/dashboard/components/news/PostNewsInput.vue"
import NewsEventsList from "@/modules/dashboard/components/news/NewsEventsList.vue"
import BackgroundBubble from "@/modules/dashboard/components/news/BackgroundBubble.vue";

const dashboardStore = useDashboardStore()
const { featuredNews, companyNewsLoading } = storeToRefs(dashboardStore)
</script>
