<template>
  <span>
    {{ $formatPrice(amount, { currency }) || '- -' }}
    {{ t(getSalaryTypeLabel(type)) }}
  </span>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { getSalaryTypeLabel } from "@/modules/employees/utils/employeeUtils"

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
  amount: {
    type: Number,
    required: true,
  },
})

const { t } = useI18n()
</script>

<style>
</style>
