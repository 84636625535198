<template>
  <BaseTooltip
    :content="$t(`${formattedWorkedHours} / ${formattedTotalHours} `) + $t('hours')"
  >
    <div
      class="relative flex flex-wrap items-center md:space-x-2"
    >
      <div class="h-[20px] w-[150px] border border-gray-300 flex rounded overflow-hidden mt-2 md:mt-0">
        <div
          :style="getStyles"
          class="h-full"
        />
      </div>
      <div
        v-if="taskPercentage > 100"
        class="h-[calc(100%+2px)] absolute -top-[2px] right-[10px]"
      >
        <svg class="h-full" viewBox="0 0 16 53" xmlns="http://www.w3.org/2000/svg"><path d="m5 5 8 8-8 8 8 8-8 8 8 8-8 8" stroke="white" stroke-width="6" fill="none" /></svg>
      </div>
    </div>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { dangerColor, primaryColor } from '@/modules/reports/enum/reportEnums'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
})
const { t } = useI18n()

const totalHours = computed(() => {
  return props.item?.total_available_hours || 0
})

const workedHours = computed(() => {
  return (props.item?.total_worked_minutes || 0) / 60
})

const formattedWorkedHours = computed(() => {
  return (+workedHours.value)?.toFixed(2)
})

const formattedTotalHours = computed(() => {
  return (+totalHours.value)?.toFixed(2)
})

const taskPercentage = computed(() => {
  return (workedHours.value / totalHours.value) * 100
})

const getStyles = computed(() => {
  const percent = taskPercentage.value
  const width = `${percent}%`
  const backgroundColor = percent <= 100 ? primaryColor : dangerColor
  return {
    width,
    backgroundColor,
  }
})
</script>
