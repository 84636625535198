<template>
  <div>
    <div
      v-if="value === ChecklistTaskAssigneeTypes.Employee"
      class="flex items-center space-x-1">
      <BaseAvatar
        class="rounded-full w-6 h-6 min-w-[24px]"
        :name="t('Employee')"
        src="/avatars/avatar3.png"
      />
      <div class="font-semibold truncate">
        {{ t('Employee') }}
      </div>
    </div>
    <div
      v-else-if="value === ChecklistTaskAssigneeTypes.DirectManager"
      class="flex items-center space-x-1">
      <BaseAvatar
        class="rounded-full w-6 h-6 min-w-[24px]"
        :name="t('Direct Manager')"
        src="/avatars/avatar2.png"
      />
      <div class="font-semibold truncate">
        {{ t('Direct Manager') }}
      </div>
    </div>
    <EmployeeLink v-else :params="{ value }" :show-link="false" />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ChecklistTaskAssigneeTypes } from "@/modules/settings/components/checklists/utils"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue";

const props = defineProps({
  value: {
    type: String,
  },
})

const { t } = useI18n()
</script>

