<template>
  <router-view/>
</template>

<script lang="ts" setup>
import { useReportsStore } from "@/modules/reports/store/reportsStore"

const reportStore = useReportsStore()
reportStore.resetIdFilters()
</script>

<route lang="yaml">
name: Capacity report
</route>
