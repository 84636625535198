<template>
  <BaseContentCard class="my-4 print:my-0 !h-auto reports-card min-h-[calc(100vh-90px)]">
    <template v-if="!isDetailedReport">
      <ReportHeader />
      <Tabs v-if="showTabs" :tabs="tabs" />
    </template>
    <router-view />
  </BaseContentCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { can, isModuleVisible } from '@/plugins/permissionPlugin'
import ReportHeader from '@/modules/reports/components/ReportHeader.vue'
import { isSpecialCompany } from "@/modules/common/config"
import { ModuleKeys } from "@/modules/auth/types/enums";

const { t } = useI18n()
const tabs = computed(() => {
  const tabs: any[] = [
    {
      label: t('Clients'),
      path: '/reports/clients',
      show: can('manageInvoices') && isSpecialCompany() && isModuleVisible(ModuleKeys.Invoices),
    },
    {
      label: t('Projects'),
      path: '/reports/projects',
      show: isModuleVisible(ModuleKeys.Time),
    },
    {
      label: t('Tasks'),
      path: '/reports/tasks',
      show: isModuleVisible(ModuleKeys.Time),
    },
    {
      label: t('Team'),
      path: '/reports/team',
      show: isModuleVisible(ModuleKeys.Time),
    },
    {
      label: t('Capacity'),
      path: '/reports/capacity',
      show: isSpecialCompany() && isModuleVisible(ModuleKeys.Holidays) && can('manageEmployees'),
    },
    {
      label: t('Presence sheet'),
      path: '/reports/presence',
      show: can('manageTimesheets'),
    },
  ]
  return tabs.filter(tab => tab.show !== false)
})

const route = useRoute()
const isDetailedReport = computed(() => {
  return route.path.includes('/detailed')
})

const showTabs = computed(() => {
  return !route.params.id
})
</script>

<route lang="yaml">
name: Reports
redirect: /reports/team
</route>
