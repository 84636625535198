import i18n from "@/i18n"

export const defaultCurrency = 'EUR'
function isInt(n: number) {
  return n % 1 === 0
}

export const getCurrencySymbol = (currency: string) => {
  const locale = i18n.locale
  return (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()
}

export function formatPrice(value: number, options: any = {}) {
  if (value === null || value === undefined) {
    return '- -'
  }

  try {
    let formatter
    const locale = options.locale || 'en-US'
    if (options.currencyDisplay === false) {
      formatter = new Intl.NumberFormat(locale, {
        maximumFractionDigits: options.maximumFractionDigits || 2,
        minimumFractionDigits: options.maximumFractionDigits !== undefined ? options.maximumFractionDigits : 2,
      })
    } else {
      let fractionDigits = options.maximumFractionDigits !== undefined ? options.maximumFractionDigits : 2

      if (isInt(value) && !options.maximumFractionDigits) {
        fractionDigits = 0
      }

      formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: options.currency || defaultCurrency,
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      })
    }
    return formatter.format(value)
  } catch (err) {
    console.log(err)
    return value
  }
}
