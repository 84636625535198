<template>
  <div class="h-full flex flex-col data-page">
    <BaseFormCard
      :submit-text="$t('Update')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
    >
      <FormCardSection :title="$t('Company details')">
        <FormKit
          v-model="model[SettingKeys.CompanyName]"
          name="company_name"
          type="text"
          :label="t('Company Name')"
          :placeholder="t('My Company')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyAdmin]"
          name="company_admin"
          type="text"
          :label="t('Administrator')"
          :placeholder="t('John Doe')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyEmail]"
          name="company_email"
          type="text"
          :label="t('Contact Email')"
          :placeholder="t('hello@company.com')"
          validation="email"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyRegNumber]"
          name="company_registration_number"
          type="text"
          :label="t('Register No.')"
          :placeholder="t('J12/1234/2018')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyIdentificationNumber]"
          name="company_identification_number"
          type="text"
          :label="t('VAT Number')"
          :placeholder="t('RO32392318')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.DefaultCurrency]"
          name="default_currency"
          type="currencySelect"
          :label="t('Default currency')"
          :placeholder="t('EUR')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.CompanyStreet]"
          name="company_street_address"
          type="text"
          :label="t('Company street address')"
          :placeholder="t('Startdust Way 23')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model[SettingKeys.CompanyCity]"
          name="company_city"
          type="text"
          :label="t('City')"
          :placeholder="t('Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model[SettingKeys.CompanyState]"
          name="company_county"
          type="text"
          :label="t('State')"
          :placeholder="t('Cluj')"
          outer-class="col-span-6 md:col-span-2"
        />
      </FormCardSection>
      <FormCardSection :title="$t('Settings')">
        <FormKit
          v-model="model[SettingKeys.ShowTimesheetDates]"
          type="checkbox"
          :label="t('Show time sheet dates')"
          :help="t('When enabled, it will display when users logged their time in the reports.')"
          outer-class="col-span-6 mt-2"
        />

        <FormKit
          v-model="model[SettingKeys.IncludeHolidayWeekends]"
          type="checkbox"
          :label="t('Include weekends in holiday calculation')"
          :help="t('When enabled, the weekends will be included in the holiday calculation.')"
          outer-class="col-span-6 mt-6"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettings } from "@/modules/settings/composables/useSettings"

const { t } = useI18n()

const { model, loading, updateSettings } = useSettings([
  SettingKeys.ShowTimesheetDates,
  SettingKeys.IncludeHolidayWeekends,
  SettingKeys.CompanyName,
  SettingKeys.CompanyIdentificationNumber,
  SettingKeys.CompanyRegNumber,
  SettingKeys.CompanyStreet,
  SettingKeys.CompanyCity,
  SettingKeys.CompanyState,
  SettingKeys.CompanyAdmin,
  SettingKeys.CompanyEmail,
  SettingKeys.DefaultCurrency,
  SettingKeys.HiddenModules,
])

async function onSubmit() {
  try {
    loading.value = true
    await updateSettings()
  } finally {
    loading.value = false
  }
}
</script>

<route lang="yaml">
name: Company Details
meta:
  permission: manageCompany
</route>
