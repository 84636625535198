<template>
  <div
    :id="activity.id"
    class="rounded-sm px-2 flex space-x-2 news-content"
  >
    <div class="flex-1">
      <div class="flex items-start space-x-2">
        <h4 class="flex-1">
          {{ t(activity.attributes.title) }}
        </h4>
        <div class="mt-1">
          <Badge v-if="isDraft">
            {{ t('Draft') }}
          </Badge>
          <Badge
            v-if="isFeatured"
            status="primary"
          >
            <SparklesIcon class="w-4 h-4 mr-1"/>
            <span>{{ t('Featured') }}</span>
          </Badge>
        </div>
      </div>
      <div v-if="activity.attributes.published_at" class="text-xs text-base-300">
        {{ formatDate(activity.attributes.published_at) }}
      </div>
      <HtmlEditor
        :model-value="activity.attributes.content"
        :read-only="!editable"
        :show-top-bar="false"
        :show-footer="false"
        :enforce-title="editable"
      />
      <PendingFileInfo
        v-if="activity.attributes.file"
        :file="activity.attributes.file"
        :file-name="activity.attributes.file_name"
        :can-remove="editable"
        :can-preview="true"
      />
    </div>
    <div>
      <ActionsDropdown
        v-if="actions && $can('manageNews')"
        :actions="actions"
      >
        <template #trigger>
          <BaseButton variant="link" size="xs">
            <EllipsisHorizontalIcon class="w-5 h-5 text-primary" />
          </BaseButton>
        </template>
      </ActionsDropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  EllipsisHorizontalIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  SparklesIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import { formatDate } from "../../../common/utils/dateUtils"
import ActionsDropdown from "@/components/common/ActionsDropdown.vue"
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"
import { can } from "@/plugins/permissionPlugin"
import { NewsStatus } from "@/modules/dashboard/components/news/newsUtils"
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore"
import { TableActions } from "@/components/table/tableUtils"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"
import { error } from "@/components/common/NotificationPlugin"
import PendingFileInfo from "@/modules/documents/components/PendingFileInfo.vue";
import HtmlEditor from "@/components/formkit/html/HtmlEditor.vue";

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const dashboardStore = useDashboardStore()

const isDraft = computed(() => {
  return props.activity.attributes?.status === NewsStatus.Draft
})

const isFeatured = computed(() => {
  return props.activity.attributes?.featured === true
})

const editable = ref(false)

const actions = computed<RowAction[]>(() => {
  return [
    {
      icon: PaperAirplaneIcon,
      label: t('Publish'),
      action: async () => {
        await dashboardStore.publishNews(props.activity)
      },
      show: () => {
        return props.activity?.attributes?.status === NewsStatus.Draft
      },
    },
    {
      icon: SparklesIcon,
      label: t('Set as featured'),
      action: async () => {
        await dashboardStore.setNewsAsFeatured(props.activity, true)
      },
      show: () => {
        const { status, featured } = props.activity?.attributes || {}
        return !featured && status === NewsStatus.Published
      },
    },
    {
      icon: SparklesIcon,
      label: t('Remove from featured'),
      action: async () => {
        await dashboardStore.setNewsAsFeatured(props.activity, false)
      },
      show: () => {
        return props.activity?.attributes?.featured
      },
    },
    {
      label: i18n.t('Delete'),
      type: TableActions.Delete,
      icon: TrashIcon,
      action: async () => {
        const confirmed = await $deleteConfirm({
          title: t('Are you sure ?'),
          description: t('Are you sure you want to delete this news post ? The data will be removed on our servers. This action cannot be undone.'),
        })
        if (!confirmed) {
          return
        }
        try {
          await dashboardStore.deleteNews(props.activity)
        } catch (err: any) {
          if (err.handled) {
            return
          }
          error(i18n.t('Could not delete the news post'))
        }
      },
      show: () => {
        return can('manageNews')
      },
    },
  ]
})
</script>

<style lang="scss">
.news-content #text-editor .ProseMirror {
  min-height: auto;
  @apply px-0 pb-0;
}
</style>
