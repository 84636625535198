<template>
  <SettingsCrudPage
    ref="table"
    :title="$t('Company levels')"
    :modal-component="LevelModal"
    :add-text="$t('Add level')"
    :edit-text="$t('Edit level')"
    :url-params="urlParams"
    :group-default-expanded="-1"
    :suppress-agg-func-in-header="true"
    :columns="columns"
    group-display-type="groupRows"
    entity="levels"
  >
    <template #after-title>
      <PositionTabs />
    </template>
  </SettingsCrudPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { ColDef, ValueSetterParams } from "@ag-grid-community/core"
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue"
import LevelModal from "@/modules/settings/components/LevelModal.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import PositionTabs from "@/modules/settings/components/positions/PositionTabs.vue"

const urlParams = {
  related: 'department',
  sort: 'name',
}
const table = ref()
const { t } = useI18n()

const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.name',
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.name = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
  {

    headerName: t('Description'),
    field: 'attributes.description',
    editable: true,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.description = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
])
</script>

<route lang="yaml">
name: Levels
meta:
  permission: managePositions
</route>
