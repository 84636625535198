<template>
  <EmployeeOnboardingTasks
    :employee-id="currentEmployee.id"
    :default-expanded="true"
  />
</template>

<script setup>
import { storeToRefs } from "pinia"
import EmployeeOnboardingTasks from "@/modules/employees/components/EmployeeOnboardingTasks.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"

const employeeStore = useEmployeeStore()
const {
  currentEmployee,
} = storeToRefs(employeeStore)
</script>

<route lang="yaml">
name: Employee Onboarding Tasks
</route>
