<template>
  <div
    :class="{
      'min-h-[500px]': domLayout !== 'autoHeight',
      'is-editable': editable,
    }"
    class="h-full flex flex-col"
  >
    <div class="my-4 flex flex-col-reverse items-center xs:flex-row flex-wrap gap-4 justify-between">
      <div class="flex flex-wrap items-center gap-6">
        <slot name="title">
          <h2 v-if="title || $slots.title">
            {{ title }}
          </h2>
        </slot>
        <FormKit
          v-if="!hideSearch"
          v-model="searchQuery"
          type="text"
          :placeholder="t('Search...')"
          prefix-icon="magnifying-glass"
          :classes="{
            input: '!py-2 !leading-[20px] max-w-[200px] md:max-w-full',
          }"
          @update:modelValue="onSearch(searchQuery)"
        />
        <TableEditingTips v-if="editable" class="-ml-4"/>
        <div v-if="hasAction(actions, TableActions.Refresh) || url && !hideRefresh" class="-mx-4">
          <TableRefreshButton @click="fetchData" />
        </div>
        <div v-if="$slots.filters || filters.length">
          <slot name="filters">
            <TableFilters
              v-model="filterParams"
              :filters="filters"
              class="hidden md:flex"
            />
          </slot>
        </div>
      </div>
      <div class="flex justify-end items-center space-x-2 xs:ml-auto">
        <slot name="actions-before" />
        <TableActionsDropdown
          v-if="hasAction(actions, TableActions.BulkActions) || $slots['bulk-actions']"
          class="hidden md:block"
        >
          <slot name="bulk-actions" :selected-rows="selectedRows" :filters="filterParams" />
        </TableActionsDropdown>
        <BaseTooltip :disabled="billingStore.hasActiveSubscription" effect="light">
          <template #content>
            <div class="max-w-sm p-2 text-sm">
              <div v-if="can('manageBilling')">
                <span>{{ t('Plan inactive description') }}</span>
                <div class="mt-2 flex justify-end">
                  <router-link class="btn btn-sm btn-primary btn-outline" to="/settings/billing">
                    {{ t('Update plan') }}
                  </router-link>
                </div>
              </div>
              <span v-else>
                {{ t('This account is currently restricted. Please contact your administrator') }}
              </span>
            </div>
          </template>
          <button
            v-if="hasAction(actions, TableActions.Add)"
            :data-test="`add_${entityName}_button`"
            type="button"
            class="btn btn-sm btn-primary"
            @click="gridContext.onAdd"
          >
            <PlusIcon class="w-4 h-4 mr-1" />
            {{ addText || t(`Add entity`, { entityName }) }}
          </button>
        </BaseTooltip>
        <ExportAction
          v-if="hasAction(actions, TableActions.Export)"
          :entity="entity"
          :request-params="requestParams"
          :columns="metaData?.available_fields_to_export"
          :url="url"
        />
        <slot name="header-actions-right" />
      </div>
    </div>
    <AgGridVue
      v-bind="$attrs"
      class="ag-theme-alpine flex-1"
      :column-defs="tableColumns"
      :column-types="columnTypes"
      :row-data="tableData"
      :default-col-def="defaultColDef"
      :loading-overlay-component="LoadingTable"
      :no-rows-overlay-component="NoRowsTable"
      :server-side-sort-on-server="true"
      :context="gridContext"
      :pagination="$attrs.pagination !== undefined ? $attrs.pagination : !isRemote"
      :pagination-page-size="pagination.perPage"
      :dom-layout="computedDomLayout"
      :undo-redo-cell-editing="true"
      :undo-redo-cell-editing-limit="100"
      animate-rows
      suppress-row-click-selection
      :enable-cell-text-selection="!canCopyCells"
      row-model-type="clientSide"
      row-selection="multiple"
      :cell-selection="true"
      :get-row-id="_getRowId"
      @grid-ready="onGridReady"
      @sort-changed="onSortChanged"
      @selection-changed="onSelectionChanged"
      @cell-key-down="onCellKeyDown"
    />
    <div v-if="isRemote && !hidePagination" class="flex justify-center items-center my-4 px-2 pagination-wrapper">
      <ElPagination
        v-model:currentPage="pagination.page"
        v-model:page-size="pagination.perPage"
        background
        layout="total, sizes, prev, pager, next"
        size="small"
        class="table-pagination"
        :page-sizes="[10, 15, 25, 50, 100]"
        :total="pagination.total"
      />
    </div>

    <component
      :is="dialogForm"
      v-if="showEditDialog"
      :key="editDialogKey"
      v-model="showEditDialog"
      :is-dialog="true"
      :is-edit="true"
      :data="rowToEdit"
      :title="editText || t('Edit')"
      @close="showEditDialog = false"
      @cancel="showEditDialog = false"
      @save="onUpdateSuccess"
    />

    <component
      :is="dialogForm"
      v-if="showCreateDialog"
      key="create-dialog"
      v-model="showCreateDialog"
      :is-dialog="true"
      :title="createText || t('Create')"
      @close="showCreateDialog = false"
      @cancel="showCreateDialog = false"
      @save="onCreateSuccess"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, useSlots, watch } from 'vue'
import { ArrowPathIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { AgGridVue } from '@ag-grid-community/vue3' // the AG Grid Vue Component
import { CellKeyDownEvent, ColDef, Column, GridApi, GridReadyEvent, ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { useStorage } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { debounce } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import LoadingTable from './LoadingTable.vue'
import NoRowsTable from './NoRowsTable.vue'
import TableActionsDropdown from './TableActionsDropdown.vue'

import '@ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'
import { usePaginatedRequest } from '@/modules/common/composables/usePaginatedRequest'
import { TableActions, getSortProp, hasAction, isColumnSortable, setLicenseKey } from '@/components/table/tableUtils'
import { actionsColumn, getColumnTypes } from '@/components/table/cells/tableColumnTypes'
import ExportAction from '@/components/table/ExportAction.vue'
import { RowAction } from '@/components/table/tableTypes'
import { FilterType } from '@/components/table/filters/filterTypes'
import TableFilters from '@/components/table/filters/TableFilters.vue'
import { EmployeeStatus } from '@/modules/employees/utils/employeeUtils'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import { useBillingStore } from '@/modules/settings/store/billingStore'
import { can } from '@/plugins/permissionPlugin'
import { isMobile } from "@/util/responsiveUtils"
import TableRefreshButton from "@/components/table/TableRefreshButton.vue"
import { useTableEditing } from "@/components/table/useTableEditing"
import { tableCellEditors } from "@/components/table/cells/tableCellComponents"
import TableEditingTips from "@/modules/expenses/components/TableEditingTips.vue";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  dataLoading: {
    type: Boolean,
    default: false,
  },
  url: {
    type: String,
    default: '',
  },
  urlParams: {
    type: Object,
    default: () => ({}),
  },
  transformData: {
    type: Function,
  },
  columns: {
    type: Array,
    default: () => [],
  },
  actions: {
    type: String,
    default: '',
  },
  entity: {
    type: String,
  },
  createText: {
    type: String,
  },
  editText: {
    type: String,
  },
  addText: {
    type: String,
  },
  title: {
    type: String,
  },
  deleteTitle: {
    type: String,
  },
  deleteDescription: {
    type: String,
  },
  extraActions: {
    type: Array as PropType<RowAction[]>,
    default: () => [],
  },
  editUrl: {
    type: String,
  },
  viewUrl: {
    type: String,
  },
  deleteExtraConfirmation: {
    type: Boolean,
  },
  dialogForm: {
    type: [String, Object],
  },
  filterRowActions: {
    type: Function,
  },
  localSearch: {
    type: Boolean,
    default: false,
  },
  domLayout: {
    type: String,
    default: 'normal',
  },
  hideSearch: {
    type: Boolean,
    default: false,
  },
  hideRefresh: {
    type: Boolean,
    default: false,
  },
  showDeleteFunction: {
    type: Function,
  },
  hidePagination: {
    type: Boolean,
    default: false,
  },
  getEmptyRow: {
    type: Function,
  },
  addRowOnTab: {
    type: Boolean,
    default: false,
  },
  addNewRowAtTop: {
    type: Boolean,
    default: false,
  },
  canCopyCells: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  getRowId: {
    type: Function,
  },
})
const emit = defineEmits(['add', 'edit', 'view', 'delete', 'selectionChange', 'dataUpdated', 'gridReady', 'gridInit', 'afterCreate'])
const { t } = useI18n()

setLicenseKey()
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RangeSelectionModule,
  MasterDetailModule,
  RowGroupingModule,
  ClipboardModule,
])

const filterParams = ref<any>({})
const showEditDialog = ref(false)
const showCreateDialog = ref(false)
const billingStore = useBillingStore()

const parsedFilterParams = computed(() => {
  const parsedParams: any = {}
  for (const key in filterParams.value) {
    const value = filterParams.value[key]
    if (key === 'status' && value === EmployeeStatus.Archived) {
      parsedParams.archived = true
      parsedParams[key] = undefined
    } else {
      parsedParams[key] = value
      parsedParams.archived = undefined
    }
  }
  return parsedParams
})

const computedDomLayout = computed(() => {
  if (isMobile() && props.domLayout === 'normal') {
    return 'autoHeight'
  }
  return props.domLayout
})
const _getRowId = (params: any) => {
  if (props.getRowId) {
    return props.getRowId(params)
  }
  return params?.data?.id || params?.data?.entity_id || params?.data?._localId
}

const gridApi = ref<GridApi | null>(null) // Optional - for accessing Grid's API
const grid = ref<GridReadyEvent | null>(null) // Optional - for accessing Grid's API
const selectedRows = ref<any[]>([])

const { addRow } = useTableEditing(grid, props)

const defaultColDef: ColDef = {
  sortable: false,
  resizable: true,
  unSortIcon: true,
  suppressMenu: true,
  flex: 1,
  comparator: props.url ? () => 0 : undefined,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  suppressMovable: isMobile(),
  icons: {
    sortAscending: `<svg class="w-4 h-4 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
</svg>
`,
    sortUnSort: `<svg class="w-4 h-4 text-base-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
</svg>
`,
    sortDescending: `<svg class="w-4 h-4 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
</svg>`,
  },
}
const editDialogKey = ref(0)
const slots = useSlots()

const rowToEdit = ref(null)
const gridContext = reactive({
  storageKey: 'default',
  filtersUrl: '',
  filterOptions: {
    sortables: [] as any[],
    matches: [] as any[],
  },
  matchFilters: {},
  advancedFilters: [],
  fullMatchFilters: {},
  displayMatchFilters: {},
  lastRequestParams: {},
  perPageOptions: [5, 10, 15, 20, 25, 50, 100],
  onAdd: () => {
    if (!billingStore.hasActiveSubscription) {
      return
    }
    if (props.dialogForm) {
      showCreateDialog.value = true
    }
    emit(TableActions.Add)
  },
  onEdit: (row: any) => {
    if (props.dialogForm) {
      rowToEdit.value = row
      showEditDialog.value = true
      editDialogKey.value++
    }
    emit(TableActions.Edit, row)
  },
})

const columnTypes = getColumnTypes({
  slots,
  props,
  emit,
  gridContext,
})

const isRemote = computed(() => !!props.url)
const showActionsColumn = computed(() => {
  if (props.extraActions?.length) {
    return true
  }
  return [TableActions.View, TableActions.Edit, TableActions.Delete].some(action => props.actions?.includes(action))
})

const filters = computed<FilterType[]>(() => {
  return props.columns.filter((c: any) => c.filter && !c.initialHide).map((c: any) => c.filter)
})

gridContext.storageKey = props.url + JSON.stringify(props.urlParams) + props.entity
const storageColumns = useStorage(gridContext.storageKey, [])

const tableColumns = computed(() => {
  let columns: any[] = [...props.columns]
  if (slots.actions || showActionsColumn.value) {
    columns.push(actionsColumn)
  }
  if (storageColumns.value) {
    columns.forEach((column) => {
      const matchingColumn: any = storageColumns.value.find((storageColumn: ColDef) => {
        return storageColumn.field === column.field && storageColumn.headerName === column.headerName
      })

      if (matchingColumn) {
        column.hide = matchingColumn?.hide
      }
    })
  }

  columns = columns.map((col: ColDef) => {
    col.sortable = isColumnSortable(col, gridContext)
    const field = col.field as string
    if (slots[field]) {
      col.type = 'custom'
    }
    return col
  })
  return columns
})

const { data, loading, fetchData, pagination, requestParams, metaData } = usePaginatedRequest({
  url: props.url,
  urlParams: props.urlParams,
  transformData: props.transformData,
  gridApi,
  gridContext,
  syncUrl: true,
})

const tableData = computed(() => {
  if (isRemote.value) {
    return data.value
  }
  return props.data
})

watch(() => tableData.value, (value) => {
  emit('dataUpdated', value)
})
watch(() => loading.value, async (value) => {
  if (tableData.value?.length === 0 && !value) {
    await nextTick()
    gridApi?.value?.showNoRowsOverlay()
  }
})

watch(() => gridContext.storageKey, (newValue) => {
  gridContext.storageKey = newValue
})

const entityName = computed(() => {
  return props.entity || ''
})

function onGridReady(params: any) {
  grid.value = params
  gridApi.value = params.api
  emit('gridReady', params.api)
  emit('gridInit', params)

  if (!tableData.value?.length && (loading.value || props.dataLoading)) {
    gridApi?.value?.showLoadingOverlay()
  }
}

const route = useRoute()
const searchQuery = ref(route.query.search || '')
const debouncedRemoteSearch = debounce(onRemoteSearch, 200)
function onSearch(query: string) {
  if (isRemote.value && !props.localSearch) {
    debouncedRemoteSearch(query)
  } else {
    gridApi.value?.setQuickFilter(query)
  }
}

async function onRemoteSearch(query: string) {
  if (!isRemote.value) {
    return
  }
  requestParams.value.search = query
  await refresh()
}

async function onSortChanged(params: any) {
  if (!isRemote.value) {
    return
  }
  const sortProp = getSortProp(params, gridApi.value)

  await refresh({
    sort: sortProp,
  })
}

function onSelectionChanged() {
  if (!gridApi.value) {
    return
  }
  selectedRows.value = gridApi.value.getSelectedRows()
  emit('selectionChange', selectedRows.value)
}

function initFilterParams() {
  if (Object.keys(route.query).length === 0) {
    return
  }
  filterParams.value = {
    ...filterParams.value,
    ...route.query,
  }
}

function getTableData() {
  const data: any[] = []
  gridApi.value?.forEachNode((node) => {
    data.push(node.data)
  })
  return data
}

function isLastCellFocused(rowIndex: number | null, columnDef?: ColDef) {
  const data = getTableData()
  const lastRowFocused = props.addNewRowAtTop
    ? rowIndex === 0
    : rowIndex === (data.length - 1)

  const columns = props.columns.filter(col => !col.hide)
  const { field } = columns.at(-1) as Column
  const lastColumnFocused = field === columnDef?.field
  return lastRowFocused && lastColumnFocused
}

async function onCellKeyDown(params: CellKeyDownEvent) {
  const { rowIndex, colDef } = params
  const event = params.event as KeyboardEvent

  if (!props.addRowOnTab || event?.key !== 'Tab' || event?.shiftKey) {
    return
  }

  if (!isLastCellFocused(rowIndex, colDef)) {
    return
  }
  onAddRow(params)
}

function onAddRow() {
  addRow()
}

initFilterParams()

onMounted(async () => {
  await fetchData()
})

onBeforeUnmount(() => {
  gridApi.value = null
})

function refresh(extraParams = {}) {
  const params = {
    force: true,
    ...(props.urlParams || {}),
    ...(parsedFilterParams.value || {}),
    search: requestParams.value.search,
    ...extraParams,
  }
  return fetchData(params)
}

function onUpdateSuccess() {
  showCreateDialog.value = false
  showEditDialog.value = false
  rowToEdit.value = null
  refresh()
}

function onCreateSuccess(data) {
  showCreateDialog.value = false
  showEditDialog.value = false
  emit('afterCreate', data)
  refresh()
}

watch(() => parsedFilterParams.value, async () => {
  await nextTick()
  await refresh()
}, { deep: true })

watch(() => route.query.add, (value) => {
  if (value) {
    gridContext.onAdd()
  }
}, { immediate: true })

defineExpose({
  fetchData,
  refresh,
  tableData,
  onAdd: () => gridContext.onAdd(),
  ...tableCellEditors,
})
</script>

<style lang="scss">
.ag-theme-alpine, .ag-theme-alpine-dark {
  --ag-alpine-active-color: theme('colors.primary');
  --ag-selected-row-background-color: theme('colors.primary/10%');
  --ag-input-focus-border-color: theme('colors.primary/10%');
  --ag-range-selection-background-color: theme('colors.primary/10%');
  --ag-range-selection-background-color-2: theme('colors.primary/15%');
  --ag-range-selection-background-color-3: theme('colors.primary/20%');
  --ag-range-selection-background-color-4: theme('colors.primary/25%');
  --ag-odd-row-background-color: transparent;
  --ag-row-hover-color: theme('colors.primary/10%');
  --ag-column-hover-color: theme('colors.primary-content');
  --ag-header-foreground-color: theme('colors.base-content');
  --ag-header-background-color: theme('colors.base-100');
  --ag-background-color: theme('colors.base-100');
  --ag-border-color: theme('colors.base-200');
  --ag-row-border-color: theme('colors.base-200');
  --ag-checkbox-unchecked-color: theme('colors.base-content/15%');
  --ag-foreground-color: theme('colors.base-content/80%');
  --ag-font-family: theme('fontFamily.sans');
  --ag-icon-size: 20px;
  --ag-grid-size: 5px;

  .ag-root-wrapper {
    @apply rounded-md;
  }

  .ag-cell {
    @apply flex items-center;
  }

  .ag-cell-wrapper {
    @apply truncate w-full;
  }

  .ag-cell .ag-input-field-input {
    @apply input input-bordered;
  }

  .ag-header-cell-text {
    @apply font-semibold text-sm;
  }

  .ag-center-cols-clipper {
    min-height: 300px !important;
  }
  .ag-right-aligned-header {
    @apply flex-row-reverse;
  }
  .ag-header-cell.header-editable .ag-header-cell-text:after {
    content: url(/icons/edit-icon.svg);
    position: absolute;
    @apply w-4 h-4 absolute opacity-50;
    right: 20px;
    top: 12px;
  }
  .ag-header-cell.header-editable.header-editable-left .ag-header-cell-text:after {
    left: 20px;
  }
}

.is-editable .ag-cell.ag-cell-not-inline-editing {
  @apply px-2.5;
}

.table-pagination {
  .el-input__wrapper {
    height: 34px !important;
    @apply text-sm;
  }

  .el-input,
  .el-input__inner {
    min-height: 32px;
    height: 32px;
  }
}
</style>
