<template>
  <div class="h-full flex flex-col data-page">
    <BaseFormCard
      class="flex-1"
      :show-actions="false"
    >
      <FormCardSection :title="$t('Download all documents')">
        <div class="col-span-6 mt-4">
          <BaseButton
            type="button"
            outline
            :classes="{ input: 'min-w-[128px]' }"
            :disabled="downloadLoading"
            @click="downloadAllDocuments"
          >
            <CloudArrowDownIcon class="w-4 h-4 mr-2" />
            <span>{{ $t('Download documents') }}</span>
          </BaseButton>
        </div>
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageBilling')"
        :title="$t('Delete company account')"
      >
        <div class="col-span-6 mt-4">
          <BaseButton
            type="button"
            outline
            @click="onDeleteAccount"
          >
            {{ t('Delete company account') }}
          </BaseButton>
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import axios from "axios"
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useAuthStore } from "@/modules/auth/store/authStore"
import { success } from "@/components/common/NotificationPlugin"

const { t } = useI18n()

const settingsStore = useSettingsStore()
const authStore = useAuthStore()
const router = useRouter()

async function onDeleteAccount() {
  const confirmed = await $confirm({
    title: t('Delete company account!'),
    description: t(`We will send a confirmation code via email which you will need to enter to delete the account. Are you sure you want to delete the account?`),
    buttonText: t('Delete'),
  })
  if (!confirmed) {
    return
  }
  await axios.post(`/tenant/send-delete-code`)
  await $confirm({
    title: t('Delete company account!'),
    description: t(`Delete company account description`),
    buttonText: t('Delete'),
    hasInput: true,
    inputType: 'otp',
    inputLabel: t('Enter confirmation code sent via email'),
    onResolve: async (data: any) => {
      const code = data.extraInputText
      await settingsStore.deleteTenant({
        code,
      })
      authStore.logout()
      await router.push('/login')
    },
  })
}

const downloadLoading = ref(false)

async function downloadAllDocuments() {
  try {
    downloadLoading.value = true
    const archive_name = `${new Date().toISOString()}-documents.zip`
    await axios.post(`/restify/documents/actions?action=download-all-documents`, {
      archive_name,
    })
    success(t('An email with a link to download the archive has been sent to your email address.'))
  } finally {
    downloadLoading.value = false
  }
}
</script>

<route lang="yaml">
name: Company Data
meta:
  permission: manageCompany
</route>
