import { defineStore } from 'pinia'
import axios from "axios"
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline"
import { parseISO } from "date-fns"
import i18n from "@/i18n"
import { formatDate } from "@/modules/common/utils/dateUtils"
import { success } from "@/components/common/NotificationPlugin"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { downloadFileLocally } from "@/modules/common/utils/downloadFileLocally"
import Holiday = App.Domains.Holidays.Models.Holiday
import Data = API.Data
import { ExportFormats, canEditHoliday, isHolidayPending } from "@/modules/holidays/utils/holidayUtils"
import Cache from "@/modules/common/Cache"
import { can } from "@/plugins/permissionPlugin"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useSettingsStore } from "@/modules/settings/store/settingsStore";

export const useHolidayStore = defineStore('holidays', {
  state: () => {
    return {
      currentHoliday: {} as Data<Holiday>,
      currentHolidayLoading: false as boolean,
      currentHolidayError: null as any,
      exportLoading: false,
      plannerHolidays: [] as Data<Holiday>[],
      plannerHolidaysLoading: false,
    }
  },
  actions: {
    async getHoliday(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentHolidayLoading = true
        const { data } = await axios.get(`/restify/holidays/${id}`, {
          params: {
            temporary_urls: true,
            related: 'documents',
          },
        })
        this.currentHoliday = data
        this.currentHolidayError = null
      } catch (err: any) {
        this.currentHolidayError = err
      } finally {
        this.currentHolidayLoading = false
      }
    },

    async updateHoliday(data: any) {
      if (!data.id) {
        return
      }
      await axios.put(`/restify/holidays/${data.id}`, data)
      await this.getHoliday(data.id)
    },
    async approveHoliday(row: Data<Holiday>) {
      await axios.post(`/restify/holidays/${row.id}/actions?action=approve`, {})
      row.attributes.approved_at = new Date().toISOString()
      success(i18n.t('Holiday request approved. The employee will be notified via email.'))
      Cache.removeForEntity('timelines')
      Cache.removeForEntity('holidays')
    },
    async rejectHoliday(row: Data<Holiday>) {
      let status_explanation = ''
      const confirmed = await $confirm({
        title: i18n.t('Reject Holiday request?'),
        description: i18n.t('When you reject a holiday request, the employee will be notified via email. The balance of the employee will not be affected.'),
        hasInput: true,
        inputLabel: i18n.t('Reject reason'),
        inputType: 'textarea',
        icon: ExclamationCircleIcon,
        buttonText: i18n.t('Reject'),
        onResolve: async ({ extraInputText }) => {
          status_explanation = extraInputText
        },
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/holidays/${row.id}/actions?action=reject`, {
        status_explanation,
      })
      row.attributes.rejected_at = new Date().toISOString()
      success(i18n.t('Holiday request was rejected. The employee will be notified via email.'))
      Cache.removeForEntity('timelines')
      Cache.removeForEntity('holidays')
    },
    async cancelHoliday(row: Data<Holiday>) {
      await axios.post(`/restify/holidays/${row.id}/actions?action=cancel`, {})
      row.attributes.cancelled_at = new Date().toISOString()
      success(i18n.t('Holiday request was cancelled. The employee will be notified via email.'))
      Cache.removeForEntity('timelines')
    },
    async exportHolidays(format = ExportFormats.Xlsx, extraFilters: any = {}) {
      let start_date, end_date
      const filters = {
        ...extraFilters,
      }
      if (filters.employee_id) {
        filters.employee_ids = [filters.employee_id]
        delete filters.employee_id
      }
      try {
        this.exportLoading = true
        const data = await axios.post(`/restify/holidays/actions?action=export`, {
          repositories: "all",
          format,
          columns: {
            name: i18n.t("Employee"),
            type: i18n.t("Type"),
            start_date: i18n.t("Start Date"),
            end_date: i18n.t("End Date"),
            paid: i18n.t("Paid"),
            days_off: i18n.t("Days off"),
            status: i18n.t("Status"),
          },
          holiday_filters: filters,
        }, { responseType: 'blob' })
        let interval = ''
        const dateFormat = 'dd-MM-yyyy'
        if (start_date && end_date) {
          interval = `-${formatDate(start_date, dateFormat)}_${formatDate(end_date, dateFormat)}`
        }
        const fileName = i18n.t('Holidays fileName', { interval })
        downloadFileLocally(data, `${fileName}.${format}`)
      } finally {
        this.exportLoading = false
      }
    },
    canCancelHoliday(holiday: Data<Holiday>) {
      const { start_date, cancelled_at, rejected_at, holiday_policy_id } = holiday?.attributes || {}
      if (cancelled_at || rejected_at) {
        return false
      }
      const settingsStore = useSettingsStore()
      const holidayPolicy = settingsStore.getPolicyById(holiday_policy_id)
      if (can('manageHolidays')) {
        return true
      }
      if (holidayPolicy?.id && !holidayPolicy?.attributes?.can_cancel_own_holidays_until_start_date) {
        return false
      }
      const startDate = parseISO(start_date)
      const now = new Date()
      return now < startDate
    },
    canApproveHoliday(holiday: Data<Holiday>) {
      if (!can('approveRejectHolidays')) {
        return
      }
      return isHolidayPending(holiday)
    },
    canRejectHoliday(holiday: Data<Holiday>) {
      if (!can('approveRejectHolidays')) {
        return
      }
      return isHolidayPending(holiday)
    },
    canEditHoliday(holiday: Data<Holiday>) {
      const isPending = isHolidayPending(holiday)
      if (isPending) {
        return isPending
      }
      return can('manageHolidays') && canEditHoliday(holiday)
    },
    async getPlannerHolidays(params: any = {}) {
      try {
        const { userEmployeeId } = useAuth()
        this.plannerHolidaysLoading = true
        const { data } = await axios.get('/restify/holidays', {
          params: {
            employee_id: userEmployeeId.value,
            ...params,
          },
        })
        this.plannerHolidays = data
      } finally {
        this.plannerHolidaysLoading = false
      }
    },
  },
  getters: {
    holidayName(state) {
      let { start_date, end_date } = state.currentHoliday?.attributes || {}
      start_date = formatDate(start_date)
      end_date = formatDate(end_date)
      return i18n.t(`Holiday for`, { start_date, end_date })
    },
  },
})
