<template>
  <div>
    <PageTitle class="mb-2">
      {{ title }}
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? $t('Update') : $t('Add')"
      :disabled="loading"
      @submit="createOrUpdatePolicy"
      @cancel="onCancel"
    >
      <FormCardSection v-if="!loading">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <Bars3BottomLeftIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Details') }}
          </h4>
          <h5>{{ $t('Establish the basic info of your policy') }}</h5>
        </div>

        <FormKit
          v-model="model.type"
          type="customSelect"
          :options="HolidayTypeOptions"
          :label="$t('Holiday type')"
          :placeholder="$t('Rest')"
          validation="required"
          outer-class="col-span-3 lg:col-span-2"
        />

        <FormKit
          v-model="model.name"
          type="text"
          :label="$t('Policy name')"
          :placeholder="$t('Time off')"
          validation="required"
          outer-class="col-span-3 lg:col-span-2"
        />

        <FormKit
          v-model="model.description"
          type="textarea"
          rows="4"
          :label="$t('Description')"
          :placeholder="$t('Paid time off that can be used throughout the year. Starts with 21 days.')"
          validation="length:0,255"
          outer-class="col-span-6 lg:col-span-4"
        />

        <div class="col-span-6 mt-12">
          <IconBox size="sm" class="mb-3">
            <DocumentTextIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Include Contract types') }}
          </h4>
          <h5>{{ $t('Add the type of contracts that this policy applies to') }}</h5>
        </div>

        <FormKit
          v-model="model.applicable_contract_types"
          type="customSelect"
          :options="contractTypeOptions"
          multiple
          :label="$t('Contract Type')"
          :placeholder="$t('Choose contract type')"
          outer-class="col-span-6 lg:col-span-4"
        />

        <div class="col-span-6 mt-12">
          <IconBox size="sm" class="mb-3">
            <HandRaisedIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Limit') }}
          </h4>
          <h5>{{ $t('How much time off can employees take?') }}</h5>
        </div>

        <FormKit
          v-model="daysRadio"
          type="radio"
          :label="$t('Choose one option')"
          :options="limitOptions"
          outer-class="col-span-6"
        />

        <FormKit
          v-if="daysRadio === LimitLabels.Limited"
          v-model="model.days_per_year"
          type="number"
          :label="$t('Total')"
          outer-class="col-span-3 lg:col-span-2"
        >
          <template #suffix>
            <div class="input-suffix text-base-300 text-sm">
              {{ $t('days / year') }}
            </div>
          </template>
        </FormKit>

        <template v-if="daysRadio === LimitLabels.Limited && isEdit && showBalanceUpdateMessage">
          <FormKit
            v-model="model.update_balances"
            type="radio"
            :label="$t('Apply limit change to all members?')"
            :options="yesNoOptions"
            outer-class="col-span-6 mt-4"
          />
          <div class="text-red-500 font-medium col-span-6 text-sm mt-2">
            <template v-if="balanceDiff > 0">
              {{
                $t('Update balance message', { count: balanceDiff })
              }}
            </template>
            <template v-else>
              {{
                $t('Update balance message negative', { count: Math.abs(balanceDiff) })
              }}
            </template>
          </div>
        </template>

        <template v-if="isTimeOffType">
          <div class="col-span-6 mt-12">
            <IconBox size="sm" class="mb-3">
              <SparklesIcon class="w-4 h-4" />
            </IconBox>
            <h4 class="mb-3">
              {{ $t('Earned') }}
            </h4>
            <h5>{{ $t('Extra days for company loyalty') }}</h5>
          </div>

          <FormKit
            v-model="milestonesRadio"
            type="radio"
            :label="$t('Choose one option')"
            :options="yesNoOptions"
            outer-class="col-span-6"
          />

          <div
            v-for="(milestone, index) in model.milestones"
            :key="index"
            class="group grid grid-cols-6 gap-x-4 col-span-6"
          >
            <FormKit
              v-model="milestone.years_in_company"
              type="number"
              :label="$t('Anniversary')"
              outer-class="col-span-3 lg:col-span-2"
            >
              <template #suffix>
                <div class="input-suffix text-base-300 text-sm">
                  {{ $t('Year') }}
                </div>
              </template>
            </FormKit>

            <FormKit
              v-model="milestone.days_per_year"
              type="number"
              :label="$t('Total')"
              outer-class="col-span-3 lg:col-span-2"
            >
              <template #suffix>
                <div class="input-suffix text-base-300 text-sm">
                  {{ $t('days / year') }}
                </div>
              </template>
            </FormKit>
            <div class="col-span-3 lg:col-span-2 flex h-full items-end">
              <div class="hidden group-hover:flex mb-2">
                <button
                  type="button"
                  class="btn btn-xs btn-square btn-primary btn-outline"
                  @click="removeMilestone(index)"
                >
                  <XMarkIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="milestonesRadio"
            class="col-span-6 mt-4"
          >
            <AddButton class="btn-outline btn-sm" @click="addMilestone">
              {{ $t('Add milestone') }}
            </AddButton>
          </div>

          <div class="col-span-6 mt-12">
            <IconBox size="sm" class="mb-3">
              <ArrowUturnRightIcon class="w-4 h-4" />
            </IconBox>
            <h4 class="mb-3">
              {{ $t('Carry over') }}
            </h4>
            <h5>{{ $t('The remaining days can be carried over to the next year') }}</h5>
          </div>

          <FormKit
            v-model="model.carry_over"
            type="radio"
            :label="$t('Choose one option')"
            :options="yesNoOptions"
            outer-class="col-span-6"
          />

          <template v-if="!isUnlimited">
            <div class="col-span-6 mt-12">
              <IconBox size="sm" class="mb-3">
                <ForwardIcon class="w-4 h-4" />
              </IconBox>
              <h4 class="mb-3">
                {{ $t('Allow advance booking') }}
              </h4>
              <h5 class="mb-2">
                {{ $t('Enable this setting to let users request holidays for the upcoming year. Approved holidays will be deducted from the new year’s balance which will be automatically created when a holiday for the next year is approved.') }}
              </h5>
            </div>
            <FormKit
              v-model="model.allow_advance_booking"
              type="radio"
              :label="$t('Choose one option')"
              :options="yesNoOptions"
              outer-class="col-span-6"
            />
          </template>

          <div class="col-span-6 mt-12">
            <IconBox size="sm" class="mb-3">
              <ClockIcon class="w-4 h-4" />
            </IconBox>
            <h4 class="mb-3">
              {{ $t('Waiting period') }}
            </h4>
            <h5>{{ $t('After how long the employee can take time off?') }}</h5>
          </div>

          <FormKit
            v-model="model.waiting_period_days"
            type="number"
            :label="$t('Total')"
            outer-class="col-span-3 lg:col-span-2"
          >
            <template #suffix>
              <div class="input-suffix text-base-300 text-sm">
                {{ $t('days') }}
              </div>
            </template>
          </FormKit>
        </template>

        <div class="col-span-6 mt-12">
          <IconBox size="sm" class="mb-3">
            <CreditCardIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Payment') }}
          </h4>
          <h5>{{ $t('Is this holiday paid?') }}</h5>
        </div>

        <FormKit
          v-model="model.paid"
          type="radio"
          :label="$t('Choose one option')"
          :options="yesNoOptions"
          outer-class="col-span-6"
        />
        
        <div class="col-span-6 mt-12">
          <IconBox size="sm" class="mb-3">
            <CreditCardIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Cancellation') }}
          </h4>
          <h5>{{ $t('Allow employee to cancel own holidays before start date') }}</h5>
        </div>

        <FormKit
          v-model="model.can_cancel_own_holidays_until_start_date"
          type="radio"
          :label="$t('Choose one option')"
          :options="yesNoOptions"
          outer-class="col-span-6"
        />

        <div class="col-span-6 mt-12">
          <IconBox size="sm" class="mb-3">
            <DocumentDuplicateIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Forms / Template Requests') }}
          </h4>
          <h5>{{ $t('Document example or template necessary for this policy. Leave blank if no document is required.') }}</h5>
        </div>

        <FileDropzone
          v-model="model.document_template_path"
          :file-name="model.document_template_path_name"
          :multiple="false"
          :file-progress="fileProgress"
          :accept="acceptedFileTypes"
          size="sm"
          class="col-span-6 mt-4"
        />
      </FormCardSection>
      <FormCardLoading v-else />
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue"
import { useRouter } from "vue-router"
import {
  ArrowUturnRightIcon,
  Bars3BottomLeftIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ForwardIcon,
  HandRaisedIcon,
  SparklesIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useHead } from "@vueuse/head"
import axios from "axios"
import FormCardSection from "@/components/form/FormCardSection.vue"
import { HolidayTypes, Milestone } from "@/modules/settings/types/settingTypes"
import { error, success } from "@/components/common/NotificationPlugin"
import PageTitle from "@/components/common/PageTitle.vue"
import FormCardLoading from "@/components/form/FormCardLoading.vue"
import { FileProgress } from "@/modules/documents/types/documentTypes"
import FileDropzone from "@/modules/documents/components/FileDropzone.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { acceptedFileTypes } from "@/modules/documents/utils/documentUtils"
import { contractTypeOptions } from "@/modules/employees/utils/employeeUtils"

const props = defineProps({
  policy: {
    type: Object,
  },
  loading: Boolean,
})

const model = ref<any>({
  type: null,
  name: '',
  description: '',
  applicable_contract_types: ['employment_contract'],
  milestones: [] as Milestone[],
  days_per_year: 1,
  carry_over: true,
  allow_advance_booking: true,
  can_cancel_own_holidays_until_start_date: true,
  update_balances: true,
  waiting_period_days: 0,
  paid: true,
  timesheet_symbol: 'Co',
  document_template_path_name: '',
  document_template_path: null,
})

const fileProgress = ref<FileProgress>({})

watch(() => props.policy, (policy) => {
  if (!policy?.id) {
    return
  }
  model.value = {
    ...model.value,
    ...policy.attributes,
  }
}, { immediate: true })

const settingsStore = useSettingsStore()
const { t } = useI18n()
const HolidayTypeOptions = computed(() => {
  return [
    { value: HolidayTypes.TimeOff, label: t('Rest') },
    { value: HolidayTypes.Other, label: t('Sick / Required') },
  ]
})

const LimitLabels = {
  Unlimited: t('Unlimited'),
  Limited: t('Limited'),
}

const yesNoOptions = computed(() => {
  return [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ]
})

const limitOptions = [
  LimitLabels.Limited,
  LimitLabels.Unlimited,
]

const isUnlimited = computed(() => {
  return model.value.days_per_year === 0
})

const daysRadio = computed({
  get() {
    return model.value.days_per_year === 0 ? LimitLabels.Unlimited : LimitLabels.Limited
  },
  set(value) {
    if (value === LimitLabels.Unlimited) {
      model.value.days_per_year = 0
    } else {
      model.value.days_per_year = 1
    }
  },
})

const milestonesRadio = computed({
  get() {
    return model.value.milestones.length > 0
  },
  set(value) {
    if (!value) {
      model.value.milestones = []
    } else if (value && model.value.milestones.length === 0) {
      model.value.milestones = [
        {
          years_in_company: 1,
          days_per_year: 22,
        },
      ]
    }
  },
})

const isEdit = computed(() => {
  return props.policy?.id
})

const previousBalance = computed(() => {
  return props.policy?.attributes?.days_per_year
})

const balanceDiff = computed(() => {
  return model.value.days_per_year - previousBalance.value
})

const showBalanceUpdateMessage = computed(() => {
  return balanceDiff.value !== 0
})

const isTimeOffType = computed(() => {
  return model.value.type === HolidayTypes.TimeOff
})

function addMilestone() {
  const lastMilestone = model.value.milestones[model.value.milestones.length - 1]
  const lastDaysPerYear = lastMilestone?.days_per_year || 0
  const lastYearsInCompany = lastMilestone?.years_in_company || 0

  model.value.milestones.push({
    days_per_year: lastDaysPerYear + 1,
    years_in_company: lastYearsInCompany + 1,
  })
}

function removeMilestone(index: number) {
  model.value.milestones.splice(index, 1)
}

const saving = ref(false)

function prepareDocumentUpload() {
  const formData = new FormData()
  if (hasDocumentToUpload()) {
    const file = model.value.document_template_path
    formData.append('document_template_path', file)
    formData.append('name', model.value.name)
  }
  return formData
}

function hasDocumentToUpload() {
  const file = model.value.document_template_path
  return file && typeof file === 'object'
}

function checkForPaidTimeOffPolicies() {
  if (!model.value.paid || model.value.type !== HolidayTypes.TimeOff) {
    return false
  }
  const hasAnotherTimeOffPolicy = settingsStore.holidayPolicies.find((policy) => {
    return policy.attributes.paid && policy.attributes.type === HolidayTypes.TimeOff
  })
  if (hasAnotherTimeOffPolicy) {
    error(t('At the moment we support only one paid rest policy. Please consider modifying the already existing paid rest policy.'))
  }
  return !!hasAnotherTimeOffPolicy
}

async function createOrUpdatePolicy() {
  try {
    saving.value = true
    let policyId = props.policy?.id
    if (isEdit.value) {
      await axios.put(`/restify/holiday-policies/${policyId}`, model.value)
    } else {
      const hasPaidTimeOffPolicy = checkForPaidTimeOffPolicies()
      if (hasPaidTimeOffPolicy) {
        return
      }
      const policy = await axios.post(`/restify/holiday-policies`, model.value)
      policyId = policy.data.id
    }
    if (hasDocumentToUpload()) {
      const formData = prepareDocumentUpload()
      await axios.post(`/restify/holiday-policies/${policyId}`, formData)
    }
    success(t('Holiday policy saved'))
    await router.push(`/settings/holidays`)
    await settingsStore.getHolidayPolicies()
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not save the holiday policy'))
  } finally {
    saving.value = false
  }
}

const router = useRouter()

async function onCancel() {
  await router.push(`/settings/holidays`)
}

const title = computed(() => {
  let baseTitle = isEdit.value ? t('Edit policy') : t('Add policy')
  if (isEdit.value) {
    baseTitle = `${baseTitle} ${props.policy?.attributes?.name}`
  }
  return baseTitle
})

useHead({
  title,
})
</script>
