<template>
  <div class="mb-4">
    <ReportHeader />
    <ProjectCapacityReport :project-id="$route.params.id"/>
  </div>
</template>

<script setup lang="ts">
import ProjectCapacityReport from '@/modules/reports/pages/reports/projects/[id]/capacity.vue'
import ReportHeader from '@/modules/reports/components/ReportHeader.vue'
import { IntervalTypes, useReportsStore } from '@/modules/reports/store/reportsStore'

const reportsStore = useReportsStore()
reportsStore.currentReport.intervalType = IntervalTypes.Month
reportsStore.changeReportRange()
</script>
