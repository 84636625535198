import { defineStore } from 'pinia'
import axios from "axios";
import i18n from "@/i18n";
import Cache from '@/modules/common/Cache'
import { formatDate } from "@/modules/common/utils/dateUtils";
import { capitalize } from "lodash-es";
import Evaluation = App.Domains.Evaluations.Models.Evaluation;
import Data = API.Data;

export const useEvaluationStore = defineStore('evaluation', {
  state: () => {
    return {
      currentEvaluation: {} as Data<Evaluation>,
      currentEvaluationLoading: false as boolean,
      currentEvaluationError: null as any,
    }
  },
  actions: {
    async getEvaluation(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentEvaluationLoading = true
        const { data } = await Cache.getRequest(`/restify/evaluations/${id}`, {
          params: {
            related: 'employee,type',
            temporary_urls: true,
          },
        })
        this.currentEvaluation = data
        this.currentEvaluationError = null
      } catch (err: any) {
        this.currentEvaluationError = err
      } finally {
        this.currentEvaluationLoading = false
      }
    },

    async createEvaluation(data: any) {
      return await axios.post(`/restify/evaluations`, data)
    },

    async updateEvaluation(id: string, data: any) {
      await axios.post(`/restify/evaluations/${id}`, data)
      return await this.getEvaluation(id)
    },
  },
  getters: {
    evaluationName(state) {
      const { employee, type } = state.currentEvaluation?.relationships || {}
      const employeeName = `${employee?.attributes?.first_name} ${employee?.attributes?.last_name}`
      const evaluationTypeName = capitalize(type?.attributes?.name)
      const { evaluated_at } = state.currentEvaluation?.attributes || {}
      return i18n.t(`Evaluation title`, {type: evaluationTypeName, employeeName, date: formatDate(evaluated_at)})
    },
  },
})
