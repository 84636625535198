<template>
  <dl
    class="w-full grid grid-cols-1 gap-px bg-gray-900/5 border border-base-200 rounded sm:grid-cols-2 lg:grid-cols-5 overflow-hidden"
  >
    <div
      v-for="stat in stats" :key="stat.name"
      class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
    >
      <dt class="text-sm/6 font-medium text-gray-500">
        {{ stat.name }}
      </dt>
      <dd class="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
        <div v-if="currentReportLoading || profitabilityLoading" class="bg-gray-100 animate-pulse h-5 w-20" />
        <div v-else>
          {{ stat.value }}
        </div>
      </dd>
    </div>
  </dl>
</template>

<script setup lang="ts">
import axios from "axios"
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from "pinia"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import startOfYear from "date-fns/startOfYear"
import { IntervalTypes, ReportTypes, useReportsStore } from "@/modules/reports/store/reportsStore"
import { formatPrice } from "@/plugins/formatPrice"
import { useProjectStore } from "@/modules/projects/store/projectStore"
import { API_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import { isModuleVisible } from "@/plugins/permissionPlugin";
import { ModuleKeys } from "@/modules/auth/types/enums";

const reportStore = useReportsStore()
const projectStore = useProjectStore()
const {
  currentReport,
  currentReportLoading,
  currentReportTotal,
  currentReportTotalCost,
} = storeToRefs(reportStore)

const { t } = useI18n()

const profitabilityData = ref<any>({})
const profitabilityLoading = ref(true)

const internalCosts = computed(() => {
  return profitabilityData.value?.totals?.total_actual_cost || 0
})

const invoicedAmount = computed(() => {
  return profitabilityData.value?.totals?.total_invoiced_amount || 0
})

const paidAmount = computed(() => {
  return profitabilityData.value?.totals?.total_paid_amount || 0
})

const unPaidInvoicesAmount = computed(() => {
  return invoicedAmount.value - paidAmount.value
})

const stats = computed(() => {
  const stats = [
    {
      name: t('Total Hours'),
      value: currentReportTotal.value,
      enabled: () => true,
    },
    {
      name: t('Billable Amount'),
      value: formatPrice(currentReportTotalCost.value),
      enabled: () => true,
    },
    {
      name: t('Internal Costs'),
      value: formatPrice(internalCosts.value),
      enabled: () => true,
    },
    {
      name: t('Invoiced'),
      value: formatPrice(invoicedAmount.value),
      enabled: () => isModuleVisible(ModuleKeys.Invoices),
    },
    {
      name: t('Unpaid Invoices'),
      value: formatPrice(unPaidInvoicesAmount.value),
      enabled: () => isModuleVisible(ModuleKeys.Invoices),
    },
  ]
  return stats.filter((stat: any) => stat.enabled())
})
const route = useRoute()

async function getProfitabilityData() {
  try {
    profitabilityLoading.value = true
    const now = new Date()
    const clientId = route.params.id
    const yearStart = startOfYear(now)
    const res = await axios.get(`/restify/clients/getters/client-profitability`, {
      params: {
        client_ids: clientId ? [clientId] : [],
        start_date: formatDate(yearStart, API_DATE_FORMAT),
        end_date: formatDate(now, API_DATE_FORMAT),
      },
    })
    profitabilityData.value = res?.data.find((item: any) => item.id === clientId)
  } finally {
    profitabilityLoading.value = false
  }
}

async function getReportData() {
  reportStore.resetIdFilters()
  reportStore.currentReport.intervalType = IntervalTypes.Year
  reportStore.currentReport.reportType = ReportTypes.Projects
  reportStore.currentReport.client_id = route.params.id as string
  reportStore.changeReportRange()
  await reportStore.getCurrentReport()
}

onMounted(() => {
  getReportData()
  getProfitabilityData()
})
</script>
