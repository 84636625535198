<template>
  <div
    ref="root"
    class="relative"
    :class="{
      '-mx-2 px-2 -my-1 py-1 rounded-sm': !edit,
      'hover:bg-gray-50 ': !edit && canEdit,
    }"
  >
    <div
      v-if="!edit"
      class="min-h-[38px] flex items-center"
      @click="onEdit"
    >
      <slot />
    </div>
    <div
      v-if="edit"
      v-click-outside="onClickOutside"
    >
      <slot name="edit" :save="onEditDone" />
      <div
        v-if="showButtons && !defaultEdit"
        class="flex justify-end space-x-1 mt-1"
      >
        <BaseButton outline size="xs" @click.stop="onEditDone">
          <CheckIcon class="w-4 h-4" />
        </BaseButton>
        <BaseButton outline size="xs" @click.stop="close">
          <XMarkIcon class="w-4 h-4" />
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ClickOutside as vClickOutside } from 'element-plus'
import { nextTick, ref } from 'vue'
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline"

const props = defineProps({
  showButtons: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  defaultEdit: {
    type: Boolean,
    default: false,
  },
})

const edit = ref(props.defaultEdit)
const root = ref()

const emit = defineEmits(['save', 'cancel'])

function onEdit() {
  if (!props.canEdit) {
    return
  }
  edit.value = true
}

function onClickOutside(event) {
  if (props.defaultEdit) {
    return
  }
  if (root.value && root.value.contains(event.target)) {
    return
  }
  const externalPopupSelectors = [
    '.el-picker__popper',
    '.el-select__popper',
  ]

  // Check if the click is inside any external popup
  const isClickInsideExternalPopup = externalPopupSelectors.some((selector) => {
    return event.target.closest(selector)
  })

  if (isClickInsideExternalPopup) {
    return
  }
  onEditDone()
}

async function onEditDone() {
  await nextTick()
  edit.value = false
  emit('save')
}

function close() {
  edit.value = false
  emit('cancel')
}
</script>

<style>
</style>
