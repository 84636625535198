<template>
  <div>
    <div
      class="flex justify-between rounded group w-full border border-gray-200 py-2 px-4 hover:bg-primary/5 hover:cursor-pointer"
      @click="onTaskCardClick"
    >
      <div
        v-if="!isTemplate"
        class="-mt-1 -ml-3">
        <BaseTooltip :content="statusContent">
          <BaseButton type="button" variant="link" size="xs" @click.stop="onCheckButtonClick">
            <CheckCircleIcon
              v-if="status !== TaskStatus.Complete"
              class="w-7 h-7 text-gray-400"
            />
            <CheckCircleIconSolid
              v-else-if="status === TaskStatus.Complete"
              class="w-7 h-7 text-green-600"
            />
          </BaseButton>
        </BaseTooltip>
      </div>
      <div class="flex-1">
        <div class="flex justify-between items-center mb-3">
          <div
            :class="{
              'line-through': status === TaskStatus.Complete,
            }"
            class="font-semibold text-sm"
          >
            {{ task.attributes?.title }}
          </div>
          <template v-if="isTemplate">
            <ApplicablePositionsLabel :value="task.attributes.applicable_positions" />
          </template>
          <TaskStatusDropdown
            v-else
            :task="task"
            :update-status="updateTaskStatus"
          />
        </div>
        <div
          v-if="task.attributes?.description"
          class="prose prose-sm truncate line-clamp-2"
          v-html="task.attributes?.description"
        />
        <div class="flex w-full justify-end items-center space-x-2 text-sm">
          <template v-if="isTemplate">
            <DueDaysLabel :value="task.attributes.days_to_complete" />
            <AssigneeLabel :value="task.attributes.assignee" />
          </template>
          <template v-else>
            <TaskDueDate :task="task" />
            <EmployeeLink
              :params="{
                value: task.attributes.assignee_id,
                showName: false,
              }"
            />
          </template>
        </div>
      </div>
    </div>
    <TaskDialog
      v-if="showDialog"
      v-model="showDialog"
      :task="task"
      :is-template="isTemplate"
      :update-status="updateTaskStatus"
      @delete="onDeleteTask"
      @update:field="onFieldUpdate"
      @cancel="onClose"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, ref } from "vue"
import { CheckCircleIcon } from "@heroicons/vue/24/outline"
import { CheckCircleIcon as CheckCircleIconSolid } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"
import { TaskStatus, _updateTaskStatus } from "@/modules/settings/components/checklists/utils"
import TaskDialog from "@/modules/settings/components/checklists/TaskDialog.vue"
import TaskStatusDropdown from "@/modules/settings/components/checklists/TaskStatusDropdown.vue"
import TaskDueDate from "@/modules/settings/components/checklists/TaskDueDate.vue"
import AssigneeLabel from "@/modules/settings/components/checklists/AssigneeLabel.vue"
import DueDaysLabel from "@/modules/settings/components/checklists/DueDaysLabel.vue"
import ApplicablePositionsLabel from "@/modules/settings/components/checklists/ApplicablePositionsLabel.vue"

const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
  isTemplate: {
    type: Boolean,
    default: false,
  },
})

const showDialog = ref(false)

const status = computed(() => {
  return props.task.attributes?.status
})

const { t } = useI18n()
const statusContent = computed(() => {
  if (status.value === TaskStatus.Complete) {
    return t('Click to mark as pending')
  }
  return t('Click to mark as done')
})

const emit = defineEmits(['update:field', 'delete'])

async function onClose() {
  await nextTick()
  showDialog.value = false
}

async function updateTaskStatus(status) {
  const oldStatus = props.task.attributes.status
  try {
    onFieldUpdate('status', status)
    await _updateTaskStatus(props.task.id, status)
  } catch (err) {
    onFieldUpdate('status', oldStatus)
  }
}

function onTaskCardClick(event) {
  const externalPopupSelectors = [
    '.el-dropdown__popper',
    '.el-dropdown',
  ]

  // Check if the click is inside any external popup
  const isClickOnDropdown = externalPopupSelectors.some((selector) => {
    return event.target.closest(selector)
  })
  if (isClickOnDropdown) {
    return
  }
  showDialog.value = true
}

function onDeleteTask(taskId) {
  emit('delete', taskId)
  showDialog.value = false
}

function onFieldUpdate(field, value) {
  emit('update:field', field, value)
}

async function onCheckButtonClick() {
  if (status.value === TaskStatus.Complete) {
    await updateTaskStatus(TaskStatus.Pending)
    return
  }
  await updateTaskStatus(TaskStatus.Complete)
}
</script>

