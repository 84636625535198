import { computed, ref, watch } from 'vue'
import axios from "axios"
import { useI18n } from "vue-i18n"
import { SettingsKeyType } from "@/modules/auth/types/enums"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { error, success } from "@/components/common/NotificationPlugin"

export function useSettings(keys: SettingsKeyType[]) {
  const settingsStore = useSettingsStore()
  const _modelValue: Record<string, any> = {}
  const initialModel = ref({})
  function initModel() {
    keys.forEach((key: SettingsKeyType) => {
      _modelValue[key] = settingsStore.getSetting(key as SettingsKeyType)
    })
    initialModel.value = _modelValue
  }

  watch(() => settingsStore.settings, initModel, { immediate: true })

  const model = computed({
    get() {
      const value: Record<string, any> = {}
      keys.forEach((key: SettingsKeyType) => {
        value[key] = settingsStore.getSetting(key as SettingsKeyType)
      })
      return value
    },
    set(value) {
      initialModel.value = value
    },
  })

  const loading = ref(false)

  function getUpdateModel() {
    const requestData = []
    for (const key in model.value) {
      if (initialModel.value[key] !== model.value[key]) {
        const settingObject = settingsStore.getSettingObject(key)
        requestData.push({
          id: settingObject?.id,
          key,
          value: model.value[key],
        })
      }
    }
    return requestData
  }

  const { t } = useI18n()
  async function updateSettings() {
    try {
      loading.value = true
      const requestData = getUpdateModel()
      await axios.post(`restify/settings/bulk/update`, requestData)
      success(t('Settings updated successfully'))
      await settingsStore.getSettings()
    } catch (err: any) {
      if (!err.handled) {
        error(t('Could not update settings.'))
      }
    } finally {
      loading.value = false
    }
  }

  return {
    model,
    loading,
    updateSettings,
  }
}
