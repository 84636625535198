<template>
  <div class="flex flex-col h-full">
    <EmployeeHolidayOverview
      ref="holidayOverview"
      :employee-id="currentEmployee.id"
    />
    <HolidaysTable
      :employee-id="currentEmployee.id"
      :policy-id="$route.params.policy"
      class="flex-1"
      @refresh="$refs.holidayOverview?.refresh()"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";
import EmployeeHolidayOverview from "@/modules/employees/components/EmployeeHolidayOverview.vue";
import HolidaysTable from "@/modules/holidays/components/HolidaysTable.vue";
const { currentEmployee } = storeToRefs(useEmployeeStore())
</script>
