import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_URL } from '@/modules/common/config'
import { NotificationType, notifications, notify } from '@/components/common/NotificationPlugin'
import { isForbidden, isInternalServerError, isUnauthorized, mapErrors } from '@/modules/common/utils/requestUtils'
import { useAuthStore } from "@/modules/auth/store/authStore"
import router from "@/router/router"
import i18n from "@/i18n"
import Cache from '@/modules/common/Cache'

export const statusCodesToHandle = [400, 401, 422]
const TOKEN_KEY = 'token'

axios.defaults.baseURL = API_URL

export function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const token = localStorage.getItem(TOKEN_KEY)
  const locale = i18n.locale

  if (!config.headers) {
    config.headers = {}
  }

  config.headers.Accept = 'application/vnd.api+json'

  if (!config.headers.Authorization && token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (locale?.value) {
    config.headers.locale = locale?.value || 'en'
  }

  return config
}

axios.interceptors.request.use(requestInterceptor)

export function successInterceptor(response: AxiosResponse): AxiosResponse {
  const { url, method } = response.config as any
  if (method === 'get') {
    return response.data
  }
  const urlParts = url.split('/').filter((p: string) => p !== '')
  let entity = ''
  if (urlParts.length > 1 && urlParts[0] === 'restify') {
    entity = urlParts[1]
  }
  const methodsToClearCache = ['post', 'put', 'patch', 'delete']
  if (entity && methodsToClearCache.includes(method)) {
    Cache.removeForEntity(entity)
  }
  return response.data
}

interface CustomAxiosError extends AxiosError {
  handled: boolean
  errors: any
  status: number
}

export async function errorInterceptor(error: CustomAxiosError) {
  // Happens for cancelled requests using axios CancelTokenSource
  if (!error.response) {
    return Promise.reject(error)
  }

  const { status } = error.response
  let errors = ''

  if (statusCodesToHandle.includes(status)) {
    errors = await mapErrors(error.response.data)
    if (errors === 'Unauthenticated.') {
      errors = 'Your session expired. Please login in again to use the application'
    }

    const exceptions = ['quantity_exceeded']
    if (exceptions.some(exception => errors.includes(exception))) {
      error.handleLocally = true
      return Promise.reject(error)
    }

    if (notifications.state.length === 0) {
      notify({
        type: NotificationType.Error,
        message: errors,
        once: true,
      })
      error.handled = true
    }
  }

  if (isForbidden(status)) {
    // Get message from response if available, otherwise use default
    errors = error.response.data.message || 'You are not allowed to perform this action'

    if (notifications.state.length === 0) {
      notify({
        type: NotificationType.Error,
        message: errors,
        once: true,
      })
      error.handled = true
    }
  }

  if (isInternalServerError(status)) {
    errors = 'A server error occurred during request execution'
    if (notifications.state.length === 0) {
      notify({
        type: NotificationType.Error,
        message: errors,
        once: true,
      })
      error.handled = true
    }
  }

  error.errors = errors
  error.status = status

  if (isUnauthorized(status)) {
    // Logout here if necessary
    const authStore = useAuthStore()

    await router.push('/login')
    authStore.logout()
    return Promise.reject(error)
  }
  return Promise.reject(error)
}
axios.interceptors.response.use(successInterceptor, errorInterceptor)
