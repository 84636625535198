<template>
  <span
    v-bind="$attrs"
    :class="badgeClass"
    class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset"
  >
    <slot>
      {{ title }}
    </slot>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const Status = {
  Default: 'default',
  Success: 'success',
  Danger: 'danger',
  Info: 'info',
  Primary: 'primary',
}

const props = defineProps({
  status: {
    type: String,
    default: 'default',
  },
  title: {
    type: String,
  },
})

const classMap = {
  [Status.Default]: 'bg-gray-50 text-gray-700 ring-gray-600/10',
  [Status.Info]: 'bg-blue-50 text-blue-700 ring-blue-600/10',
  [Status.Primary]: 'bg-primary/5 text-primary ring-primary/20',
  [Status.Success]: 'bg-green-50 text-green-700 ring-green-600/10',
  [Status.Danger]: 'bg-red-50 text-red-700 ring-red-600/10',
}

const badgeClass = computed(() => {
  return classMap[props.status]
})
</script>
