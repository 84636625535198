<template>
  <div class="flex flex-col">
    <template v-if="!isEditPage">
      <div
        class="flex items-end w-full justify-between"
      >
        <PageTitle
          :loading="pageLoading"
        >
          <div class="flex space-x-2">
            <ClientAvatar :data="{ attributes: currentClient }" :show-name="false"/>
            <span>{{ clientName }}</span>
          </div>
        </PageTitle>
        <EditButton
          v-if="$can('manageClients') && isDetailsPage"
          @click="goToEdit"
        />
      </div>
      <div v-if="tabs.length">
        <TabLinks v-model="activeTab" :tabs="tabs"/>
      </div>
      <div v-else class="mb-4"/>
    </template>
    <div
      v-if="pageLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="pageLoading"/>
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8"/>
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the client information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1"/>
    <slot/>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {DocumentTextIcon, ExclamationTriangleIcon, ReceiptRefundIcon, UserIcon} from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { useHead } from '@vueuse/head'
import i18n from '@/i18n'
import TabLinks from '@/components/tabs/TabLinks.vue'
import EditButton from '@/components/common/buttons/EditButton.vue'
import { can, isModuleVisible } from '@/plugins/permissionPlugin'
import { useClientStore } from '@/modules/clients/store/clientStore'
import { useI18n } from "vue-i18n";
import ClientAvatar from "@/modules/clients/components/ClientAvatar.vue";
import { ModuleKeys } from "@/modules/auth/types/enums";

const props = defineProps({
  clientId: {
    type: String,
  },
})
const activeTab = ref('Details')
const clientStore = useClientStore()

const route = useRoute()

const {
  currentClientName: clientName,
  currentClient,
  currentClientLoading,
} = storeToRefs(clientStore)

const id = computed(() => props.clientId as string)

const pageLoading = computed(() => {
  return currentClientLoading.value && currentClient.value.id?.toString() !== id.value
})
const { t } = useI18n()
const tabs = computed(() => {
  const basePath = `/clients/${id.value}`
  const tabs: any[] = [
    {
      label: t('Details'),
      icon: UserIcon,
      path: `${basePath}/details`,
      show: true,
      permission: can('manageClients'),
    },
    {
      label: t('Invoices'),
      icon: ReceiptRefundIcon,
      path: `${basePath}/invoices`,
      show: true,
      permission: can('manageInvoices') && isModuleVisible(ModuleKeys.Invoices),
    },
    {
      label: t('Feedback'),
      icon: DocumentTextIcon,
      path: `${basePath}/feedback`,
      permission: can('manageFeedback') && isModuleVisible(ModuleKeys.Feedback),
    },
  ]
  return tabs.filter(tab => tab.show !== false && tab.permission === true)
})

const isEditPage = computed(() => {
  return route.path.endsWith('edit')
})

const isDetailsPage = computed(() => {
  return route.path.endsWith('details')
})

const pageTitle = computed(() => {
  if (!clientName.value) {
    return activeTab.value
  }
  return `${activeTab.value} - ${clientName.value}`
})

useHead({
  title: pageTitle,
})
watch(() => id.value, async (value: string) => {
  await clientStore.getClient(value)
})
onMounted(async () => {
  await clientStore.getClient(id.value)
})
onUnmounted(() => {
  clientStore.resetCurrentClient()
})

const router = useRouter()

async function goToEdit() {
  await router.push(`/clients/${id.value}/edit`)
}
</script>
