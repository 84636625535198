<template>
  <RouterView />
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const settingsStore = useSettingsStore()
const route = useRoute()
const id = computed<string>(() => {
  return route.params.id as string
})
onMounted(async () => {
  await settingsStore.getHolidayPolicy(id.value)
})
</script>
