<template>
  <div
    :class="taskStatusClassMap[status]"
    class="items-center text-sm flex space-x-1 !min-w-[0]"
  >
    <component :is="taskIconMap[status]" class="w-4 h-4" />
    <span>{{ statusLabel }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TaskStatus, taskIconMap, taskStatusClassMap } from "@/modules/settings/components/checklists/utils"

const props = defineProps({
  status: {
    type: String,
    default: TaskStatus.Pending,
  },
})
const { t } = useI18n()
const statusLabel = computed(() => {
  const labelMap = {
    [TaskStatus.Pending]: t('Pending'),
    [TaskStatus.Progress]: t('In Progress'),
    [TaskStatus.Complete]: t('Done'),
    [TaskStatus.Skipped]: t('Skipped'),
  }
  return labelMap[props.status]
})
</script>
