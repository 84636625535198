<template>
  <BaseSelect
    v-bind="allAttrs"
    :model-value="value"
    :options="options"
    :label-formatter="labelFormatter"
    :data-loading="allEmployeesLoading"
    value-key="id"
    filterable
    popper-class="employee-select"
    @update:model-value="handleChange"
    @update:full-value="fullValue = $event"
  >
    <template #prefix >
      <EmployeeAvatar
        v-if="fullValue && !Array.isArray(fullValue) && !allAttrs.multiple && !fullValue?.nonEmployee"
        :data="fullValue"
        :show-name="false"
        :show-position="false"
      />
      <BaseAvatar
        v-if="fullValue && !Array.isArray(fullValue) && !allAttrs.multiple && fullValue?.nonEmployee"
        class="rounded-full w-6 h-6 min-w-[24px]"
        :src="fullValue.avatar"
        :name="fullValue.shortLabel"
      />
    </template>
    <template #default="{ option }">
      <div class="flex flex-col">
        <template v-if="!option.nonEmployee">
          <EmployeeAvatar
            :data="option"
            :show-position="true"
          />
          <div v-if="allAttrs['show-email']" class="text-gray-400 -mt-1 text-sm ml-8 truncate">
            {{ option.attributes.email }}
          </div>
        </template>
        <template v-else>
          <div class="flex items-start space-x-2">
            <BaseAvatar
              class="rounded-full w-6 h-6 min-w-[24px]"
              :src="option.avatar"
              :name="option.shortLabel || option.label"
            />
            <div class="font-semibold truncate -mt-2">
              {{ option.label }}
            </div>
          </div>
        </template>
      </div>
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Employee = App.Models.Employee
import Data = API.Data

const props = defineProps({
  context: Object,
  extraOptions: {
    type: Array,
  },
})
const employeeStore = useEmployeeStore()
const { allEmployees, allEmployeesLoading } = storeToRefs(employeeStore)
const { value, allAttrs, handleChange } = useFormKitInput(props)

const fullValue = ref()

const options = computed(() => {
  const options = allAttrs.value.options
  if (options) {
    return options
  }
  const extraOptions = props.extraOptions || props.context?.attrs?.['extra-options']
  if (extraOptions) {
    return extraOptions.concat(allEmployees.value)
  }
  return allEmployees.value
})
function labelFormatter(row: Data<Employee> | any) {
  if (!row.attributes) {
    return row?.label
  }
  const { first_name, last_name } = row.attributes
  return `${first_name} ${last_name}`
}
</script>

<style>
.employee-select .el-select-dropdown__item {
  height: 44px;
  @apply flex items-center;
}
</style>
