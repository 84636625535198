<template>
  <div ref="wrapper" class="w-full col-span-6 mt-4 employee-list">
    <div
      v-if="!edit"
      class="flex w-full justify-end -mt-12 mb-2"
    >
      <BaseButton
        size="xs"
        variant="primary"
        outline
        @click="showRates = !showRates"
      >
        {{ showRates ? $t('Hide Rates') : $t('Show Rates') }}
      </BaseButton>
    </div>
    <div v-if="edit" class="text-sm text-gray-500 flex items-center space-x-1 -mt-3 mb-2">
      <InformationCircleIcon class="w-4 h-4" />
      <span>{{ $t('You can double click on the rate or allocation columns to edit the value') }}</span>
    </div>
    <div class="space-y-2">
      <BaseDataTable
        :data="data"
        :columns="columns"
        :pagination="false"
        :row-height="50"
        :hide-search="true"
        :get-row-class="getRowClass"
        class="-mt-4"
        dom-layout="autoHeight"
        @grid-ready="grid = $event"
      >
        <template #delete="{ row }">
          <BaseButton
            v-if="edit && !isArchivedOnProject(row)"
            outline
            square
            size="xs"
            @click="archiveEmployeeOnProject(row)"
          >
            <XMarkIcon class="w-4 h-4" />
          </BaseButton>
          <BaseButton
            v-if="edit && isArchivedOnProject(row)"
            outline
            size="xs"
            class="bg-white"
            @click="restoreEmployee(row)"
          >
            {{ $t('Restore') }}
          </BaseButton>
        </template>
        <template #edit="{ row, index }">
          <BaseButton
            v-if="edit"
            outline
            size="xs"
            :disabled="isArchivedOnProject(row)"
            @click="editRate(index)"
          >
            <span class="text-xs">{{ t('Edit rate') }}</span>
          </BaseButton>
        </template>
        <template #pivots.billed_rate="{ row }">
          <span>
            {{ getRate(row) }}
          </span>
        </template>
      </BaseDataTable>
    </div>
    <div v-if="edit" class="w-full max-w-[400px] mt-4">
      <FormKit
        id="employeeForm"
        :actions="false"
        type="form"
        @submit="addNewEmployee"
      >
        <div class="flex space-x-2 items-start">
          <FormKit
            ref="employeeName"
            :key="employeeSelectKey"
            v-model="model.employeeId"
            :name="t('Member')"
            :placeholder="$t('Choose a member')"
            :filter-function="filterEmployees"
            type="employeeSelect"
            outer-class="w-full employee-select"
            validation="length:2"
          />
          <button
            type="button"
            class="btn btn-primary btn-outline btn-sm"
            @click="addNewEmployee"
          >
            {{ t('Assign member') }}
          </button>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { InformationCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { reset } from '@formkit/core'
import axios from 'axios'
import {
  Column,
  GridApi,
  RowClassParams, ValueFormatterParams,
  ValueSetterParams,
} from "@ag-grid-community/core"
import { uniqBy } from "lodash-es"
import { error } from '@/components/common/NotificationPlugin'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { formatPrice } from "@/plugins/formatPrice"
import Data = API.Data
import Employee = App.Domains.Employees.Models.Employee
import { $confirm } from "@/components/common/modal/modalPlugin"
import { getTableData } from "@/modules/common/utils/tableUtils"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import { can } from "@/plugins/permissionPlugin"
import { SettingKeys } from "@/modules/auth/types/enums";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
const props = defineProps({
  employees: {
    type: Array,
    default: () => [],
  },
  projectId: {
    type: String,
  },
  edit: {
    type: Boolean,
    default: true,
  },
  showRatesByDefault: {
    type: Boolean,
    default: true,
  },
  showArchived: {
    type: Boolean,
    default: true,
  },
})
const wrapper = ref()
const data = ref(getFilteredEmployees())
const showRates = ref(props.showRatesByDefault)

const model = ref({
  employeeId: '',
})

function getFilteredEmployees() {
  return props.employees.filter((employee: any) => {
    if (props.showArchived) {
      return true
    }
    return !employee.pivots?.archived_at
  })
}

const { t } = useI18n()
const grid = ref<GridApi>()
const columns = computed<Column[]>(() => {
  return [
    {
      headerName: '',
      field: 'delete',
      maxWidth: 120,
      resizable: false,
      hide: !props.edit,
    },
    {
      headerName: '',
      field: 'id',
      type: ColumnTypes.EmployeeLink,
      resizable: false,
    },
    {
      headerName: t('Weekly allocation'),
      field: 'pivots.weekly_allocation_hours',
      resizable: false,
      editable: true,
      maxWidth: 140,
      cellEditor: 'numericCellEditor',
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return t('No allocation set')
        }
        return `${params.value} ${t('hours')}`
      },
      valueSetter: (params: ValueSetterParams) => {
        const newValue = params.newValue
        if (newValue < 0) {
          return false
        }
        params.data.pivots.weekly_allocation_hours = newValue
        updateEmployeeRow(params.data, {
          weekly_allocation_hours: newValue,
          billed_rate: params.data.pivots.billed_rate,
        })
        return true
      },
    },
    {
      headerName: t('Billable rate (per hour)'),
      field: 'pivots.billed_rate',
      type: ColumnTypes.Custom,
      resizable: false,
      editable: true,
      maxWidth: 140,
      cellEditor: 'numericCellEditor',
      hide: !can('manageInvoices'),
      valueSetter: (params: ValueSetterParams) => {
        const newValue = params.newValue
        if (newValue < 0) {
          return false
        }
        params.data.pivots.billed_rate = newValue
        updateEmployeeRow(params.data, {
          billed_rate: newValue,
          weekly_allocation_hours: params.data.pivots.weekly_allocation_hours,
        })
        return true
      },
    },
    {
      headerName: t(''),
      field: 'edit',
      resizable: false,
      maxWidth: 130,
      hide: !props.edit,
    },
  ]
})
const settingsStore = useSettingsStore()
const defaultCurrency = computed(() => settingsStore.getSetting(SettingKeys.DefaultCurrency))

function getRate(row: any) {
  if (!showRates.value) {
    return `** ${t('Hidden')} **`
  }
  const rate = row.pivots?.billed_rate
  const currency = row.pivots?.currency || defaultCurrency.value
  if (!rate) {
    return t('Missing rate')
  }
  return formatPrice(rate, {
    currency,
  })
}

function editRate(index: number) {
  grid.value!.startEditingCell({
    rowIndex: index,
    colKey: 'pivots.billed_rate',
  })
}

const employeeSelectKey = ref(0)

function isArchivedOnProject(row: any) {
  return row?.pivots?.archived_at
}

function getRowClass(params: RowClassParams) {
  if (isArchivedOnProject(params.data)) {
    return '!bg-gray-50 opacity-80'
  }
  return ''
}
function filterEmployees(employee: Data<Employee>) {
  const employeeIds = getProjectEmployeeIds()
  return !employeeIds.includes(employee.id)
}

function getEmployees() {
  const data = getTableData(grid.value)
  const employeeIds = props.employees
  return uniqBy([...data, ...employeeIds], 'id')
}
function getProjectEmployeeIds() {
  return getEmployees().map((employee: any) => employee.id)
}

const hasUnsavedMember = computed(() => {
  return model.value.employeeId
})

watch(() => props.employees, () => {
  data.value = props.employees
})

const employeeStore = useEmployeeStore()
async function updateEmployeeRow(employee: Data<Employee>, { billed_rate, weekly_allocation_hours }: any) {
  if (!props.projectId) {
    return
  }
  await axios.post(`/restify/projects/${props.projectId}/actions?action=set-billed-rate`, {
    employee_id: employee.id,
    billed_rate,
    weekly_allocation_hours,
  })
}
async function addNewEmployee(rate: undefined) {
  const temporaryId = crypto.randomUUID()
  try {
    const employeeId = model.value.employeeId
    if (!employeeId) {
      return
    }
    if (isDuplicate(employeeId)) {
      error(t('This member is already part of the project'))
      await focusOnInput()
      return
    }
    const fullEmployee = employeeStore.getEmployeeById(employeeId)
    reset('employeeForm')
    const billed_rate = fullEmployee?.attributes?.billed_rate
    const weekly_allocation_hours = fullEmployee?.attributes?.weekly_hours_capacity || 40
    const row = {
      id: temporaryId,
      pivots: {
        billed_rate,
        weekly_allocation_hours,
      },
      ...fullEmployee,
    }
    grid.value!.applyTransaction({
      add: [row],
    })
    if (!props.projectId) {
      return
    }
    const employeeRequestId = model.value.employeeId || fullEmployee?.id
    await attachEmployee(employeeRequestId as string, billed_rate as number, weekly_allocation_hours as number)

    await focusOnInput()
  } catch (err) {
    const employees = getEmployees()
    const employee = employees.find((employee: any) => employee.id === temporaryId)
    grid.value!.applyTransaction({
      remove: [employee],
    })
  } finally {
    employeeSelectKey.value++
  }
}

async function restoreEmployee(employee: any) {
  try {
    const confirmed = await $confirm({
      title: t('Restore member on project'),
      description: t(`This action will restore the member on the project. Are you sure?`),
      buttonText: t('Restore'),
    })
    if (!confirmed) {
      return
    }
    await axios.post(`/restify/projects/${props.projectId}/actions?action=restore-employees`, {
      employees: [employee.id],
    })
    employee.pivots = {
      ...employee.pivots,
      archived_at: null,
    }
    updateGridRow(employee)
  } catch (err) {
    console.error(err)
  }
}
async function attachEmployee(employeeId: string, rate: number, weekly_allocation_hours: number) {
  if (!props.projectId) {
    return
  }
  return await axios.post(`/restify/projects/${props.projectId}/attach/employees`, {
    employees: [employeeId],
    billed_rate: rate,
    weekly_allocation_hours,
  })
}

function isDuplicate(employeeId: string) {
  return data.value.some((employee: any) => employee.attributes.id === employeeId)
}

async function focusOnInput() {
  setTimeout(() => {
    wrapper.value?.querySelector('.employee-select input')?.focus()
  }, 50)
}

async function archiveEmployeeOnProject(employee: any) {
  try {
    const confirmed = await $confirm({
      title: t('Archive member on project'),
      description: t(`This action will archive the member on the project. They won't be able to log time or access the project anymore. Are you sure?`),
      buttonText: t('Archive'),
    })
    if (!confirmed) {
      return
    }
    await nextTick()
    employeeSelectKey.value++
    if (employee.is_local) {
      return
    }
    await axios.post(`/restify/projects/${props.projectId}/actions?action=detach-employee`, {
      employees: [employee.id],
    })
    employee.pivots = {
      ...employee.pivots,
      archived_at: new Date().toISOString(),
    }
    updateGridRow(employee)
  } catch (err) {
    updateGridRow(employee)
  }
}

function updateGridRow(employee: any) {
  grid.value!.applyTransaction({
    update: [employee],
  })
  grid.value!.redrawRows()
}

defineExpose({
  hasUnsavedMember,
})
</script>
