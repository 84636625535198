<template>
  <BaseTooltip
    :content="$t(`${formattedWorkingHours} / ${formattedTotalHours} `) + $t('hours')"
  >
    <div
      class="group relative flex flex-wrap items-center md:space-x-2"
    >
      <div class="h-[20px] w-[150px] border border-gray-300 flex rounded overflow-hidden mt-2 md:mt-0">
        <div
          :style="workingHoursStyles"
          class="h-full relative"
        >
          <div class="opacity-0 group-hover:opacity-100 absolute inset-0 w-full flex items-center justify-center">
            <BriefcaseIcon class="w-3 h-3 text-white" />
          </div>
        </div>
        <div
          :style="nationalHolidayStyles"
          class="h-full relative"
        >
          <div class="opacity-0 group-hover:opacity-100 absolute inset-0 w-full flex items-center justify-center">
            <NationalIcon class="w-3 h-3 text-white" />
          </div>
        </div>
        <div
          :style="daysOffStyles"
          class="h-full relative"
        >
          <div class="opacity-0 group-hover:opacity-100 absolute inset-0 w-full flex items-center justify-center">
            <RestIcon class="w-3 h-3 text-white" />
          </div>
        </div>
      </div>
    </div>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { BriefcaseIcon } from "@heroicons/vue/24/outline"
import { orangeColor, primaryColor, redColor } from '@/modules/reports/enum/reportEnums'
import RestIcon from '@/assets/icons/rest.svg'
import NationalIcon from '@/assets/icons/national.svg'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
})
const { t } = useI18n()

const dailyHours = computed(() => {
  return props.item?.weekly_capacity / 5 || 0
})
const totalHours = computed(() => {
  return props.item?.total_workdays * dailyHours.value
})

const workingDays = computed(() => {
  return (+props.item?.total_workdays || 0) - (+props.item?.total_days_off || 0)
})

const daysOff = computed(() => {
  return (+props.item?.days_off || 0)
})

const nationalHolidays = computed(() => {
  return (+props.item?.national_holidays || 0)
})

const workingHours = computed(() => {
  return workingDays.value * dailyHours.value
})

const daysOffHours = computed(() => {
  return daysOff.value * dailyHours.value
})

const nationalHolidayHours = computed(() => {
  return nationalHolidays.value * dailyHours.value
})

const formattedWorkingHours = computed(() => {
  return (+workingHours.value)?.toFixed(2)
})

const formattedTotalHours = computed(() => {
  return (+totalHours.value)?.toFixed(2)
})

const workingHoursPercentage = computed(() => {
  return (workingHours.value / totalHours.value) * 100
})

const daysOffPercentage = computed(() => {
  return (daysOffHours.value / totalHours.value) * 100
})

const nationalHolidaysPercentage = computed(() => {
  return (nationalHolidayHours.value / totalHours.value) * 100
})

const workingHoursStyles = computed(() => {
  const percent = workingHoursPercentage.value
  const width = `${percent}%`
  return {
    width,
    backgroundColor: primaryColor,
  }
})

const daysOffStyles = computed(() => {
  const percent = daysOffPercentage.value
  const width = `${percent}%`
  return {
    width,
    backgroundColor: orangeColor,
  }
})

const nationalHolidayStyles = computed(() => {
  const percent = nationalHolidaysPercentage.value
  const width = `${percent}%`
  return {
    width,
    backgroundColor: redColor,
  }
})
</script>
