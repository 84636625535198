<template>
  <BubbleMenu
    :editor="editor as Editor"
    :plugin-key="pluginKey"
    :should-show="shouldShow"
    :update-delay="0"
    :tippy-options="tippyOptions"
  >
    <ToolbarWrapper>
      <ToolbarButton
        tooltip="Sidebar left"
        :active="editor?.isActive('columns', { layout: ColumnLayout.SidebarLeft })"
        type="button"
        @click="onColumnLeft"
      >
        <Icon name="PanelLeft" />
      </ToolbarButton>
      <ToolbarButton
        tooltip="Two columns"
        type="button"
        :active="editor?.isActive('columns', { layout: ColumnLayout.TwoColumn })"
        @click="onColumnTwo"
      >
        <Icon name="Columns" />
      </ToolbarButton>
      <ToolbarButton
        tooltip="Sidebar right"
        type="button"
        :active="editor?.isActive('columns', { layout: ColumnLayout.SidebarRight })"
        @click="onColumnRight"
      >
        <Icon name="PanelRight" />
      </ToolbarButton>
    </ToolbarWrapper>
  </BubbleMenu>
</template>

<script setup lang="ts">
import { BubbleMenu, Editor } from '@tiptap/vue-3'
import { sticky } from 'tippy.js'
import { computed } from "vue"
import getRenderContainer from "@/components/formkit/html/utils/getRenderContainer"
import { ColumnLayout } from "@/components/formkit/html/MultiColumn"
import ToolbarButton from "@/components/formkit/html/components/toolbar/ToolbarButton.vue"
import ToolbarWrapper from "@/components/formkit/html/components/toolbar/ToolbarWrapper.vue"

const props = defineProps({
  editor: Object,
  appendTo: Object,
})

const getReferenceClientRect = () => {
  const renderContainer = getRenderContainer(props.editor as Editor, 'columns')
  const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)
  return rect
}

const tippyOptions: any = {
  offset: [0, 8],
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
  getReferenceClientRect,
  appendTo: () => props.appendTo,
  plugins: [sticky],
  sticky: 'popper',
}
const shouldShow = () => {
  const isColumns = props.editor?.isActive('columns')
  return isColumns
}

const pluginKey = computed(() => {
  return `columns-menu-${crypto.randomUUID()}`
})

const onColumnLeft = () => {
  props.editor?.chain().focus().setLayout(ColumnLayout.SidebarLeft).run()
}

const onColumnRight = () => {
  props.editor?.chain().focus().setLayout(ColumnLayout.SidebarRight).run()
}

const onColumnTwo = () => {
  props.editor?.chain().focus().setLayout(ColumnLayout.TwoColumn).run()
}
</script>
