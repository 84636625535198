import { defineStore } from 'pinia'
import { capitalize, cloneDeep, groupBy, orderBy, uniqBy } from 'lodash-es'
import axios from 'axios'
import Cache from '@/modules/common/Cache'
import { HolidayTypes } from '@/modules/settings/types/settingTypes'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import { useAuth } from '@/modules/auth/composables/useAuth'
import { SettingKeys, SettingsKeyType } from '@/modules/auth/types/enums'
import Department = App.Domains.Deparments.Models.Department
import Position = App.Domains.Positions.Models.Position
import Level = App.Domains.Levels.Models.Level
import DocumentType = App.Domains.Documents.Models.DocumentType
import EvaluationType = App.Domains.Evaluations.Models.EvaluationType
import HolidayPolicy = App.Domains.HolidayPolicies.Models.HolidayPolicy
import LegalHoliday = App.Domains.LegalHolidays.Models.LegalHoliday
import ContractType = App.Domains.ContractTypes.Models.ContractType
import Data = API.Data
import Role = App.Domains.Permissions.Models.Role
import Setting = App.Domains.Settings.Models.Setting
import { useBillingStore } from "@/modules/settings/store/billingStore"
import DocumentTag = App.Domains.Documents.Models.DocumentTag
import { can } from "@/plugins/permissionPlugin"

const defaultGetConfig = {
  params: {
    perPage: 200,
  },
}
export const useSettingsStore = defineStore('settings', {
  persistedState: {
    persist: true,
    storage: window.localStorage,
    includePaths: ['settings'],
  },
  state: () => {
    return {
      evaluationTypes: [] as Data<EvaluationType>[],
      evaluationTypesLoading: false,
      documentTypes: [] as Data<DocumentType>[],
      documentTags: [] as Data<DocumentTag>[],
      expenseCategories: [] as Data<any>[],
      documentTypesLoading: false,
      contractTypes: [] as Data<ContractType>[],
      contractTypesLoading: false,
      positions: [] as Data<Position>[],
      positionsLoading: false,
      departments: [] as Data<Department>[],
      departmentsLoading: false,
      holidayPolicies: [] as Data<HolidayPolicy>[],
      holidayBalances: [] as any[],
      legalHolidays: [] as Data<LegalHoliday>[],
      roles: [] as Data<Role>[],
      levels: [] as Data<Level>[],
      rolesLoading: false,
      levelsLoading: false,
      currentPolicy: {} as HolidayPolicy,
      currentPolicyLoading: false,
      currentChecklistTemplate: {} as Data<any>,
      currentChecklistTemplateLoading: false,
      holidayPoliciesLoading: false,
      holidayBalancesLoading: false,
      settings: [] as Data<Setting>[],
      tenantData: {} as Record<string, any>,
      userSettings: [] as Data<Setting>[],
      settingsLoading: false,
      userSettingsLoading: false,
    }
  },
  actions: {
    async getSettings(invalidateCache = false) {
      try {
        this.settingsLoading = true
        const { data } = await Cache.getRequest('/restify/settings?perPage=50', {
          invalidateCache,
          params: {
            perPage: 100,
          },
        })
        this.settings = data
      } finally {
        this.settingsLoading = false
      }
    },
    async getUserSettings(invalidateCache = false) {
      try {
        this.userSettingsLoading = true
        const { data } = await Cache.getRequest('/restify/user-settings', {
          invalidateCache,
        })
        this.userSettings = data
      } finally {
        this.userSettingsLoading = false
      }
    },
    async updateSetting(key: SettingsKeyType, value: any) {
      try {
        const settingIndex = this.settings.findIndex((setting: Data<Setting>) => setting.attributes.key === key)
        if (settingIndex === -1) {
          console.warn(`Could not find setting with key ${key}`)
          return
        }
        const setting = this.settings[settingIndex]
        setting.attributes.value = value
        await axios.put(`/restify/settings/${setting.id}`, {
          ...setting.attributes,
          value,
        })
        this.settings[settingIndex] = setting
      } catch (err) {
        console.error(err)
      }
    },
    async getRoles() {
      try {
        this.rolesLoading = true
        const { data } = await axios.get('/restify/roles', {
          params: {
            related: 'permissions',
          },
        })
        this.roles = data.map((role: Data<Role>) => {
          role.attributes.name = capitalize(role.attributes.name)
          role.relationships!.permissions = orderBy(role.relationships?.permissions, 'attributes.description')
          return role
        })
      } finally {
        this.rolesLoading = false
      }
    },
    async getLevels() {
      try {
        this.levelsLoading = true
        const { data } = await Cache.getRequest(`/restify/levels`, defaultGetConfig)
        this.levels = data.map((level: Data<Level>) => {
          level.attributes.name = capitalize(level.attributes.name)
          return level
        })
        this.levels = orderBy(data, 'attributes.name')
      } finally {
        this.levelsLoading = false
      }
    },
    async getHolidayPolicies() {
      try {
        this.holidayPoliciesLoading = true
        const { data } = await Cache.getRequest('/restify/holiday-policies', {
          params: {
            perPage: 50,
            sort: 'name',
            temporary_urls: true,
            withMeta: true,
          },
        })
        this.holidayPolicies = data
      } finally {
        this.holidayPoliciesLoading = false
      }
    },
    async getHolidayBalances(requestParams: any = {}) {
      try {
        if (this.holidayBalancesLoading) {
          return
        }
        this.holidayBalancesLoading = true
        const { data } = await axios.get('/restify/holiday-balances', {
          params: {
            perPage: 200,
            active_employee: true,
            ...requestParams,
          },
        })

        let balances = cloneDeep(this.holidayBalances)
        balances = data.concat(balances)
        balances = uniqBy(balances, 'id')
        this.holidayBalances = balances
      } finally {
        this.holidayBalancesLoading = false
      }
      return this.holidayBalances
    },
    async getVacationPolicyBalances(requestParams: any = {}): Promise<any[]> {
      if (!requestParams.employee_id) {
        return []
      }
      try {
        this.holidayBalancesLoading = true
        const res = await axios.get('/restify/holiday-balances/getters/available-balance', {
          params: {
            holiday_policy_id: this.getVacationPolicy?.id,
            ...requestParams,
          },
        })
        this.holidayBalancesLoading = false
        return res as any[]
      } catch (err) {
        console.warn(err)
        return []
      } finally {
        this.holidayBalancesLoading = false
      }
    },
    async getHolidayPolicy(id: string) {
      try {
        this.currentPolicyLoading = true
        const { data } = await Cache.getRequest(`/restify/holiday-policies/${id}`, {
          params: {
            temporary_urls: true,
          },
        })
        this.currentPolicy = data
      } finally {
        this.currentPolicyLoading = false
      }
    },
    async getChecklistTemplate(id: string) {
      try {
        this.currentChecklistTemplateLoading = true
        const { data } = await Cache.getRequest(`/restify/checklist-templates/${id}`)
        this.currentChecklistTemplate = data
      } finally {
        this.currentChecklistTemplateLoading = false
      }
    },
    async getNationalHolidays(params = {}) {
      const { data } = await Cache.getRequest('/restify/legal-holidays', {
        params: {
          sort: 'date',
          perPage: 500,
          ...params,
        },
      })
      this.legalHolidays = data
    },
    async getExpenseCategories() {
      try {
        this.rolesLoading = true
        const { data } = await axios.get('/restify/expense-categories', {
          params: {
            perPage: 200,
          },
        })
        this.expenseCategories = data
      } finally {
        this.rolesLoading = false
      }
    },
    async initHolidays() {
      await Promise.all([
        this.getHolidayPolicies(),
        this.getNationalHolidays(),
      ])
      const { userEmployeeId } = useAuth()
      if (userEmployeeId.value) {
        await this.getHolidayBalances({ employee_id: userEmployeeId.value })
      }
    },
    async getConfiguration() {
      try {
        const billingStore = useBillingStore()
        const { data } = await Cache.getRequest('/configuration', {
          params: {
            perPage: 2000,
          },
        })
        billingStore.setActivePlan(data.active_plan)
        billingStore.setSeatData(data.available_seats)
        this.evaluationTypes = orderBy(data.evaluation_types, 'attributes.name')
        this.documentTypes = orderBy(data.document_types, 'attributes.name')
        this.documentTags = orderBy(data.document_tags, 'attributes.name')
        this.contractTypes = orderBy(data.contract_types, 'attributes.name')
        this.positions = orderBy(data.positions, 'attributes.name')
        this.departments = orderBy(data.departments, 'attributes.name')
        this.legalHolidays = data.legal_holidays
        this.holidayPolicies = data.holiday_policies
        this.settings = data.settings
      } catch (err) {
        console.error(err)
      }
    },
    async getTenantData() {
      if (!can('manageCompany')) {
        return
      }
      const { data } = await axios.get('/restify/tenants/getters/tenant-information')
      this.tenantData = data
    },
    async init() {
      await this.getTenantData()
      await this.getConfiguration()
    },
    async deleteTenant(data: any) {
      await axios.post(`/tenant/delete`, data)
    },
  },
  getters: {
    hasDemoData(): boolean {
      return this.tenantData.has_demo_data && can('manageCompany')
    },
    getRolePermissions(state) {
      return (roleId: string) => {
        return state.roles.find(r => r.id === roleId)?.relationships?.permissions || []
      }
    },
    getRoleById(state) {
      return (id: string): Data<Role> | undefined => {
        return state.roles.find(d => d.id?.toString() === id?.toString())
      }
    },
    getRoleName() {
      return (id: string): string => {
        return this.getRoleById(id)?.attributes.name || ''
      }
    },
    getDepartmentById(state) {
      return (id: string) => {
        return state.departments.find(d => d.id?.toString() === id?.toString())
      }
    },
    getPositionById(state) {
      return (id: string): Data<Position> | undefined => {
        return state.positions.find(d => d.id?.toString() === id?.toString())
      }
    },
    getPositionName() {
      return (id: string): string => {
        return this.getPositionById(id)?.attributes.name || ''
      }
    },
    getContractTypeByName(state) {
      return (name: string) => {
        return state.contractTypes.find(d => d.attributes.name === name)
      }
    },
    getHolidayPolicyById(state) {
      return (id: string) => {
        return state.holidayPolicies.find(d => d.id?.toString() === id.toString())
      }
    },
    getPoliciesForEmployee(state) {
      return (employeeId: string) => {
        const employeeStore = useEmployeeStore()
        const employee = employeeStore.getEmployeeById(employeeId)
        return state.holidayPolicies.filter((policy: Data<HolidayPolicy>) => {
          const contractType = employee?.attributes?.contract_type
          if (!contractType) {
            return true
          }
          return policy.attributes.applicable_contract_types.includes(contractType)
        })
      }
    },
    getDocumentTypeById(state) {
      return (id: string) => {
        return state.documentTypes.find(d => d.id?.toString() === id?.toString())
      }
    },
    getPolicyById(state) {
      return (id: string) => {
        return state.holidayPolicies.find(d => d.id?.toString() === id?.toString())
      }
    },
    groupedPolicies(state) {
      return groupBy(state.holidayPolicies, 'attributes.type')
    },
    getLegalHolidaysByYear(state) {
      return (year: number) => {
        return state.legalHolidays.filter(h => h.attributes.year === year?.toString())
      }
    },
    getHolidayPolicyBalanceObject(state) {
      return (policyId: string, employeeId: string) => {
        return state.holidayBalances.find((p) => {
          const { holiday_policy_id, employee_id } = p.attributes
          return holiday_policy_id?.toString() === policyId && employee_id?.toString() === employeeId
        })
      }
    },
    getHolidayPolicyBalance() {
      return (policyId: string, employeeId: string) => {
        const balance = this.getHolidayPolicyBalanceObject(policyId, employeeId)
        return balance?.attributes?.balance || 0
      }
    },
    getHolidayPolicyInitialBalance() {
      return (policyId: string, employeeId: string) => {
        const balance = this.getHolidayPolicyBalanceObject(policyId, employeeId)
        return balance?.attributes?.initial_balance || 0
      }
    },
    getVacationPolicy(state): Data<HolidayPolicy> | undefined {
      return state.holidayPolicies.find(p => p.attributes.type === HolidayTypes.TimeOff && p.attributes.days_per_year > 0 && p.attributes.paid)
    },
    getCurrentEmployee() {
      const employeeStore = useEmployeeStore()
      const { userEmployeeId } = useAuth()
      let employeeId = employeeStore.currentEmployee?.id as string
      if (!employeeId) {
        employeeId = userEmployeeId.value as string
      }
      return employeeId
    },
    getVacationPolicyBalance() {
      const holidayPolicy = this.getVacationPolicy as Data<HolidayPolicy>
      // @ts-expect-error
      return holidayPolicy ? this.getHolidayPolicyBalance(holidayPolicy.id, this.getCurrentEmployee) : 0
    },
    getVacationPolicyInitialBalance() {
      const holidayPolicy = this.getVacationPolicy as Data<HolidayPolicy>
      // @ts-expect-error
      return holidayPolicy ? this.getHolidayPolicyInitialBalance(holidayPolicy.id, this.getCurrentEmployee) : 0
    },
    getPoliciesForContractType(state) {
      return (contractType: string) => {
        return state.holidayPolicies.filter(p => p.attributes.applicable_contract_types?.includes(contractType))
      }
    },
    groupedPoliciesForContractType() {
      return (contractType: string) => {
        return groupBy(this.getPoliciesForContractType(contractType), 'attributes.type')
      }
    },
    getSettingObject() {
      return (key: SettingsKeyType) => {
        return this.settings.find(s => s.attributes.key === key)
      }
    },
    getSetting() {
      return (key: SettingsKeyType) => {
        const defaultDueDays = 30
        const defaultTax = 0
        const booleanSettings = [SettingKeys.ShowTimesheetDates, SettingKeys.IncludeHolidayWeekends]
        if (booleanSettings.includes(key as any)) {
          return this.getSettingBoolean(key)
        }
        const settingValue = this.settings.find(s => s.attributes.key === key)?.attributes.value
        if (key === SettingKeys.InvoiceDueDays) {
          const numberValue = +settingValue as string
          return isNaN(numberValue) ? defaultDueDays : numberValue
        }
        if (key === SettingKeys.InvoiceTax && !settingValue) {
          return defaultTax
        }
        if (settingValue === '0') {
          return false
        }
        return settingValue
      }
    },
    getSettingBoolean() {
      return (key: SettingsKeyType): boolean => {
        const setting = this.settings.find(s => s.attributes.key === key)
        if (!setting) {
          return false
        }
        const value = setting.attributes.value
        if (value === '0' || value === 'false' || value === null) {
          return false
        }
        return !!value
      }
    },
    showTimesheetDates(): boolean {
      return this.getSettingBoolean(SettingKeys.ShowTimesheetDates)
    },
    getUserSetting() {
      return (key: SettingsKeyType) => {
        return this.userSettings.find(s => s.attributes.key === key)?.attributes.value
      }
    },
    getExpenseCategoryById(state) {
      return (id: string) => {
        return state.expenseCategories.find(d => d.id?.toString() === id?.toString())
      }
    },
    getHiddenModules(): string[] {
      const hiddenModules = this.getSetting(SettingKeys.HiddenModules) as string
      try {
        return JSON.parse(hiddenModules)
      } catch (err) {
        return []
      }
    },
    isModuleVisible() {
      return (moduleName: string): boolean => !this.getHiddenModules?.includes(moduleName)
    },
  },
})
