<template>
  <ChecklistTemplateForm
    :data="currentChecklistTemplate"
    :loading="currentChecklistTemplateLoading"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import ChecklistTemplateForm from "@/modules/settings/components/checklists/ChecklistTemplateForm.vue"

const settingsStore = useSettingsStore()
const { currentChecklistTemplate, currentChecklistTemplateLoading } = storeToRefs(settingsStore)
</script>

<route lang="yaml">
name: Onboarding Template Edit
meta:
  permission: manageChecklists
</route>
