<template>
  <div>
    <FormKit
      id="holiday-form"
      type="form"
      :actions="actions"
      :disabled="isFormDisabled"
      @submit="onSubmit"
    >
      <component :is="wrapperComponent" v-bind="$attrs">
        <HolidayEditDisabledTip
          v-if="isFormDisabled"
          :holiday="props.holiday"
        />
        <div class="grid grid-cols-6 gap-x-4">
          <FormKit
            v-if="!props.employeeId && $can('manageHolidays')"
            v-model="model.employee_id"
            :disabled="isEdit"
            :label="$t('Employee')"
            :placeholder="$t('John Doe')"
            type="employeeSelect"
            validation="required"
            outer-class="col-span-6"
            @update:modelValue="onEmployeeChange"
          />

          <FormKit
            v-model="model.holiday_policy_id"
            :contract_type="selectedEmployee?.attributes?.contract_type"
            :label="$t('Holiday Type')"
            :placeholder="$t('Sick leave')"
            validation="required"
            type="holidayPolicySelect"
            outer-class="col-span-6"
            @update:fullValue="holidayPolicy = $event"
          >
            <template #suffix>
              <div class="input-suffix text-base-300 text-sm mr-8 !border-l-0 !border-r-0">
                <div class="flex items-center space-x-1">
                  <template v-if="holidayPolicy?.attributes?.paid">
                    <CheckIcon class="text-primary w-4 h-4" />
                    <span>{{ $t('Paid') }}</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="w-4 h-4" />
                    <span>{{ $t('Unpaid') }}</span>
                  </template>
                  <template v-if="selectedPolicyBalance">
                    <CheckIcon class="text-primary w-4 h-4 ml-4" />
                    <span>{{ selectedPolicyBalance }}</span>
                  </template>
                </div>
              </div>
            </template>
          </FormKit>

          <HolidayRequestIntervalPicker
            :key="intervalPickerKey"
            :data="model"
            @change="onIntervalChange"
          />

          <div v-if="selectedDaysCount" class="col-span-6 flex flex-wrap items-center mt-2">
            <div class="flex items-center text-base-300 text-sm mr-6">
              <CheckIcon class="text-primary w-4 h-4 mr-1" />
              <span>{{ $t('days', selectedDaysCount) }} {{ $t('of holiday') }}</span>
            </div>
            <div
              v-if="showRemainingDays"
              class="flex items-center text-base-300 text-sm mr-6"
            >
              <CalendarDaysIcon class="text-primary w-4 h-4 mr-1" />
              <span>{{
                $t('days', selectedPolicyBalance - selectedDaysCount)
              }} {{ $t('remaining after taking this holiday') }}</span>
            </div>
          </div>

          <WarningTip v-if="noRemainingDays" class="col-span-6 mt-2">
            {{ $t(`There are no holidays left. Please choose a shorter interval or use a different holiday type.`) }}
          </WarningTip>

          <WarningAlert v-if="isMoreThan1Year && policyAllowsAdvanceBooking" class="col-span-6 mt-2">
            {{ $t(`When you request a holiday spanning across years, the holiday balances for each year will be updated accordingly.`) }}
          </WarningAlert>

          <WarningAlert v-if="isForNextYear && !isMoreThan1Year && policyAllowsAdvanceBooking" class="col-span-6 mt-2">
            {{ $t(`Holiday request for next year`, { currentYear: new Date().getFullYear(), holidayRequestEndYear }) }}
          </WarningAlert>

          <WarningTip v-if="showAdvancedBookingWarning" class="col-span-6 mt-2">
            {{ $t('Unfortunately, the selected holiday policy does not allow requesting holidays in advance for the next year.') }}
          </WarningTip>

          <WarningTip v-if="waitingPeriodRestriction" class="col-span-6 mt-2">
            {{ $t(`Unfortunately you cannot take holidays earlier than`) }} {{ formatDate(waitingPeriodDate, 'do MMMM yyyy') }}
          </WarningTip>

          <FormKit
            v-model="model.notes"
            :label="$t('Notes')"
            :placeholder="$t(`We're going to the sea with my family in Croatia :)`)"
            type="textarea"
            rows="4"
            validation="length:0,255"
            outer-class="col-span-6"
          />
          <a
            v-if="holidayPolicy?.attributes?.document_template_path"
            :href="holidayPolicy?.attributes?.document_template_path"
            target="_blank"
            rel="noopener"
            class="flex col-span-6 mt-4 -mb-2 no-underline justify-end items-center space-x-1 text-primary"
          >
            <span class="text-sm font-medium">{{ $t('Download example') }}</span>
            <FolderArrowDownIcon class="w-4 h-4" />
          </a>
          <FileDropzone
            v-model="model.document_path"
            :file-name="model.file_name"
            :disabled="isFormDisabled"
            :multiple="false"
            :file-progress="fileProgress"
            :accept="acceptedFileTypes"
            size="sm"
            class="col-span-6 mt-4"
          />

          <FormKit
            v-model="model.requested_approver_id"
            :label="$t('Approver')"
            :placeholder="$t('Holiday approver')"
            :fetch-at-start="true"
            :url-params="{
              permissions: 'approveRejectHolidays',
            }"
            :help="$t('You can optionally select a specific approver to send notifications to.')"
            url="/restify/employees"
            type="employeeSelect"
            outer-class="col-span-6"
          />
        </div>
        <template #footer>
          <div class="w-full flex justify-center space-x-2">
            <FormKit
              type="button"
              :classes="{ input: 'min-w-[128px] btn-outline' }"
              @click="$emit('close')"
            >
              <span>{{ $t('Cancel') }}</span>
            </FormKit>
            <FormKit
              type="submit"
              :classes="{ input: 'min-w-[128px]' }"
              :disabled="loading || noRemainingDays || isFormDisabled || waitingPeriodRestriction"
            >
              <span>{{ $t('Request holiday') }}</span>
            </FormKit>
          </div>
        </template>
      </component>
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import axios from "axios"
import { PropType, computed, nextTick, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { CalendarDaysIcon, CheckIcon, FolderArrowDownIcon, XMarkIcon } from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { cloneDeep } from "lodash-es"
import { ElDialog } from "element-plus"
import { addDays, parseISO } from "date-fns"
import { useRoute, useRouter } from "vue-router"
import { FormKitNode } from "@formkit/core"
import Cache from "@/modules/common/Cache"
import { error, success } from "@/components/common/NotificationPlugin"
import FileDropzone from '@/modules/documents/components/FileDropzone.vue'
import { FileProgress } from "@/modules/documents/types/documentTypes"
import { canEditHoliday, differenceInWorkingDays } from "@/modules/holidays/utils/holidayUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { API_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import WarningTip from "@/components/common/WarningTip.vue"
import { acceptedFileTypes, updateFileProgress } from "@/modules/documents/utils/documentUtils"
import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-overlay.css'
import 'element-plus/theme-chalk/el-dialog.css'
import HolidayEditDisabledTip from "@/modules/holidays/components/HolidayEditDisabledTip.vue"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Holiday = App.Domains.Holidays.Models.Holiday
import Data = API.Data
import HolidayRequestIntervalPicker from "@/modules/holidays/components/HolidayRequestIntervalPicker.vue"
import { trackHolidayCreated } from "@/util/analytics"
const props = defineProps({
  holiday: {
    type: Object as PropType<Data<Holiday>>,
  },
  dialog: {
    type: Boolean,
    default: false,
  },
  actions: {
    type: Boolean,
    default: true,
  },
  employeeId: {
    type: String,
    default: '',
  },
  start: {
    type: Date,
  },
  end: {
    type: Date,
  },
})
const emit = defineEmits(['close', 'save'])
const { t } = useI18n()
const wrapperComponent = computed(() => {
  return props.dialog ? ElDialog : 'div'
})

const isEdit = computed(() => {
  return props.holiday?.id
})

const holidayPolicy = ref(null)
const initialFormState = {
  holiday_policy_id: '',
  employee_id: props.employeeId,
  start_date: props.start || null,
  end_date: props.end || null,
  notes: '',
  interval: [] as Date[],
  files: [],
  file_name: '',
  document_path: null,
  requested_approver_id: '',
}

const fileProgress = ref<FileProgress>({})

const model = ref(cloneDeep(initialFormState))
const loading = ref(false)
const settingsStore = useSettingsStore()
const employeeStore = useEmployeeStore()
const { legalHolidays, holidayBalances } = storeToRefs(settingsStore)

setManagerAsApprover()
const isFormDisabled = computed<boolean>(() => {
  return !canEditHoliday(props.holiday)
})
const selectedDaysCount = computed(() => {
  const interval = model.value.interval || []
  if (interval.length < 2) {
    return ''
  }
  const [start, end] = cloneDeep(interval)
  return differenceInWorkingDays(start, end, legalHolidays.value)
})

const selectedPolicyBalance = computed(() => {
  const { employee_id, holiday_policy_id } = model.value
  if (!employee_id || !holiday_policy_id) {
    return
  }
  const balance = holidayBalances.value.find((b) => {
    return b.attributes.employee_id?.toString() === employee_id.toString() && b.attributes.holiday_policy_id.toString() === holiday_policy_id.toString()
  })
  return balance?.attributes?.balance || 0
})

const isPaidPolicy = computed(() => {
  return holidayPolicy.value?.attributes?.paid
})

const isUnlimitedPolicy = computed(() => {
  return holidayPolicy.value?.attributes?.days_per_year === 0
})

const policyAllowsAdvanceBooking = computed(() => {
  return holidayPolicy.value?.attributes?.allow_advance_booking
})

const holidayRequestEndYear = computed(() => {
  if (!model.value.start_date || !model.value.end_date) {
    return currentYear
  }
  return parseISO(model.value.end_date)?.getFullYear()
})

const isForNextYear = computed(() => {
  if (!model.value.start_date || !model.value.end_date) {
    return false
  }
  const endYear = parseISO(model.value.end_date)?.getFullYear()
  const currentYear = new Date().getFullYear()
  return endYear > currentYear
})

const isMoreThan1Year = computed(() => {
  if (!model.value.start_date || !model.value.end_date) {
    return false
  }
  try {
    const startYear = parseISO(model.value.start_date)?.getFullYear()
    const endYear = parseISO(model.value.end_date)?.getFullYear()
    if (isNaN(startYear) || isNaN(endYear)) {
      return false
    }
    return startYear !== endYear
  } catch (err) {
    return false
  }
})

const noRemainingDays = computed(() => {
  if (selectedPolicyBalance.value === undefined) {
    return false
  }
  if (policyAllowsAdvanceBooking.value && isForNextYear.value) {
    return false
  }
  const daysPerYear = holidayPolicy.value?.attributes?.days_per_year
  if (daysPerYear === 0 && isPaidPolicy.value) {
    // Unlimited paid holiday policy (for example Medical Leave)
    return false
  }
  if (!isPaidPolicy.value) {
    return false
  }
  return selectedDaysCount.value > selectedPolicyBalance.value
})

const showRemainingDays = computed(() => {
  if (policyAllowsAdvanceBooking.value && isForNextYear.value) {
    return false
  }
  return model.value.holiday_policy_id && model.value.employee_id && !isUnlimitedPolicy.value
})

const showAdvancedBookingWarning = computed(() => {
  return isForNextYear.value && !policyAllowsAdvanceBooking.value && !isUnlimitedPolicy.value
})

const selectedEmployee = computed(() => {
  return employeeStore.getEmployeeById(model.value.employee_id)
})

const waitingPeriodRestriction = computed(() => {
  const waitingPeriodDays = holidayPolicy.value?.attributes?.waiting_period_days
  if (!waitingPeriodDays || !model.value.start_date) {
    return false
  }
  const holidayStartDate = parseISO(model.value.start_date)
  return holidayStartDate?.getTime() < waitingPeriodDate.value.getTime()
})

const waitingPeriodDate = computed(() => {
  const waitingPeriodDays = holidayPolicy.value?.attributes?.waiting_period_days
  if (!waitingPeriodDays) {
    return new Date()
  }
  const workingStartDate = parseISO(selectedEmployee.value?.attributes?.start_date)
  return addDays(workingStartDate, waitingPeriodDays)
})

function prepareFormData(): FormData {
  const formData = new FormData()
  const [start_date, end_date] = cloneDeep(model.value.interval)
  model.value.start_date = formatDate(start_date, API_DATE_FORMAT)
  model.value.end_date = formatDate(end_date, API_DATE_FORMAT)

  if (model.value.document_path) {
    updateFileProgress({
      file: model.value.document_path,
      progress: 0,
      fileProgress,
    })
    formData.append('document_path', model.value.document_path)
  }

  formData.append('holiday_policy_id', model.value.holiday_policy_id)
  formData.append('start_date', model.value.start_date)
  formData.append('end_date', model.value.end_date)

  if (model.value.requested_approver_id) {
    formData.append('requested_approver_id', model.value.requested_approver_id)
  }

  if (model.value.notes) {
    formData.append('notes', model.value.notes)
  }
  if (model.value.employee_id) {
    formData.append('employee_id', model.value.employee_id)
  }

  return formData
}

function disableWeekends(node: FormKitNode, date: Date) {
  // Re-implement min-date and max-date
  if (node.props.minDate && date < node.props.minDate) {
    return true
  }
  if (node.props.maxDate && date > node.props.maxDate) {
    return true
  }

  // If the date is a weekend, disable it.
  return date.getDay() === 0 || date.getDay() === 6
}

const intervalPickerKey = ref(0)
function onIntervalChange(data: any) {
  model.value.start_date = data.start_date
  model.value.end_date = data.end_date
  model.value.interval = data.interval
}

function setManagerAsApprover() {
  const employee = employeeStore.getEmployeeById(model.value.employee_id)
  const managerId = employee?.attributes?.manager_employee_id
  if (managerId && !model.value.requested_approver_id) {
    model.value.requested_approver_id = managerId
  }
}

async function onEmployeeChange(employeeId: string) {
  await settingsStore.getHolidayBalances({
    employee_id: employeeId,
  })
  model.value.requested_approver_id = ''
  setManagerAsApprover()
  if (model.value.employee_id === employeeId) {
    return
  }
  model.value.holiday_policy_id = ''
}

const router = useRouter()
onMounted(async () => {
  if (props.employeeId) {
    await onEmployeeChange(props.employeeId)
  }
})

const { getSaveRedirectPath } = useFilterTabs('holidays')
const route = useRoute()

function getRedirectPath() {
  const isEmployeeHolidaysPage = route.name === t('Employee Holidays')
  if (isEmployeeHolidaysPage) {
    return route.path
  }
  return getSaveRedirectPath()
}

async function onSubmit() {
  try {
    loading.value = true
    const formData = prepareFormData()

    const url = isEdit.value ? `/restify/holidays/${props.holiday?.id}` : '/restify/holidays'
    await axios.post(url, formData, {
      onUploadProgress: ({ progress }: any) => {
        updateFileProgress({
          file: model.value.document_path,
          fileProgress,
          progress: progress * 100,
        })
      },
    })
    updateFileProgress({
      file: model.value.document_path,
      fileProgress,
      progress: 100,
    })
    if (isEdit.value) {
      success(t('Holiday request updated'))
    } else {
      success(t('Holiday request created'))
    }
    emit('save')
    trackHolidayCreated()
    await router.push(getRedirectPath())
  } catch (err: any) {
    if (err.handled) {
      return
    }
    console.error(err)
  } finally {
    loading.value = false
  }
}

defineExpose({
  onSubmit,
})

watch(() => props.holiday, (value) => {
  if (!value?.id) {
    return
  }
  model.value = {
    ...model.value,
    ...(value.attributes || {}),
  }
  const start = parseISO(model.value.start_date)
  const end = parseISO(model.value.end_date)
  model.value.interval = [start, end]
}, { immediate: true })
</script>
