<template>
  <div class="flex flex-wrap gap-2 md:gap-3">
    <HolidayTypeCard
      v-if="displayVacationTab"
      :type="HolidayTypes.TimeOff"
      :has-link="false"
      :balances="balances"
    />

    <HolidayTypeCard :type="HolidayTypes.National" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import HolidayTypeCard from "@/modules/employees/components/HolidayTypeCard.vue"
import { HolidayTypes } from "@/modules/settings/types/settingTypes"
import Data = API.Data
import HolidayPolicy = App.Domains.HolidayPolicies.Models.HolidayPolicy

const props = defineProps({
  employeeId: {
    type: String,
  },
})
const settingsStore = useSettingsStore()
const balances = ref<any[]>([])

const policiesForEmployee = computed(() => {
  return settingsStore.getPoliciesForEmployee(props.employeeId as string)
})

const allowedPolicyTypes = computed(() => {
  return policiesForEmployee.value.map((policy: Data<HolidayPolicy>) => policy.attributes.type)
})

const displayVacationTab = computed(() => {
  return policiesForEmployee.value.find((policy: Data<HolidayPolicy>) => {
    return policy.attributes.type === HolidayTypes.TimeOff && policy.attributes.paid
  }) !== undefined
})

const displayOtherCard = computed(() => {
  return allowedPolicyTypes.value.includes(HolidayTypes.Other)
})

async function getBalances() {
  balances.value = await settingsStore.getVacationPolicyBalances({
    employee_id: props.employeeId,
  })
}

onMounted(async () => {
  await settingsStore.getHolidayBalances({
    employee_id: props.employeeId,
    related: 'holidayPolicy',
  })
  await getBalances()
})

watch(() => props.employeeId, getBalances)

defineExpose({
  refresh: getBalances,
})
</script>
