<template>
  <span
    :class="{
      'text-red-700': isOverdue,
      'text-yellow-700': isDueSoon,
      'text-gray-600': isNotDue,
    }"
    class="text-sm"
  >
    {{ dueDate }}
  </span>
</template>

<script setup>
import { computed } from "vue"
import { differenceInDays } from "date-fns"
import { useI18n } from "vue-i18n"
import { formatDate } from "@/modules/common/utils/dateUtils"

const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

const daysUntilDue = computed(() => {
  const dueDate = new Date(props.task.attributes?.due_date)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return differenceInDays(dueDate, today)
})

const isOverdue = computed(() => {
  return daysUntilDue.value <= 0
})

const isDueSoon = computed(() => {
  return daysUntilDue.value > 0 && daysUntilDue.value <= 3
})

const isNotDue = computed(() => {
  return daysUntilDue.value > 3
})

const dueDate = computed(() => {
  if (daysUntilDue.value === 0) {
    return t('Due today')
  }
  if (daysUntilDue.value === 1) {
    return t('Due tomorrow')
  }
  return formatDate(props.task.attributes?.due_date, 'MMM do')
})
</script>

<style>
</style>
