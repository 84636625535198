<template>
  <TabLinks v-model="activeTab" :tabs="tabs" size="sm" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { DocumentDuplicateIcon, TagIcon } from "@heroicons/vue/24/outline"
import i18n from "@/i18n"

const activeTab = ref(i18n.t('Document Types'))
const tabs = computed(() => {
  return [
    {
      label: i18n.t('Document Types'),
      path: `/settings/documents`,
      icon: DocumentDuplicateIcon,
    },
    {
      label: i18n.t('Tags'),
      path: `/settings/tags`,
      icon: TagIcon,
    },
  ]
})
</script>
