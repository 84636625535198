<template>
  <FormCardSection
    :title="$t('Modules')"
  >
    <div class="space-y-3 col-span-6 mt-4">
      <FormKit
        v-for="module in modules"
        :key="module.key"
        :name="module.name"
        :model-value="isModuleEnabled(module)"
        :disabled="isCheckboxDisabled(module)"
        type="checkbox"
        :label="module.name"
        :help="module.description"
        @update:model-value="toggleModule(module, $event)"
      />
    </div>
  </FormCardSection>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CalendarIcon,
  ChatBubbleLeftRightIcon, CheckBadgeIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  InboxIcon,
  PresentationChartLineIcon,
  ReceiptPercentIcon,
  ReceiptRefundIcon,
} from "@heroicons/vue/24/outline"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { ModuleKeys, SettingKeys } from "@/modules/auth/types/enums"
import { isModuleVisible } from "@/plugins/permissionPlugin"

const settingsStore = useSettingsStore()

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
})

interface Module {
  name: string
  key: string
  description: string
  icon: any
}

const { t } = useI18n()
const modules = computed<Module[]>(() => {
  return [
    {
      name: t('Team'),
      key: ModuleKeys.Team,
      description: t('Manage team members and their roles in one place'),
      icon: ClockIcon,
    },
    {
      name: t('Time'),
      key: ModuleKeys.Time,
      description: t('Track time spent on projects and tasks. If projects or invoicing is enabled, you cannot disable this module.'),
      icon: ClockIcon,
    },
    {
      name: t('Holidays'),
      key: ModuleKeys.Holidays,
      description: t('Leave management across the company. Team members can request time off and managers can approve the requests.'),
      icon: CalendarIcon,
    },
    {
      name: t('Documents'),
      key: ModuleKeys.Documents,
      description: t('Manage employee documents and templates to generate documents'),
      icon: DocumentDuplicateIcon,
    },
    {
      name: t('Evaluations'),
      key: ModuleKeys.Evaluations,
      description: t('Employee evaluations and performance reviews'),
      icon: PresentationChartLineIcon,
    },
    {
      name: t('Feedback'),
      key: ModuleKeys.Feedback,
      description: t('Collect and give feedback for team members or clients'),
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: t('Projects'),
      key: ModuleKeys.Projects,
      description: t('Manage clients and projects. If time tracking or invoicing is enabled, you cannot disable this module.'),
      icon: InboxIcon,
    },
    {
      name: t('Invoices'),
      key: ModuleKeys.Invoices,
      description: t('Bill clients for tracked time and expenses'),
      icon: ReceiptRefundIcon,
    },
    {
      name: t('Expenses'),
      key: ModuleKeys.Expenses,
      description: t('Track expenses across projects, employees or across the company'),
      icon: ReceiptPercentIcon,
    },
    {
      name: t('Onboarding Tasks'),
      key: ModuleKeys.Onboarding,
      description: t('Manage onboarding tasks and onboarding templates for new employees'),
      icon: CheckBadgeIcon,
    },
  ]
})

const hiddenModules = computed(() => {
  try {
    return JSON.parse(props.modelValue)
  } catch (err) {
    return []
  }
})

const emit = defineEmits(['update:modelValue'])

function isModuleEnabled(module: Module) {
  return !hiddenModules.value.includes(module.key)
}

function isCheckboxDisabled(module: Module) {
  if (module.key === ModuleKeys.Projects && isModuleVisible(ModuleKeys.Projects)) {
    return isModuleVisible(ModuleKeys.Time) || isModuleVisible(ModuleKeys.Invoices)
  }
  if (module.key === ModuleKeys.Time) {
    return isModuleVisible(ModuleKeys.Invoices)
  }
  return module.key === ModuleKeys.Team
}

function toggleModule(module: Module, value: boolean) {
  const enabledModules = hiddenModules.value
  if (!value) {
    enabledModules.push(module.key)
  } else {
    const index = enabledModules.indexOf(module.key)
    if (index > -1) {
      enabledModules.splice(index, 1)
    }
  }
  emit('update:modelValue', JSON.stringify(enabledModules))
}
</script>
