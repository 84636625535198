<template>
  <PositionSelect
    ref="select"
    v-model="value"
    v-focus
    :value-key="params.valueKey || 'id'"
    :multiple="params.multiple"
    class="w-full"
    @update:modelValue="onChangeValue"
    @keydown.enter.native="goToNextCell"
  />
</template>

<script>
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import PositionSelect from "@/components/formkit/PositionSelect.vue"

export default {
  components: {
    PositionSelect,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
}
</script>
