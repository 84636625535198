<template>
  <div class="h-full flex flex-col modules-page">
    <BaseFormCard
      :submit-text="$t('Update')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
    >
      <CompanyModules
        v-model="model[SettingKeys.HiddenModules]"
      />
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettings } from "@/modules/settings/composables/useSettings"
import CompanyModules from "@/modules/settings/components/company/CompanyModules.vue"

const { t } = useI18n()

const { model, loading, updateSettings } = useSettings([
  SettingKeys.HiddenModules,
])

async function onSubmit() {
  try {
    loading.value = true
    await updateSettings()
  } finally {
    loading.value = false
  }
}
</script>

<style>
.modules-page {
  .checkbox:disabled {
    @apply opacity-70 bg-white border-primary;
  }
}
</style>

<route lang="yaml">
name: Company Modules
meta:
  permission: manageCompany
</route>
