<template>
  <div class="flex flex-col space-y-2 mb-4">
    <div
      v-if="templateId"
      class="flex w-full justify-end -mt-8">
      <AddButton @click="showAddDialog = true" size="sm">
        {{ $t('Add Task') }}
      </AddButton>
    </div>
    <TaskCard
      v-for="task in tasks"
      :key="task.id"
      :task="task"
      :is-template="true"
      @delete="onDeleteTask"
      @update:field="(field, value) => task.attributes[field] = value"
    />
    <EmptyState
      v-if="tasks.length === 0 && !loading"
      :title="$t('No Template Tasks Found')"
      :description="!templateId ? $t('In order to add tasks, create the template first') : $t('Use the button above to add a new task')"
      :icon="CheckBadgeIcon"
      class="mt-24"
    />
    <LoadingTable v-if="loading && tasks?.length > 0" class="mt-4"/>

    <TaskDialog
      v-if="showAddDialog"
      v-model="showAddDialog"
      :add="true"
      :task="addModel"
      :is-template="true"
      @save="onAddTask"
      @delete="onDeleteTask"
      @cancel="showAddDialog = false"
    />
  </div>
</template>

<script setup>
import axios from "axios"
import { onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { CheckBadgeIcon } from "@heroicons/vue/24/outline"
import TaskCard from "@/modules/settings/components/checklists/TaskCard.vue"
import LoadingTable from "@/components/table/LoadingTable.vue"
import EmptyState from "@/components/data/EmptyState.vue"
import TaskDialog from "@/modules/settings/components/checklists/TaskDialog.vue"
import { ChecklistTaskAssigneeTypes } from "@/modules/settings/components/checklists/utils"

const props = defineProps({
  templateId: {
    type: String,
  },
})

const tasks = ref([])
const loading = ref(false)
const page = ref(1)
const perPage = ref(100)
const tasksMeta = ref({})
const showAddDialog = ref(false)

const { t } = useI18n()

function getEmptyTask() {
  return {
    assignee: ChecklistTaskAssigneeTypes.Employee,
    title: '',
    description: '',
    applicable_positions: [],
    auto_create_for_new_employees: true,
    days_to_complete: 0,
    template_id: props.templateId,
  }
}

const addModel = ref({
  attributes: getEmptyTask(),
})

function onAddTask(task) {
  tasks.value.unshift(task)
  showAddDialog.value = false
}

function onDeleteTask(taskId) {
  tasks.value = tasks.value.filter(task => task.id !== taskId)
}

async function getTasks(force) {
  if (!props.templateId) {
    return
  }
  const pageParam = force ? 1 : page.value
  if (tasksMeta.value.last_page < pageParam) {
    return
  }
  try {
    loading.value = true
    const { data, meta } = await axios.get(`/restify/checklist-template-tasks`, {
      params: {
        template_id: props.templateId,
        perPage: perPage.value,
        page: pageParam,
      },
    })
    tasksMeta.value = meta
    if (force) {
      tasks.value = data
      return
    } else {
      tasks.value = tasks.value.concat(data)
    }
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  getTasks()
})
</script>

<style>
</style>
