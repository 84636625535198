<template>
  <Surface
    v-if="items.length"
    ref="scrollContainer"
    class="text-black max-h-[min(80vh,24rem)] overflow-auto flex-wrap mb-8 p-2"
  >
    <div class="grid grid-cols-1 gap-0.5">
      <template v-for="(group, groupIndex) in items" :key="`${group.title}-wrapper`">
        <div
          class="text-neutral-500 text-[0.65rem] col-[1/-1] mx-2 mt-4 font-semibold tracking-wider select-none uppercase first:mt-0.5"
        >
          {{ group.title }}
        </div>
        <DropdownButton
          v-for="(command, commandIndex) in group.commands"
          :key="`${command.label}`"
          :is-active="isActive(groupIndex, commandIndex)"
          :data-name="command.label"
          @click="selectItem(groupIndex, commandIndex)"
        >
          <Icon :name="command.iconName" class="mr-1" />
          {{ command.label }}
        </DropdownButton>
      </template>
    </div>
  </Surface>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import DropdownButton from "@/components/formkit/html/components/dropdown/DropdownButton.vue"
import Surface from "@/components/formkit/html/components/Surface.vue"
// Props
const props = defineProps({
  items: Array,
  command: Function,
})

// Refs
const scrollContainer = ref(null)
const selectedGroupIndex = ref(0)
const selectedCommandIndex = ref(0)

const activeItem = computed(() => {
  return props.items[selectedGroupIndex.value]?.commands[selectedCommandIndex.value]
})

async function scrollIntoView() {
  await nextTick()
  if (!activeItem.value) {
    return
  }
  const node = document.querySelector(`[data-name="${activeItem.value.label}"]`)
  if (node) {
    node.scrollIntoView({ block: 'nearest' })
  }
}

watch(
  () => props.items,
  () => {
    selectedGroupIndex.value = 0
    selectedCommandIndex.value = 0
  },
)

const selectItem = (groupIndex, commandIndex) => {
  const command = props.items[groupIndex].commands[commandIndex]
  props.command(command)
}

function isActive(groupIndex, commandIndex) {
  return selectedGroupIndex.value === groupIndex && selectedCommandIndex.value === commandIndex
}

const onKeyDown = (params) => {
  const event = params.event || params
  if (event.key === 'ArrowDown') {
    if (!props.items.length) {
      return false
    }

    const commands = props.items[selectedGroupIndex.value].commands

    let newCommandIndex = selectedCommandIndex.value + 1
    let newGroupIndex = selectedGroupIndex.value

    if (commands.length - 1 < newCommandIndex) {
      newCommandIndex = 0
      newGroupIndex = selectedGroupIndex.value + 1
    }

    if (props.items.length - 1 < newGroupIndex) {
      newGroupIndex = 0
    }

    selectedCommandIndex.value = newCommandIndex
    selectedGroupIndex.value = newGroupIndex
    scrollIntoView()

    return true
  }

  if (event.key === 'ArrowUp') {
    if (!props.items.length) {
      return false
    }

    let newCommandIndex = selectedCommandIndex.value - 1
    let newGroupIndex = selectedGroupIndex.value

    if (newCommandIndex < 0) {
      newGroupIndex = selectedGroupIndex.value - 1
      newCommandIndex = props.items[newGroupIndex]?.commands.length - 1 || 0
    }

    if (newGroupIndex < 0) {
      newGroupIndex = props.items.length - 1
      newCommandIndex = props.items[newGroupIndex].commands.length - 1
    }

    selectedCommandIndex.value = newCommandIndex
    selectedGroupIndex.value = newGroupIndex
    scrollIntoView()

    return true
  }

  if (event.key === 'Enter') {
    if (!props.items.length || selectedGroupIndex.value === -1 || selectedCommandIndex.value === -1) {
      return false
    }

    selectItem(selectedGroupIndex.value, selectedCommandIndex.value)

    return true
  }

  return false
}

defineExpose({
  onKeyDown,
})
</script>
