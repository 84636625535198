<template>
  <div v-if="!collapsable" class="bg-white border border-base-200 rounded px-3 py-4 md:p-8 content-card">
    <slot name="title">
      <h3 v-if="title" class="mb-6 leading-none">
        {{ title }}
      </h3>
    </slot>
    <slot></slot>
  </div>
  <ElCollapse v-else v-model="activeNames" class="bg-white border border-base-200 rounded px-3 py-4 md:p-8 content-card">
    <ElCollapseItem :name="collapseName">
      <template #title>
        <slot name="title">
          <h3 v-if="title" class="mb-6 leading-none">
            {{ title }}
          </h3>
        </slot>
      </template>
      <slot></slot>
    </ElCollapseItem>
  </ElCollapse>
</template>

<script setup lang="ts">
import { ref, useId, watch } from "vue"

const props = defineProps({
  title: String,
  collapsable: {
    type: Boolean,
  },
  collapsed: {
    type: Boolean,
    default: false,
  },
})

const collapseName = useId()
const activeNames = ref([collapseName])

watch(() => props.collapsed, (value) => {
  if (!props.collapsable) {
    return
  }
  if (value) {
    activeNames.value = []
  } else {
    activeNames.value = [collapseName]
  }
}, { immediate: true })
</script>

<style lang="scss">
.content-card {
  .el-collapse-item__header {
    @apply h-auto mb-2;
  }
  .el-collapse-item__content {
    @apply py-0;
    font-size: inherit;;
  }
  .el-collapse-item__wrap {
    @apply border-0;
  }
}
</style>
