<template>
  <BaseFormDialog
    v-bind="$attrs"
    :loading="loading"
    :submit-text="t('Add Task')"
    width="80vw"
    class="task-dialog relative"
    @submit="saveTask"
  >
    <div class="absolute right-[48px] top-[15px]">
      <ActionsDropdown
        v-if="actions && can('manageChecklists')"
        :actions="actions"
      >
        <template #trigger>
          <BaseButton outline size="xs">
            <EllipsisHorizontalIcon class="w-5 h-5 text-primary" />
          </BaseButton>
        </template>
      </ActionsDropdown>
    </div>
    <div class="col-span-6 md:col-span-4 md:mt-6 px-2 md:px-0">
      <EditableField
        :show-buttons="true"
        :default-edit="add"
        @save="updateTaskField('title', model.title)"
        @cancel="revertTaskField('title')"
      >
        <h3>{{ task.attributes.title }} </h3>
        <template #edit="{ save }">
          <FormKit
            v-model="model.title"
            v-focus
            :label="add ? $t('Task Title') : null"
            :placeholder="$t('Task Title')"
            name="title"
            type="text"
            validation="required"
            inner-class="!text-xl font-semibold"
            @keydown.enter="add ? null : save()"
          />
        </template>
      </EditableField>
      <div class="mt-4">
        <h5>{{ $t('Description') }}</h5>
        <EditableField
          :show-buttons="true"
          :default-edit="add"
          @save="updateTaskField('description', model.description)"
          @cancel="revertTaskField('description')"
        >
          <HtmlEditor
            :model-value="task.attributes.description || $t('No description provided')"
            :read-only="true"
            class="px-0 mt-2"
          />
          <template #edit>
            <div>
              <HtmlEditor
                v-model="model.description"
                class="px-0"
              />
            </div>
          </template>
        </EditableField>
      </div>
    </div>
    <div class="col-span-6 md:col-span-2 md:mt-6 px-2 md:px-0">
      <div class="bg-white border border-gray-200 rounded-sm">
        <div class="px-4 py-3 border-b border-gray-200">
          <ContentCardRow :title="$t('Details')" class="!mb-0" />
        </div>
        <div class="px-4 py-3">
          <template v-if="isTemplate">
            <ContentCardRow :title="$t('Assignee')">
              <EditableField
                :can-edit="can('manageChecklists')"
                @save="updateTaskField('assignee', model.assignee)"
                @cancel="revertTaskField('assignee')"
              >
                <AssigneeLabel :value="task.attributes.assignee" />
                <template #edit="{ save }">
                  <FormKit
                    v-model="model.assignee"
                    v-focus
                    type="employeeSelect"
                    :extra-options="getAssigneeOptions()"
                    :placeholder="$t('Employee')"
                    outer-class="col-span-6 md:col-span-2"
                    @update:model-value="save"
                  />
                </template>
              </EditableField>
            </ContentCardRow>
            <ContentCardRow :title="$t('For positions')">
              <EditableField
                :can-edit="can('manageChecklists')"
                @save="updateTaskField('applicable_positions', model.applicable_positions)"
                @cancel="revertTaskField('applicable_positions')"
              >
                <ApplicablePositionsLabel :value="task.attributes.applicable_positions" />
                <template #edit="{ save }">
                  <FormKit
                    v-model="model.applicable_positions"
                    v-focus
                    :multiple="true"
                    type="positionSelect"
                    class="w-full"
                  />
                </template>
              </EditableField>
            </ContentCardRow>
            <ContentCardRow :title="$t('Due')">
              <EditableField
                :can-edit="can('manageChecklists')"
                @save="updateTaskField('days_to_complete', model.days_to_complete)"
                @cancel="revertTaskField('days_to_complete')"
              >
                <div>
                  <DueDaysLabel :value="task.attributes.days_to_complete" />
                </div>
                <template #edit="{ save }">
                  <div>
                    <FormKit
                      :model-value="daysToCompleteValue"
                      v-focus
                      filterable
                      type="customSelect"
                      :options="getDueDayOptions()"
                      class="w-full"
                      @update:modelValue="onUpdateDaysToComplete($event, save)"
                    />
                    <div class="mt-1">
                      <FormKit
                        v-if="showCustomDaysToComplete && daysToCompleteValue === DueDays.Custom"
                        v-model="model.days_to_complete"
                        v-focus
                        :placeholder="$t('Days to complete')"
                        type="number"
                        @keydown.enter="save"
                      />
                    </div>
                  </div>
                </template>
              </EditableField>
            </ContentCardRow>
          </template>
          <template v-else>
            <ContentCardRow :title="$t('Employee')">
              <EmployeeLink :params="{ value: task.attributes.employee_id }" :show-link="false" />
            </ContentCardRow>
            <ContentCardRow :title="$t('Status')">
              <TaskStatusDropdown :task="task" :update-status="updateStatus" />
            </ContentCardRow>
            <ContentCardRow :title="$t('Due Date')">
              <EditableField
                @save="updateTaskField('due_date', model.due_date)"
                @cancel="revertTaskField('due_date')"
              >
                <TaskDueDate :task="task" />
                <template #edit="{ save }">
                  <FormKit
                    v-model="model.due_date"
                    v-focus
                    name="due_date"
                    type="customDatePicker"
                    validation="required"
                    @update:model-value="save"
                  />
                </template>
              </EditableField>
            </ContentCardRow>
            <ContentCardRow :title="$t('Assignee')">
              <EditableField
                :can-edit="$can('manageEmployees')"
                @save="updateTaskField('assignee_id', model.assignee_id)"
                @cancel="revertTaskField('assignee_id')"
              >
                <EmployeeLink :params="{ value: task.attributes.assignee_id }" :show-link="false" />
                <template #edit="{ save }">
                  <FormKit
                    v-model="model.assignee_id"
                    v-focus
                    type="employeeSelect"
                    :placeholder="$t('Employee')"
                    outer-class="col-span-6 md:col-span-2"
                    @update:model-value="save"
                  />
                </template>
              </EditableField>
            </ContentCardRow>
          </template>
        </div>
      </div>
    </div>
    <template v-if="!add" #footer>
      <div />
    </template>
  </BaseFormDialog>
</template>

<script setup>
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import axios from "axios"
import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/vue/24/outline"
import HtmlEditor from "@/components/formkit/html/HtmlEditor.vue"
import TaskStatusDropdown from "@/modules/settings/components/checklists/TaskStatusDropdown.vue"
import ContentCardRow from "@/components/common/ContentCardRow.vue"
import TaskDueDate from "@/modules/settings/components/checklists/TaskDueDate.vue"
import { error } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"
import EditableField from "@/modules/settings/components/checklists/EditableField.vue"
import AssigneeLabel from "@/modules/settings/components/checklists/AssigneeLabel.vue"
import { can } from "@/plugins/permissionPlugin"
import { DueDays, getAssigneeOptions, getDueDayOptions } from "@/modules/settings/components/checklists/utils"
import ApplicablePositionsLabel from "@/modules/settings/components/checklists/ApplicablePositionsLabel.vue"
import DueDaysLabel from "@/modules/settings/components/checklists/DueDaysLabel.vue"
import ActionsDropdown from "@/components/common/ActionsDropdown.vue"
import { TableActions } from "@/components/table/tableUtils"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"

const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
  updateStatus: {
    type: Function,
    default: async () => {
    },
  },
  isTemplate: {
    type: Boolean,
    default: false,
  },
  add: {
    type: Boolean,
  },
})

const {
  description,
  title,
  due_date,
  assignee_id,
  assignee,
  applicable_positions,
  days_to_complete,
  template_id,
  auto_create_for_new_employees,
} = props.task?.attributes || {}
const model = ref({
  title,
  description,
  due_date,
  assignee_id,
  // template
  assignee,
  applicable_positions: applicable_positions || [],
  days_to_complete,
  template_id,
  auto_create_for_new_employees,
})

const loading = ref(false)

const emit = defineEmits(['update:field', 'save', 'delete'])

const apiUrl = computed(() => {
  return props.isTemplate ? '/restify/checklist-template-tasks' : '/restify/checklist-tasks'
})

const showCustomDaysToComplete = computed(() => {
  const definedValues = getDueDayOptions().map(option => option.value).filter(value => value !== DueDays.Custom)
  return !definedValues.includes(model.value.days_to_complete)
})

const daysToCompleteValue = computed(() => {
  if (showCustomDaysToComplete.value) {
    return DueDays.Custom
  }
  return model.value.days_to_complete
})

const { t } = useI18n()

const actions = computed(() => {
  return [
    {
      label: i18n.t('Delete'),
      type: TableActions.Delete,
      icon: TrashIcon,
      action: async () => {
        const confirmed = await $deleteConfirm({
          title: t('Are you sure ?'),
          description: t('Are you sure you want to delete this task ? This action cannot be undone.'),
        })
        if (!confirmed) {
          return
        }
        try {
          await axios.delete(`${apiUrl.value}/${props.task.id}`)
          onDelete()
        } catch (err) {
          if (err.handled) {
            return
          }
          error(i18n.t('Could not delete the task'))
        }
      },
      show: () => {
        return can('manageChecklists')
      },
    },
  ]
})

async function updateTaskField(field, value) {
  const oldValue = props.task.attributes[field]
  try {
    const id = props.task.id
    onFieldUpdate(field, value)
    if (field === 'applicable_positions' && value?.length === 0) {
      value = null
    }
    if (!props.add) {
      await axios.patch(`${apiUrl.value}/${id}`, {
        [field]: value,
      })
    }
  } catch (err) {
    onFieldUpdate(field, oldValue)
    error(i18n.t('Could not update task'))
  }
}

function onDelete() {
  emit('delete', props.task.id)
}

async function saveTask() {
  try {
    loading.value = true
    const requestData = {
      ...model.value,
    }
    if (requestData.applicable_positions?.length === 0) {
      requestData.applicable_positions = null
    }
    const { data } = await axios.post(apiUrl.value, requestData)
    emit('save', data)
  } catch (err) {
    error(t('Could not save task'))
  } finally {
    loading.value = false
  }
}

function onUpdateDaysToComplete(value, save) {
  model.value.days_to_complete = value
  if (value === DueDays.Custom) {
    return
  }
  save()
}

function revertTaskField(field) {
  model.value[field] = props.task.attributes[field]
}

function onFieldUpdate(field, value) {
  emit('update:field', field, value)
}
</script>

<style lang="scss">
.task-dialog {
  #text-editor.readonly .ProseMirror {
    @apply p-0;
  }

  .content-card-row {
    @apply mb-0;
  }

  .content-card-row-title {
    @apply min-w-[100px] py-1;
  }

  .content-card-row-content {
    @apply w-full py-1 px-2;
  }
}
</style>
