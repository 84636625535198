<template>
  <BaseFormDialog
    v-if="showDialog"
    v-model="showDialog"
    :show-actions="false"
    append-to-body
    @close="goToNextCell"
    @cancel="goToNextCell"
  >
    <HtmlEditor
      ref="editor"
      v-model="value"
      :focus-on-init="true"
      :show-variables="false"
      class="col-span-6"
    />
    <template #footer>
      <div/>
    </template>
  </BaseFormDialog>
</template>

<script>
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import BaseFormDialog from "@/components/form/BaseFormDialog.vue"

export default {
  components: {
    BaseFormDialog,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
  data() {
    return {
      showDialog: true,
    }
  },
}
</script>
