<template>
  <EmployeeSelect
    v-model="value"
    v-focus
    class="w-full"
    :extra-options="params.extraOptions"
    @update:modelValue="onChange"
  />
</template>

<script>
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import EmployeeSelect from "@/components/formkit/EmployeeSelect.vue"

export default {
  components: {
    EmployeeSelect,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
}
</script>
