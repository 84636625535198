<template>
  <span :title="label" class="text-gray-600 text-sm">
    {{ label }}
  </span>
</template>

<script setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { getDueDayOptions } from "@/modules/settings/components/checklists/utils"

const props = defineProps({
  value: {
    type: Number,
  },
})
const { t } = useI18n()

const label = computed(() => {
  const option = getDueDayOptions().find(option => option.value === props.value)
  if (option) {
    return option.label
  }
  if (props.value < 0) {
    return t('days before hire date', { days: Math.abs(props.value) })
  }
  return t('days after hire date', { days: Math.abs(props.value) })
})
</script>

<style>
</style>
