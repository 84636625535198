<template>
  <BaseContentCard>
    <div class="flex items-center space-x-5 mb-5">
      <IconBox size="sm">
        <BellAlertIcon class="w-4 h-4" />
      </IconBox>
      <h3>{{ $t('Company News') }}</h3>
    </div>
    <PostNewsInput v-if="$can('manageNews')" class="mb-6"/>
    <NewsEventsList
      :data="companyNews.data"
      :loading="companyNewsLoading"
      @load-more="dashboardStore.getCompanyNews()"
    />
  </BaseContentCard>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { BellAlertIcon } from "@heroicons/vue/24/outline"
import { onMounted } from "vue"
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore"
import PostNewsInput from "@/modules/dashboard/components/news/PostNewsInput.vue"
import NewsEventsList from "@/modules/dashboard/components/news/NewsEventsList.vue"

const dashboardStore = useDashboardStore()
const { companyNews, companyNewsLoading } = storeToRefs(dashboardStore)

onMounted(async () => {
  await dashboardStore.getCompanyNews(true)
})
</script>
