<template>
  <div class="h-full">
    <FeedbackTimeline
      :title="$t('Feedback Activity')"
      :feedbackable-id="route.params.id"
      :feedbackable-type="FeedbackType.Client"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router"
import FeedbackTimeline from "@/modules/feedback/components/FeedbackTimeline.vue"
import { FeedbackType } from "@/modules/feedback/store/feedbackStore"

const route = useRoute()
</script>

<route lang="yaml">
name: Client Feedback
</route>
