<template>
  <ActionsDropdown
    v-if="statusActions"
    :actions="statusActions"
  >
    <template #trigger>
      <TaskStatusComponent :status="status" />
    </template>
  </ActionsDropdown>
</template>

<script setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { TaskStatus, taskIconMap, taskStatusClassMap } from "@/modules/settings/components/checklists/utils"
import ActionsDropdown from "@/components/common/ActionsDropdown.vue"
import TaskStatusComponent from "@/modules/settings/components/checklists/TaskStatus.vue"

const props = defineProps({
  task: {
    type: Object,
    default: () => ({}),
  },
  updateStatus: {
    type: Function,
    default: async () => {},
  },
})

const status = computed(() => {
  return props.task.attributes?.status
})

const { t } = useI18n()

const statusActions = computed(() => {
  return [
    {
      icon: taskIconMap[TaskStatus.Pending],
      label: t('Pending'),
      action: async () => {
        await props.updateStatus(TaskStatus.Pending)
      },
      show: () => {
        return true
      },
      class: taskStatusClassMap[TaskStatus.Pending],
      iconClass: '!text-gray-700',
    },
    {
      icon: taskIconMap[TaskStatus.Progress],
      label: t('Progress'),
      action: async () => {
        await props.updateStatus(TaskStatus.Progress)
      },
      show: () => {
        return true
      },
      class: taskStatusClassMap[TaskStatus.Progress],
      iconClass: '!text-primary-700',
    },
    {
      icon: taskIconMap[TaskStatus.Complete],
      label: t('Done'),
      action: async () => {
        await props.updateStatus(TaskStatus.Complete)
      },
      show: () => {
        return true
      },
      class: taskStatusClassMap[TaskStatus.Complete],
      iconClass: '!text-green-700',
    },
    {
      icon: taskIconMap[TaskStatus.Skipped],
      label: t('Skip'),
      action: async () => {
        await props.updateStatus(TaskStatus.Skipped)
      },
      show: () => {
        return true
      },
      class: taskStatusClassMap[TaskStatus.Skipped],
      iconClass: '!text-orange-700',
    },
  ]
})
</script>

<style>
</style>
