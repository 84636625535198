import { App } from 'vue'
import { createHead } from "@vueuse/head"
import i18n from "@/i18n"
import copyToClipboard from '@/plugins/copyToClipboard'
import NotificationsPlugin from '@/components/common/NotificationPlugin'
import dateFormatPlugin from '@/plugins/dateFormatPlugin'
import formKitPlugin from "@/plugins/formKitPlugin"
import priceFormatPlugin from "@/plugins/priceFormatPlugin"
import focusDirective from "@/directives/focus"
import permissionPlugin from "@/plugins/permissionPlugin"

export default {
  install(Vue: App) {
    const head = createHead()
    Vue.directive(focusDirective.name, focusDirective)
    Vue.use(i18n)
    Vue.use(copyToClipboard)
    Vue.use(dateFormatPlugin)
    Vue.use(priceFormatPlugin)
    Vue.use(NotificationsPlugin)
    Vue.use(formKitPlugin)
    Vue.use(permissionPlugin)
    Vue.use(head)
  },
}
