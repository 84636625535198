<template>
  <a
    class="flex items-center group w-full space-x-5 file-icon-info cursor-pointer no-underline text-base-content"
    :href="fileLink"
    target="_blank"
    :download="name"
  >
    <img
      v-if="isImage && canPreview"
      :src="fileLink"
      alt="name"
      class="rounded-sm shadow max-h-[200px]"
    >
    <FileIcon
      v-else
      :icon-class="typeColors.text"
      :background-class="typeColors.background"
      :extension="extension"
    />
    <div v-if="!canPreview || !isImage" class="flex flex-1 flex-col text-sm truncate">
      <span class="font-semibold mb-0.5 truncate leading-5 group-hover:text-primary group-hover:cursor-pointer">
        {{ name }}
      </span>
      <div v-if="!isUrl" class="mt-1 mr-0.5">
        <el-progress :stroke-width="2" :percentage="progress" color="--pc" />
      </div>
      <span
        v-if="fileSize"
        class="text-base-300 text-xs -mt-4"
      >
        {{ fileSize }}
      </span>
    </div>
    <div v-if="canRemove">
      <button
        class="bg-background hover:bg-base-200 h-5 w-5 rounded cursor-pointer flex items-center justify-center"
        type="button"
        @click.stop.prevent="emit('close')"
      >
        <XMarkIcon class="w-3 h-3" />
      </button>
    </div>
  </a>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { PropType, computed } from "vue"
import FileIcon from './FileIcon.vue'
import {
  getFileExtension,
  getFileType,
  getFileTypeColor,
  getHumanFileSize,
} from "@/modules/documents/utils/documentUtils"
import { downloadFileLocally } from "@/modules/common/utils/downloadFileLocally"

const props = defineProps({
  file: {
    type: [Object, String],
    default: () => ({}),
  },
  fileName: {
    type: String,
  },
  progress: {
    type: Number,
    default: 0,
  },
  canRemove: {
    type: Boolean,
    default: true,
  },
  canPreview: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])
const name = computed(() => {
  if (typeof props.file === 'string') {
    const alternativeFileName = new URL(props.file).pathname.split('/').pop()
    return props.fileName || alternativeFileName
  }
  return props.file.name
})

const isUrl = computed(() => {
  return typeof props.file === 'string' && props.file.startsWith('http')
})
const typeColors = computed(() => {
  return getFileTypeColor(name.value)
})

const fileSize = computed(() => {
  if (typeof props.file === 'string') {
    return ''
  }
  return getHumanFileSize(props.file?.size)
})

const extension = computed(() => {
  return getFileExtension(name.value)
})

const isImage = computed(() => {
  return getFileType(name.value) === 'image'
})

const fileLink = computed(() => {
  if (typeof props.file === 'string') {
    return props.file
  }
  return window.URL.createObjectURL(new window.Blob([props.file]))
})
</script>

<style>
.el-progress.el-progress--line {
  @apply flex flex-col w-full;
}

.el-progress__text,
.el-progress-bar {
  @apply w-full;
}

.el-progress__text {
  @apply flex justify-end mt-1 text-base-300;
}
</style>
