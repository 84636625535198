<template>
  <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2">
    <div class="flex flex-col space-y-2 min-w-[240px]">
      <BaseContentCard>
        <span class="text-gray-700 font-medium text-sm tracking-tight">{{ t('Actual cost') }}</span>
        <div v-if="loading" class="bg-gray-100 animate-pulse h-8 rounded-sm w-[120px] -mb-1" />
        <h2 v-else class="-mt-1 -mb-1">
          {{ formatPrice(totalActualCost, defaultCurrency) }}
        </h2>
      </BaseContentCard>
      <BaseContentCard>
        <span class="text-gray-700 font-medium text-sm tracking-tight">{{ t('Billable amount') }}</span>
        <div v-if="loading" class="bg-gray-100 animate-pulse h-8 rounded-sm w-[120px]" />
        <h2 v-else class="-mt-1">
          {{ formatPrice(totalBillableAmount, defaultCurrency) }}
        </h2>
      </BaseContentCard>
      <BaseContentCard>
        <span class="text-gray-700 font-medium text-sm tracking-tight">{{ t('Profit') }}</span>
        <div v-if="loading" class="bg-gray-100 animate-pulse h-8 rounded-sm w-[120px]" />
        <h2 v-else class="-mt-1">
          {{ formatPrice(totalProfit, defaultCurrency) }}
        </h2>
      </BaseContentCard>
    </div>
    <BaseContentCard class="w-full flex-1 min-h-[273px] relative">
      <div class="absolute flex items-center space-x-4 top-[12px] md:top-[25px] z-10">
        <ArrowButtons
          @left-click="year--"
          @right-click="year++"
        />
        <h4 class="text-2xl font-semibold flex items-center space-x-2">
          {{ t('Year ') }} {{ year }}
        </h4>
      </div>
      <VChart
        :option="option"
        theme="ovilia-green"
        autoresize
        :loading="loading"
        :loading-options="loadingOptions"
      />
    </BaseContentCard>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { registerTheme, use } from "echarts/core"
import { BarChart } from "echarts/charts"
import { DatasetComponent, GridComponent, LegendComponent, MarkLineComponent, TooltipComponent } from "echarts/components"
import { CanvasRenderer } from "echarts/renderers"
import VChart from "vue-echarts"
import axios from "axios"
import { capitalize, maxBy, sumBy } from "lodash-es"
import get from "lodash/get"
import theme from "@/components/chart/theme.json"
import BaseContentCard from "@/components/common/BaseContentCard.vue"
import { API_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import { primaryColor, redColor } from "@/modules/reports/enum/reportEnums"
import { formatPrice } from "@/plugins/formatPrice"
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import ArrowButtons from "@/components/common/ArrowButtons.vue"

const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
})
interface ChartItem {
  month: number
  actual_cost: number
  projected_cost: number
  timesheet_billable_amount: number
  invoiced_amount: number
  profit: number
  cost_breakdown: {
    expense_cost: number
    fixed_salaries: number
    holiday_cost: number
    hourly_based_salaries: number
  }
  projected_cost_breakdown: {
    expense_cost: number
    fixed_salaries: number
    holiday_cost: number
    hourly_based_salaries: number
  }
}

const data = ref<ChartItem[]>([])
const reportData = ref<any>({})

const totalActualCost = computed(() => {
  return get(reportData.value, 'totals.total_actual_cost')
})

const totalProfit = computed(() => {
  return get(reportData.value, 'totals.profit')
})

const totalBillableAmount = computed(() => {
  return get(reportData.value, 'totals.timesheet_billable_amount')
})

const BudgetType = {
  Monthly: 'monthly',
  Yearly: 'yearly',
  Fixed: 'fixed',
}

const projectBudget = computed(() => {
  const amount = get(reportData.value, 'budget.amount')
  const type = get(reportData.value, 'budget.type')
  if (type === BudgetType.Yearly) {
    return amount / 12
  }
  return amount
})

const year = ref(new Date().getFullYear())

use([
  BarChart,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  MarkLineComponent,
  CanvasRenderer,
])
registerTheme("ovilia-green", theme)

const loading = ref(false)
const { t } = useI18n()

const loadingOptions = {
  text: t("Loading..."),
  color: primaryColor,
  maskColor: "rgba(255, 255, 255, 0.6)",
}

const settingsStore = useSettingsStore()
const defaultCurrency = computed(() => settingsStore.getSetting(SettingKeys.DefaultCurrency))

function getFullMonthName(value: string) {
  return capitalize(formatDate(value, 'MMMM'))
}

const option = computed(() => {
  const markLineValue: number = projectBudget.value || 0
  const actualCosts = data.value.map(item => item.actual_cost)
  const billableAmounts = data.value.map(item => item.timesheet_billable_amount)
  const invoicedAmounts = data.value.map(item => item.invoiced_amount)
  const profits = data.value.map(item => item.profit)

  let stackAMax = Math.max(...actualCosts)
  let stackBMax = Math.max(...[...billableAmounts, ...invoicedAmounts, ...profits])
  if (actualCosts.length === 0) {
    stackAMax = 0
  }
  if (billableAmounts.length === 0) {
    stackBMax = 0
  }
  const maxValue = Math.max(...[stackAMax, stackBMax, markLineValue]) * 1.1
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
      fontWeight: 300,
    },
    tooltip: {
      trigger: 'axis',
      padding: [12, 16],
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: any) => {
        let content = `<h5 class="border-b border-gray-200 mb-3">${t('Details for ') + getFullMonthName(params[0].name)}</h5>`
        params.forEach((param: any) => {
          const formattedValue = formatPrice(param.value, defaultCurrency.value)
          content += `<div class="flex justify-between space-x-4 min-w-[180px]"><span class="font-normal">${param.marker} ${param.seriesName}</span><span class="font-semibold">${formattedValue}</span></div>`
        })
        return content
      },
    },
    legend: {
      right: 0,
      top: 0,
      itemGap: 20,
      data: [
        t('Fixed Salaries'),
        t('Hourly Salaries'),
        t('Holiday Cost'),
        t('Expenses'),
        t('Billable'),
        t('Invoiced'),
        t('Profit'),
        t('Budget'),
      ],
    },
    grid: {
      left: 5,
      right: '0%',
      top: 50,
      bottom: '0%',
      containLabel: true,
    },
    series: [
      {
        data: data.value.map(item => item.cost_breakdown.fixed_salaries),
        type: 'bar',
        stack: 'a',
        name: t('Fixed Salaries'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: primaryColor,
        },
        markLine: {
          data: markLineValue > 0 ? [{ yAxis: markLineValue, name: t('Budget') }] : [],
          label: {
            show: true, // Show label on the line
            position: 'insideStartBottom',
            formatter: t('Budget ') + formatPrice(markLineValue, defaultCurrency.value),
          },
        },
      },
      {
        data: data.value.map(item => item.cost_breakdown.hourly_based_salaries),
        type: 'bar',
        stack: 'a',
        name: t('Hourly Salaries'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#0284c7',
        },
      },
      {
        data: data.value.map(item => item.cost_breakdown.holiday_cost),
        type: 'bar',
        stack: 'a',
        name: t('Holiday Cost'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: redColor,
        },
      },
      {
        data: data.value.map(item => item.cost_breakdown.expense_cost),
        type: 'bar',
        stack: 'a',
        name: t('Expenses'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#9333ea',
        },
      },
      {
        data: data.value.map(item => item.timesheet_billable_amount),
        type: 'bar',
        stack: 'b',
        name: t('Billable'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: 'rgba(13, 148, 136, 0.5)',
        },
      },
      {
        data: data.value.map(item => item.invoiced_amount),
        type: 'bar',
        stack: 'b',
        name: t('Invoiced'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: 'rgba(234, 179, 8, 0.5)',
        },
      },
      {
        data: data.value.map(item => item.profit),
        type: 'bar',
        name: t('Profit'),
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: 'rgba(34, 197, 94, 0.5)',
        },
      },
    ],
    xAxis: {
      type: "category",
      splitLine: { show: false },
      data: data.value.map(item => item.month),
      axisLabel: {
        formatter: (value: string) => {
          return capitalize(formatDate(value, 'MMM'))
        },
      },
    },
    yAxis: {
      type: 'value',
      max: maxValue,
      axisLine: {
        show: false, // Hide the vertical line of the Y-axis
      },
      axisLabel: {
        formatter: (value: number) => {
          return formatPrice(value, defaultCurrency.value)
        },
      },
    },
  }
})

function transformData(chartData: ChartItem[]) {
  return chartData
}

function getDates(year: number) {
  const start_date = formatDate(new Date(year, 0, 1), API_DATE_FORMAT)
  const end_date = formatDate(new Date(year, 11, 31), API_DATE_FORMAT)
  return { start_date, end_date }
}

async function getData() {
  try {
    loading.value = true
    const response = await axios.get(`/restify/projects/${props.projectId}/getters/project-profitability`, {
      params: {
        ...getDates(year.value),
      },
    })
    const months = response.data?.[0]?.months || []
    reportData.value = response.data?.[0] || {}
    data.value = transformData(months)
  } finally {
    loading.value = false
  }
}

watch(() => year.value, getData)

onMounted(() => {
  getData()
})
</script>

<style>
</style>
