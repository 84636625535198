<template>
  <div class="flex flex-col h-full">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ t(route.name) }}
    </PageTitle>
    <HolidayTabs />
    <BaseDataTable
      ref="table"
      v-bind="editableTableProps"
      url="/restify/holiday-balances"
      :url-params="urlParams"
      :columns="columns"
      :transform-data="transformData"
      class="holiday-balances-table"
      @grid-init="grid = $event"
      @cell-value-changed="onCellValueChanged"
    >
      <template #actions-before>
        <div class="-mt-10 flex space-x-2">
          <FormKit
            v-model="filters.year"
            :label="$t('Year')"
            :placeholder="$t('Year')"
            type="number"
            outer-class="col-span-6"
          />

          <FormKit
            v-model="filters.holiday_policy_id"
            :label="t('Holiday Type')"
            :placeholder="t('Sick leave')"
            validation="required"
            type="holidayPolicySelect"
            outer-class="col-span-6"
          />
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  ICellEditorParams,
} from "@ag-grid-community/core"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { useTableEditing } from "@/components/table/useTableEditing"
import { updateInlineEntry } from "@/components/table/tableUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { can } from "@/plugins/permissionPlugin"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import HolidayTabs from "@/modules/holidays/components/HolidayTabs.vue"
import Data = API.Data
import { success } from "@/components/common/NotificationPlugin"
import { getEmployeeNameById } from "@/modules/clients/utils/employeeUtils"

const props = defineProps({})
const grid = ref<GridReadyEvent>()
const table = ref()

const { editableTableProps } = useTableEditing(grid, {
  ...props,
})

const settingsStore = useSettingsStore()
const filters = ref({
  holiday_policy_id: settingsStore.getVacationPolicy?.id,
  year: new Date().getFullYear(),
})

const perPage = 50

const urlParams = computed(() => {
  return {
    holiday_policy_id: filters.value?.holiday_policy_id,
    year: filters.value.year,
    active_employee: true,
    with_trashed: true,
    perPage,
  }
})
const { t } = useI18n()
const route = useRoute()

const currentYear = new Date().getFullYear()

const isCurrentYear = computed(() => {
  return urlParams.value.year === currentYear
})

const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Employee'),
      field: 'employee_id',
      type: ColumnTypes.EmployeeLink,
      minWidth: 200,
      initialHide: !can('approveRejectHolidays'),
      filter: FilterTypes.Employee,
      cellClass: 'bg-gray-50',
    },
    {
      headerName: t('Starting balance (days)'),
      field: 'initial_balance',
      type: 'rightAligned',
      headerClass: 'ag-right-aligned-header header-editable header-editable-left',
      tooltipValueGetter(params) {
        if (isEditable(params)) {
          return t('Double click to edit')
        }
        return t('This balance has been archived or transferred and is no longer editable')
      },
      cellClass: (params: CellClassParams) => {
        if (isEditable(params)) {
          return 'font-medium flex justify-end'
        }
        return 'font-medium flex justify-end bg-gray-50'
      },
      editable: isEditable,
      minWidth: 150,
      maxWidth: 250,
    },
    {
      headerName: isCurrentYear.value ? t('Current balance (days)') : t('Ending balance (days)'),
      field: 'balance',
      type: 'rightAligned',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'font-medium flex justify-end bg-gray-50',
      minWidth: 150,
      maxWidth: 250,
    },
  ]
})

function isEditable(params: any) {
  return !params.data.deleted_at
}

async function onCellValueChanged(params: CellValueChangedEvent) {
  await saveOrUpdateEntry(params)
}

function isRowValid(row: any) {
  const initialBalance = +row.initial_balance
  return !isNaN(initialBalance) && initialBalance >= 0
}

async function saveOrUpdateEntry(params: CellValueChangedEvent) {
  const row = params.data
  const isValid = isRowValid(row)
  if (!isValid) {
    return params.data
  }
  const oldData = { ...params.data }
  const entry = { ...row }
  let savedData = params.data
  try {
    params.data.loading = true
    if (!entry.id) {
      return
    }
    savedData = await updateInlineEntry({
      url: `/restify/holiday-balances/${entry.id}`,
      method: 'post',
      row: entry,
      params,
    })
    success(t(`Holiday balance updated`, {
      employee: getEmployeeNameById(entry.employee_id),
      balance: entry.initial_balance,
    }))
    params.node.setData(params.data)
  } catch (err) {
    console.error(err)
    params.node.setData(oldData)
  }
  return savedData
}

function transformData(data: Data<any>[]) {
  return data.map((item: any) => {
    return {
      id: item.id,
      ...item.attributes,
    }
  })
}

async function refresh() {
  table.value?.refresh(urlParams.value)
}
function isValidYear() {
  return filters.value.year >= 1990 && filters.value.year <= 2100
}

async function onFiltersChange() {
  await nextTick()
  await refresh()
}
watch(() => filters.value.year, async () => {
  if (!isValidYear()) {
    return
  }
  await onFiltersChange()
})
watch(() => filters.value.holiday_policy_id, async () => {
  await onFiltersChange()
})
</script>

<style lang="scss">
.holiday-balances-table {
  .el-select .el-input {
    @apply bg-transparent;
  }
}
</style>

<route lang="yaml">
name: Holidays Balances
</route>
