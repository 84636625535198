<template>
  <div class="bg-gray-50 border border-gray-200 py-1 px-2 text-gray-700 rounded-sm text-sm font-medium truncate">
    <div v-if="value?.length === 0 || !value">
      {{ $t('All positions') }}
    </div>
    <div v-else class="max-w-full truncate" :title="positionNames">
      {{ positionNames }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
})

const settingsStore = useSettingsStore()

const positionNames = computed(() => {
  return props.value?.map(positionId => settingsStore.getPositionName(positionId)).join(', ')
})
</script>

<style>
</style>
