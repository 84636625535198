<template>
  <div class="flex flex-col space-y-1 min-w-[280px] lg:min-w-[600px] -mt-4">
    <BaseDataTable
      :data="sortedData"
      :columns="columns"
      dom-layout="autoHeight"
      :hide-pagination="true"
      :hide-search="true"
      :pagination="false"
    >
      <template #attributes.amount="{ row }">
        <div>
          <EmployeeSalaryField
            :type="row.attributes.type"
            :currency="employee.salary_currency"
            :amount="row.attributes.amount"
          />
          <Badge
            v-if="Number(employee.salary) === Number(row.attributes.amount) && !row.attributes.end_date"
            class="ml-2"
          >
            {{ $t('Current') }}
          </Badge>
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from "vue-i18n"
import EmployeeSalaryField from "@/modules/employees/components/EmployeeSalaryField.vue"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { FriendlyDateFormat, formatDate } from "@/modules/common/utils/dateUtils"
import { orderBy } from "lodash-es";

const props = defineProps({
  employee: {
    type: Object,
    required: true,
  },
  data: {
    type: Array,
    default: () => [],
  },
})

const { t } = useI18n()
const sortedData = computed(() => {
  return orderBy(props.data, ['attributes.end_date', 'attributes.start_date'], ['desc', 'desc'])
})
const columns = computed(() => {
  return [
    {
      headerName: t('Amount'),
      field: 'attributes.amount',
      type: 'custom',
      minWidth: 240,
    },
    {
      headerName: t('Start Date'),
      field: 'attributes.start_date',
      minWidth: 160,
      valueFormatter: (params) => {
        if (!params.value) {
          return t('No date')
        }
        return formatDate(params.value, FriendlyDateFormat)
      },
    },
    {
      headerName: t('End Date'),
      field: 'attributes.end_date',
      minWidth: 160,
      valueFormatter: (params) => {
        if (!params.value) {
          return t('Present')
        }
        return formatDate(params.value, FriendlyDateFormat)
      },
    },
  ]
})
</script>
