<template>
  <BaseContentCard>
    <div class="flex items-center space-x-5 mb-5">
      <IconBox v-if="icon" size="sm">
        <component :is="icon" class="w-4 h-4"/>
      </IconBox>
      <h3>{{ title }}</h3>
    </div>

    <el-timeline
      ref="scrollableArea"
      class="overflow-y-scroll h-[600px] pl-0.5">
      <template v-if="loading && props.data.data?.length === 0">
        <el-timeline-item
          v-for="i in 3"
          :id="i"
          :key="i"
          v-bind="timelineItemProps"
        >
          <h5 class="bg-gray-100 animate-pulse h-5 mb-2 w-[120px]"></h5>
          <div class="bg-gray-100 animate-pulse h-[16px] mb-2 w-[100px]"></div>
          <div class="bg-gray-100 animate-pulse rounded-sm h-20 mt-5"/>

        </el-timeline-item>
      </template>

      <template v-if="!loading && props.data.data?.length === 0">
        <div class="flex h-full items-center justify-center">
          <h3>{{ $t('No activity found') }}</h3>
        </div>
      </template>

      <el-timeline-item
        v-for="activity in props.data.data"
        :id="activity.id"
        :key="activity.id"
        v-bind="timelineItemProps"
      >
        <h5>{{ $t(activity.attributes.title) }}</h5>
        <span v-if="activity.attributes.created_at" class="text-xs text-base-300">
          {{ $formatDate(activity.attributes.created_at) }}
        </span>
        <TimeLineItemContent
          v-if="getContent(activity)?.type"
          :data="getContent(activity)"
          :activity="activity"
        />
      </el-timeline-item>
      <LoadingTable v-if="loading && props.data.data?.length > 0" class="mt-4"/>
    </el-timeline>
  </BaseContentCard>
</template>
<script lang="ts" setup>
import { ClockIcon } from "@heroicons/vue/24/outline";
import TimeLineItemContent from "@/modules/dashboard/components/TimeLineItemContent.vue";
import { computed, PropType, ref } from "vue";
import { useInfiniteScroll } from "@vueuse/core";
import Data = API.Data;
import DataList = API.DataList;
import LoadingTable from "@/components/table/LoadingTable.vue";
import { DataTypes } from "@/modules/dashboard/store/dashboardStore";

const props = defineProps({
  data: {
    type: Object as PropType<DataList<any>>,
    default: () => ({}),
    required: true,
  },
  icon: {
    type: [Object, String],
    default: ClockIcon,
  },
  title: {
    type: String,
    required: true,
  },
  loading: Boolean,
})

const scrollableArea = ref()
const emit = defineEmits(['loadMore'])
useInfiniteScroll(
  scrollableArea,
  () => {
    emit('loadMore')
  },
  { distance: 20 },
)

const timelineItemProps = computed(() => {
  return {
    hideTimestamp: true,
    hollow: true,
    size: 'large',
    color: 'var(--primary-color)',
    placement: 'top',
  }
})

function getContent(activity: Data<any>) {
  const type = activity?.attributes?.type
  if (type === DataTypes.Root || !type) {
    return activity
  }
  return activity?.relationships?.timelineable
}
</script>
