<template>
  <BaseContentCard
    v-if="tasks.length > 0 && isModuleVisible(ModuleKeys.Onboarding)"
    collapsable
    :collapsed="allTasksCompleted && !defaultExpanded"
    class="relative"
  >
    <template #title>
      <div class="flex justify-between w-full items-center">
        <h3>
          {{ $t('Onboarding Tasks') }}
        </h3>
        <BaseTooltip :content="`${$t('Completed')} ${completedTasksPercentage}%`">
          <div class="flex items-center mr-1">
            <FadeTransition mode="out-in">
              <CheckCircleIcon
                v-if="allTasksCompleted"
                key="done"
                class="w-7 h-7 text-green-600"
              />
              <RadialProgress
                v-else
                key="progress"
                :progress="completedTasksPercentage"
                :thickness="2"
                class="text-primary"
                size="22"
              />
            </FadeTransition>
            <span class="text-sm text-gray-600 ml-1">
              {{ completedTasks.length }}/{{ tasks.length }}
            </span>
          </div>
        </BaseTooltip>
      </div>
    </template>
    <div class="flex flex-col space-y-2">
      <TaskCard
        v-for="task in tasks"
        :key="task.id"
        :task="task"
        @update:field="(field, value) => task.attributes[field] = value"
      />
    </div>
  </BaseContentCard>
</template>

<script setup>
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CheckCircleIcon } from "@heroicons/vue/24/solid"
import TaskCard from "@/modules/settings/components/checklists/TaskCard.vue"
import { TaskStatus } from "@/modules/settings/components/checklists/utils"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { can, isModuleVisible } from "@/plugins/permissionPlugin"
import FadeTransition from "@/components/common/transitions/FadeTransition.vue";
import { ModuleKeys } from "@/modules/auth/types/enums";

const props = defineProps({
  employeeId: {
    type: String,
  },
  defaultExpanded: {
    type: Boolean,
    default: false,
  },
})

const tasks = ref([])
const loading = ref(false)
const { t } = useI18n()

const completedTasks = computed(() => {
  return tasks.value.filter(task => task.attributes.status === TaskStatus.Complete)
})
const completedTasksPercentage = computed(() => {
  return Math.round((completedTasks.value.length / tasks.value.length) * 100)
})

const allTasksCompleted = computed(() => {
  return completedTasksPercentage.value >= 100
})

const { userEmployeeId } = useAuth()

async function getTasks() {
  const { data } = await axios.get(`/restify/checklist-tasks`, {
    params: {
      employee_id: props.employeeId,
      assignee_id: can('manageEmployees') ? undefined : userEmployeeId.value,
      sort: 'due_date',
      perPage: 100,
    },
  })
  tasks.value = data
}

onMounted(() => {
  getTasks()
})
</script>

<style>
</style>
