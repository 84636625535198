<template>
  <div>
    <div
      v-if="showTitle"
      class="flex flex-wrap items-center w-full justify-between">
      <PageTitle
        class="mb-2"
        :show-back="true"
        :loading="currentExpenseLoading"
      >
        {{ expenseName }}
      </PageTitle>
      <div class="flex flex-1 justify-end mb-2">
        <EditButton
          v-if="$can('manageEvaluations')"
          @click="goToEdit"
        />
      </div>
    </div>

    <BaseContentCard v-if="currentExpense.id">
      <div class="flex flex-wrap space-y-2 justify-between items-center mb-6">
        <h3 class="leading-none flex items-center space-x-4">
          <span>
            {{ currentExpense?.attributes?.title }}
          </span>
        </h3>
        <div class="flex flex-1 justify-end items-center space-x-4">
          <ExpenseStatus :params="{ data: currentExpense }" />
          <ActionsDropdown
            v-if="hasVisibleActions"
            :actions="actions"
          >
            <template #trigger>
              <button
                class="btn btn-outline border border-input flex items-center space-x-3
              hover:bg-white hover:border-transparent hover:text-base-content hover:shadow-lg"
              >
                <span>{{ $t('Actions') }}</span>
                <ChevronDownIcon class="w-4 h-4 text-primary" />
              </button>
            </template>
          </ActionsDropdown>
        </div>
      </div>
      <ContentCardRow :title="$t('Title')">
        <div class="flex space-x-3 items-center text-sm">
          <CalendarIcon class="w-4 h-4 text-primary" />
          <span>{{ currentExpense.attributes?.title }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Description')">
        <div class="flex space-x-3 items-center text-sm">
          <PencilIcon class="w-4 h-4 text-primary" />
          <div
            v-if="currentExpense.attributes.description"
            class="prose prose-sm"
            v-html="currentExpense.attributes.description"
          />
          <span v-else>- -</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Interval')">
        <HolidayInterval :data="currentExpense" />
      </ContentCardRow>
      <ContentCardRow :title="$t('Requested Approver')">
        <EmployeeLink
          v-if="currentExpense?.attributes?.requested_approver_id"
          :params="{ value: currentExpense.attributes.requested_approver_id }"
        />
        <div v-else class="text-sm">
          {{ $t('No requested approver') }}
        </div>
      </ContentCardRow>
      <ContentCardRow
        v-if="currentExpense.attributes.status_explanation && (isRejected || isPaid)"
        :title="isRejected ? $t('Reject reason: ') : $t('Details: ')"
      >
        <div class="flex space-x-3 items-center text-sm">
          <NoSymbolIcon v-if="isRejected" class="w-4 h-4 text-primary" />
          <CreditCardIcon v-if="isPaid" class="w-4 h-4 text-primary" />
          <span>
            {{ currentExpense.attributes.status_explanation }}
          </span>
        </div>
      </ContentCardRow>
      <!-- TODO Cannot use this because of multiple currencies -->
      <ContentCardRow v-if="false" :title="$t('Amount')">
        <div class="flex space-x-3 items-center text-sm">
          <BanknotesIcon class="w-4 h-4 text-primary" />
          <span class="font-semibold">
            {{ formatPrice(currentExpense.attributes.total_amount) }}
          </span>
        </div>
      </ContentCardRow>
    </BaseContentCard>
    <BaseContentCard
      v-if="currentExpense.id"
      :title="$t('Expenses')"
      class="mt-3 relative"
    >
      <ExpenseTable
        ref="expenseTable"
        :bundle-id="currentExpense.id"
        :editable="true"
        class="col-span-6 w-full"
      >
        <template #actions-before>
          <UploadFilesToBundle
            :bundle-id="currentExpense.id"
            @save="$refs.expenseTable?.refresh()"
          />
        </template>
      </ExpenseTable>
    </BaseContentCard>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import {
  BanknotesIcon,
  CalendarIcon,
  ChevronDownIcon, CloudArrowDownIcon, CreditCardIcon, DocumentArrowDownIcon,
  ExclamationCircleIcon,
  NoSymbolIcon,
  PencilIcon,
  PencilSquareIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { computed } from "vue"
import { formatPrice } from "../../../plugins/formatPrice"
import PageTitle from "@/components/common/PageTitle.vue"
import EditButton from "@/components/common/buttons/EditButton.vue"
import { useExpenseStore } from "@/modules/expenses/store/expenseStore"
import ExpenseTable from "@/modules/expenses/components/ExpenseTable.vue"
import ActionsDropdown from "@/components/common/ActionsDropdown.vue"
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"
import HolidayInterval from "@/modules/holidays/components/HolidayInterval.vue"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import ExpenseStatus from "@/components/table/cells/ExpenseStatus.vue"
import UploadFilesToBundle from "@/modules/expenses/components/UploadFilesToBundle.vue";

const props = defineProps({
  showTitle: {
    type: Boolean,
    default: true,
  },
})

const expenseStore = useExpenseStore()
const { expenseName, currentExpenseLoading, currentExpense } = storeToRefs(expenseStore)
const { t } = useI18n()
const router = useRouter()

const route = useRoute()

const isRejected = computed(() => {
  return currentExpense.value.attributes.status === 'rejected'
})

const isPaid = computed(() => {
  return currentExpense.value.attributes.status === 'paid'
})

const actions = computed<RowAction[]>(() => {
  return [
    {
      icon: PencilSquareIcon,
      label: t('Edit'),
      action: async () => {
        await router.push(`/expenses/${currentExpense.value.id}/edit`)
      },
      show: () => {
        return expenseStore.canEditExpenseBundle(currentExpense.value)
      },
    },
    {
      label: i18n.t('Approve'),
      icon: ShieldCheckIcon,
      action: async () => {
        await expenseStore.approveExpenseBundle(currentExpense.value)
      },
      show: () => {
        return expenseStore.canApproveExpenseBundle(currentExpense.value)
      },
    },
    {
      label: i18n.t('Reject'),
      icon: ExclamationCircleIcon,
      skipActionAwait: true,
      action: async () => {
        await expenseStore.rejectExpenseBundle(currentExpense.value)
      },
      show: () => {
        return expenseStore.canRejectExpenseBundle(currentExpense.value)
      },
    },
    {
      label: i18n.t('Mark as paid'),
      icon: CreditCardIcon,
      action: async () => {
        await expenseStore.markExpenseBundleAsPaid(currentExpense.value)
      },
      show: () => {
        return expenseStore.canMarkExpenseBundleAsPaid(currentExpense.value)
      },
    },
    {
      label: i18n.t('Export bundle'),
      icon: CloudArrowDownIcon,
      action: async () => {
        await expenseStore.downloadExpenseBundle(currentExpense.value)
      },
      show: () => true,
    },
    {
      label: i18n.t('Generate PDF report'),
      icon: DocumentArrowDownIcon,
      action: async () => {
        await expenseStore.downloadExpenseBundleAsPDF(currentExpense.value)
      },
      show: () => true,
    },
  ]
})

const hasVisibleActions = computed(() => {
  return actions.value.filter((action: RowAction) => action.show()).length > 0
})

function goToEdit() {
  const path = route.fullPath.replace('details', 'edit')
  router.push({ path })
}
</script>
