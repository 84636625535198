<template>
  <Surface class="flex items-center gap-2 p-2">
    <a :href="url" target="_blank" rel="noopener noreferrer" class="text-sm underline">
      {{ url }}
    </a>
    <ToolbarDivider />
    <Tooltip title="Edit link">
      <ToolbarButton @click="emit('edit')">
        <Icon name="Pen" />
      </ToolbarButton>
    </Tooltip>
    <Tooltip title="Remove link">
      <ToolbarButton @click="emit('clear')">
        <Icon name="Trash2" />
      </ToolbarButton>
    </Tooltip>
  </Surface>
</template>

<script setup>
import ToolbarDivider from "@/components/formkit/html/components/toolbar/ToolbarDivider.vue"
import { Tooltip } from "@/components/formkit/html/components/tooltip"
import Surface from "@/components/formkit/html/components/Surface.vue"
import ToolbarButton from "@/components/formkit/html/components/toolbar/ToolbarButton.vue"

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['edit', 'clear'])
</script>
