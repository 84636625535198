<template>
  <el-timeline
    ref="scrollableArea"
    class="overflow-y-scroll h-[500px] pl-0.5"
  >
    <template v-if="loading && data?.length === 0">
      <el-timeline-item
        v-for="i in 3"
        :id="i"
        :key="i"
        v-bind="timelineItemProps"
      >
        <h5 class="bg-gray-100 animate-pulse h-5 mb-2 w-[120px]" />
        <div class="bg-gray-100 animate-pulse h-[16px] mb-2 w-[100px]" />
        <div class="bg-gray-100 animate-pulse rounded-sm h-20 mt-5" />
      </el-timeline-item>
    </template>

    <template v-if="!loading && data?.length === 0">
      <div class="flex h-full items-center justify-center">
        <h3>{{ $t('No activity found') }}</h3>
      </div>
    </template>

    <el-timeline-item
      v-for="activity in data"
      :id="activity.id"
      :key="activity.id"
      v-bind="timelineItemProps"
    >
      <NewsEvent :activity="activity" />
    </el-timeline-item>
    <LoadingTable v-if="loading && data?.length > 0" class="mt-4" />
  </el-timeline>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from "vue"
import LoadingTable from "@/components/table/LoadingTable.vue"
import NewsEvent from "@/modules/dashboard/components/news/NewsEvent.vue"
import Data = API.Data
import { useInfiniteScroll } from "@vueuse/core"

const props = defineProps({
  data: {
    type: Array as PropType<Data<any>[]>,
    required: true,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const scrollableArea = ref()
const emit = defineEmits(['loadMore'])
useInfiniteScroll(
  scrollableArea,
  () => {
    emit('loadMore')
  },
  { distance: 20 },
)

const timelineItemProps = computed(() => {
  return {
    hideTimestamp: true,
    hollow: true,
    size: 'large',
    color: 'var(--primary-color)',
    placement: 'top',
  }
})
</script>
