<template>
  <BaseContentCard class="events-calendar">
    <div class="flex items-center space-x-5 mb-5">
      <IconBox size="sm">
        <CalendarIcon class="w-4 h-4" />
      </IconBox>
      <h3>
        {{ title || $t('Events') }}
      </h3>
      <div class="flex flex-1 justify-end items-center">
        <ShareButton
          v-if="projectId"
          class="btn-xs"
          @click="shareCalendar"
        />
      </div>
    </div>
    <BaseCalendar
      :events="calendarEvents"
      @date-select="onDateSelect"
    />
    <div class="flex justify-between">
      <div class="flex flex-col md:flex-row flex-wrap gap-4 mt-4">
        <FormKit
          id="holidays"
          v-model="filters[TimelineTypes.Holiday]"
          type="checkbox"
          :label="$t('Holidays')"
          inner-class="checkbox-holidays"
        />
        <FormKit
          id="national-holidays"
          v-model="filters[TimelineTypes.NationalHoliday]"
          type="checkbox"
          :label="$t('National Holidays')"
          inner-class="checkbox-national"
        />
        <FormKit
          v-if="!projectId"
          id="birthdays"
          v-model="filters[TimelineTypes.Birthday]"
          type="checkbox"
          inner-class="checkbox-birthdays"
          :label="$t('Birthdays')"
        />
        <FormKit
          v-if="!projectId"
          v-model="filters[TimelineTypes.Evaluation]"
          type="checkbox"
          inner-class="checkbox-evaluations"
          :label="$t('Evaluations')"
        />
        <FormKit
          v-if="!projectId"
          id="other"
          v-model="filters[TimelineTypes.Other]"
          type="checkbox"
          inner-class="checkbox-personal"
          :label="$t('Personal')"
        />
      </div>
    </div>
  </BaseContentCard>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CalendarIcon } from '@heroicons/vue/24/outline'
import { computed, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { EventInput } from '@fullcalendar/core'
import { addDays, addMinutes, parse, parseISO } from 'date-fns'
import { useClipboard, useStorage } from '@vueuse/core'
import { useSettingsStore } from '@/modules/settings/store/settingsStore'
import { TimelineTypes, useDashboardStore } from '@/modules/dashboard/store/dashboardStore'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import { API_DATE_FORMAT, CalendarDateFormat, formatDate } from '@/modules/common/utils/dateUtils'
import { useAuth } from "@/modules/auth/composables/useAuth"
import { colorMap } from "@/modules/dashboard/enum/eventColorMap"
import Data = API.Data
import LegalHoliday = App.Domains.LegalHolidays.Models.LegalHoliday
import { success } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"
import BaseCalendar from "@/modules/dashboard/components/calendar/BaseCalendar.vue"
const props = defineProps({
  projectId: {
    type: String,
  },
  title: {
    type: String,
  },
})
const dashboardStore = useDashboardStore()
const { dashboardData } = storeToRefs(dashboardStore)
const { can } = useAuth()

const key = `events-calendar-filters-${props.projectId}`
const filters = ref({
  [TimelineTypes.Birthday]: true,
  [TimelineTypes.Evaluation]: true,
  [TimelineTypes.Holiday]: true,
  [TimelineTypes.NationalHoliday]: true,
  [TimelineTypes.Other]: true,
  project_ids: [] as string[],
  start_date: undefined as string | undefined,
  end_date: undefined as string | undefined,
})

const storageFilters = useStorage(key, filters.value)
filters.value = storageFilters.value

async function onDateSelect(selectInfo: any) {
  const start_date = formatDate(selectInfo.start, API_DATE_FORMAT)
  const end_date = formatDate(selectInfo.end, API_DATE_FORMAT)
  if (start_date === filters.value.start_date && end_date === filters.value.end_date) {
    return
  }
  filters.value.start_date = start_date
  filters.value.end_date = end_date
  await getCalendarData()
}

watch(() => filters.value, (value) => {
  storageFilters.value = value
}, { deep: true })

const employeeStore = useEmployeeStore()
const settingsStore = useSettingsStore()

const { t } = useI18n()
const birthdayEvents = computed(() => {
  if (!filters.value[TimelineTypes.Birthday]) {
    return []
  }
  const calendarStart = parse(filters.value.start_date as string, 'dd-MM-yyyy', new Date())
  const calendarEnd = parse(filters.value.end_date as string, 'dd-MM-yyyy', new Date())
  const startYear = calendarStart?.getFullYear ? calendarStart.getFullYear() : new Date().getFullYear()
  const years = [startYear]
  const endYear = calendarEnd?.getFullYear ? calendarEnd.getFullYear() : new Date().getFullYear()
  if (endYear !== startYear) {
    years.push(endYear)
  }
  const birthdays: any[] = []
  years.forEach((year) => {
    dashboardData.value.birthdays.forEach((birthday: any) => {
      let start: any = parseISO(birthday.birth_date as string)
      start.setFullYear(year)
      start = addMinutes(start, -start.getTimezoneOffset())

      const event = {
        title: t('Birthday of', { name: birthday.first_name }),
        start,
        end: start,
        allDay: true,
        ...colorMap[TimelineTypes.Birthday],
        extendedProps: {
          type: TimelineTypes.Birthday,
          employee_id: birthday.id,
        },
      }
      birthdays.push(event)
    })
  })
  return birthdays
})

const legalHolidayEvents = computed(() => {
  if (!filters.value[TimelineTypes.NationalHoliday]) {
    return []
  }
  return settingsStore.legalHolidays.map((holiday: Data<LegalHoliday>) => {
    const { start_date, end_date, name } = holiday.attributes
    return {
      title: name,
      start: start_date,
      end: end_date,
      allDay: true,
      ...colorMap[TimelineTypes.NationalHoliday],
      extendedProps: {
        type: TimelineTypes.LegalHoliday,
      },
    }
  })
})

const personalEvents = computed(() => {
  if (!filters.value[TimelineTypes.Other]) {
    return []
  }
  return dashboardData.value.timelines_personal.map((event: any) => {
    const start_date = formatDate(event.start_date, CalendarDateFormat)
    const { backgroundColor, borderColor } = colorMap[event.type] || colorMap.default
    const mappedEvent: EventInput = {
      title: t(event.title),
      start: start_date,
      end: start_date,
      backgroundColor,
      borderColor,
      allDay: true,
      extendedProps: {
        type: event.type,
        employee_id: event.employee.id,
        event_id: event.id,
      },
    }
    return mappedEvent
  })
})

const evaluationEvents = computed(() => {
  if (!filters.value[TimelineTypes.Evaluation]) {
    return []
  }
  const events = dashboardData.value.evaluations.map((event: any) => {
    const {
      evaluated_at,
      type,
      name,
    } = event as any

    const start_date = formatDate(evaluated_at, CalendarDateFormat)
    const { backgroundColor, borderColor } = colorMap[type] || colorMap.default
    const mappedEvent: EventInput = {
      title: t(name),
      start: start_date,
      end: start_date,
      backgroundColor,
      borderColor,
      allDay: true,
      extendedProps: {
        type,
        employee_id: event.employee.id,
        event_id: event.id,
      },
    }
    return mappedEvent
  })
  return events
})

const holidayEvents = computed(() => {
  if (!filters.value[TimelineTypes.Holiday]) {
    return []
  }
  const events = dashboardData.value.holidays.map((event: any) => {
    const isHoliday = event.type === TimelineTypes.Holiday
    let title = ''
    if (isHoliday && event.employee) {
      title = t('Holiday of', { name: event.employee.first_name })
    }
    let {
      start_date,
      end_date,
      type,
    } = event
    if (end_date && end_date !== start_date) {
      end_date = parseISO(end_date as string)
      end_date = addDays(end_date, 1)
      end_date = formatDate(end_date, CalendarDateFormat)
    }
    start_date = formatDate(start_date, CalendarDateFormat)
    const { backgroundColor, borderColor } = colorMap[type] || colorMap.default
    const mappedEvent: EventInput = {
      title: t(title),
      start: start_date,
      end: end_date,
      backgroundColor,
      borderColor,
      allDay: true,
      extendedProps: {
        type,
        employee_id: event.employee.id,
        event_id: event.id,
      },
    }
    return mappedEvent
  })
  return events
})
const calendarEvents = computed(() => {
  return [
    ...holidayEvents.value,
    ...birthdayEvents.value,
    ...legalHolidayEvents.value,
    ...evaluationEvents.value,
    ...personalEvents.value,
  ]
})

async function getCalendarData() {
  const { start_date, end_date, project_ids } = filters.value
  if (!start_date || !end_date) {
    return
  }
  await dashboardStore.getCalendarEvents({
    project_ids,
    start_date,
    end_date,
  })
}

watch(() => filters.value.project_ids, async (project_ids) => {
  filters.value[TimelineTypes.Evaluation] = false
  filters.value[TimelineTypes.Other] = false
  await getCalendarData()
})

function setDefaultFilters() {
  if (props?.projectId) {
    filters.value.project_ids = [props.projectId as string]
    filters.value[TimelineTypes.Birthday] = true
    filters.value[TimelineTypes.Evaluation] = false
    filters.value[TimelineTypes.Other] = false
  }
}

async function shareCalendar() {
  const shareToken = await dashboardStore.shareCalendar()
  let url = `${window.location.origin}/share/calendar/${shareToken.attributes.token}`
  if (props.projectId) {
    url += `?project_id=${props.projectId}`
  }
  const { copy } = useClipboard({ source: url })
  await copy()
  success(i18n.t('Calendar share link copied to clipboard'))
}

onMounted(async () => {
  setDefaultFilters()
  await getCalendarData()
})
</script>
