import { computed } from "vue"
import { useI18n } from "vue-i18n"
import {
  BriefcaseIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon, CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  LinkIcon,
  PresentationChartLineIcon,
  ReceiptPercentIcon,
  Squares2X2Icon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/vue/24/outline"
import { SettingCard } from "@/modules/settings/types/settingTypes"
import { can, isModuleVisible } from "@/plugins/permissionPlugin"
import { ModuleKeys } from "@/modules/auth/types/enums";

export function useSettingPages() {
  const { t } = useI18n()
  const pages = computed<SettingCard[]>(() => {
    const cards: any[] = [
      {
        title: t('Account'),
        description: t('Configure your account preferences'),
        path: '/settings/account',
        icon: UserIcon,
        show: () => true,
      },
      {
        title: t('Billing'),
        description: t('Manage your billing details and subscriptions'),
        path: '/settings/billing',
        icon: CreditCardIcon,
        show: () => can('manageBilling'),
      },
      {
        title: t('Company'),
        description: t('Manage your company settings'),
        path: '/settings/company/details',
        icon: BuildingOffice2Icon,
        show: () => can('manageCompany'),
      },
      {
        title: t('Documents'),
        description: t('Prepare all the details for your company documents'),
        path: '/settings/documents',
        icon: DocumentDuplicateIcon,
        show: () => can('manageDocumentTypes') && isModuleVisible(ModuleKeys.Documents),
      },
      {
        title: t('Departments'),
        description: t('Define company level departments'),
        path: '/settings/departments',
        icon: Squares2X2Icon,
        show: () => can('manageDepartments'),
      },
      {
        title: t('Evaluations'),
        description: t('Make sure all the settings for evaluations are ready'),
        path: '/settings/evaluations',
        icon: PresentationChartLineIcon,
        show: () => can('manageEvaluationTypes') && isModuleVisible(ModuleKeys.Evaluations),
      },
      {
        title: t('Expense categories'),
        description: t('Define expense categories'),
        path: '/settings/expenses',
        icon: ReceiptPercentIcon,
        show: () => can('manageExpenses') && isModuleVisible(ModuleKeys.Expenses),
      },
      {
        title: t('Holidays'),
        description: t('Configure holiday types and policies'),
        path: '/settings/holidays',
        icon: CalendarDaysIcon,
        show: () => can('manageHolidayPolicies') && isModuleVisible(ModuleKeys.Holidays),
      },
      {
        title: t('Integrations'),
        description: t('Integrate with third party applications'),
        path: '/settings/integrations',
        icon: LinkIcon,
        show: () => true,
      },
      {
        title: t('Onboarding Templates'),
        description: t('Define onboarding templates & tasks'),
        path: '/settings/checklist-templates',
        icon: CheckBadgeIcon,
        show: () => can('manageChecklists') && isModuleVisible(ModuleKeys.Onboarding),
      },
      {
        title: t('Positions & Levels'),
        description: t('Define company positions & levels'),
        path: '/settings/positions',
        icon: UserCircleIcon,
        show: () => can('managePositions'),
      },
      {
        title: t('Templates'),
        description: t('Define document templates'),
        path: '/settings/templates',
        icon: ClipboardDocumentCheckIcon,
        show: () => can('manageTemplates') && isModuleVisible(ModuleKeys.Documents),
      },
    ]
    return cards
      .filter(card => card.show())
      .map((card: any) => {
        card.name = card.title
        card.isSettingPage = true
        return card
      })
  })
  return {
    pages,
  }
}
