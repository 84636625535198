<template>
  <ReportData
    :report-type="ReportTypes.Capacity"
    :for-project="true"
  />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import ReportData from '@/modules/reports/components/ReportData.vue'

const props = defineProps({
  projectId: String,
})

const reportsStore = useReportsStore()
const { currentReport } = storeToRefs(reportsStore)
const route = useRoute()
currentReport.value.project_id = route.params.id as string || props.projectId as string
</script>

<route lang="yaml">
name: Project capacity report
</route>
