<template>
  <TabLinks v-if="holidayTabs.length > 1" v-model="activeTab" :tabs="holidayTabs" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import { CalendarDaysIcon, ScaleIcon } from "@heroicons/vue/24/outline"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import i18n from "@/i18n"
import { can } from "@/plugins/permissionPlugin";

const { activeTab, tabs } = useFilterTabs('holidays')

const holidayTabs = computed(() => {
  const allTabs: any[] = [
    ...tabs.value,
    {
      label: i18n.t('Balances'),
      path: `/holidays/balances`,
      icon: ScaleIcon,
      show: can('manageHolidays') && can('manageEmployees'),
    },
    {
      label: i18n.t('Planner'),
      path: `/holidays/planner`,
      icon: CalendarDaysIcon,
    },
  ]
  return allTabs.filter(tab => tab.show !== false)
})
</script>
