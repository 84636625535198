<template>
  <TabLinks v-model="activeTab" :tabs="tabs" size="sm" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { UserCircleIcon } from "@heroicons/vue/24/outline"
import { BarsArrowUpIcon } from "@heroicons/vue/20/solid"
import i18n from "@/i18n"

const activeTab = ref(i18n.t('Document Types'))
const tabs = computed(() => {
  return [
    {
      label: i18n.t('Positions'),
      path: `/settings/positions`,
      icon: UserCircleIcon,
    },
    {
      label: i18n.t('Levels'),
      path: `/settings/levels`,
      icon: BarsArrowUpIcon,
    },
  ]
})
</script>
