import { ArrowTrendingUpIcon, CheckCircleIcon, ClockIcon, ForwardIcon } from "@heroicons/vue/24/outline"
import i18n from "@/i18n"
import axios from "axios";
import { error } from "@/components/common/NotificationPlugin";

export const CheckListTemplateEventTypes = {
  Onboarding: 'onboarding',
  Offboarding: 'offboarding',
}

export const ChecklistTaskAssigneeTypes = {
  Employee: 'employee',
  DirectManager: 'direct_manager',
}

export const DueDays = {
  OneWeekBeforeHireDate: -7,
  ThreeDaysBeforeHireDate: -3,
  OneDayBeforeHireDate: -1,
  OnHireDate: 0,
  OneDayAfterHireDate: 1,
  ThreeDayAfterHireDate: 3,
  OneWeekAfterHireDate: 7,
  TwoWeeksAfterHireDate: 14,
  OneMonthAfterHireDate: 30,
  Custom: 'custom',
}

export const TaskStatus = {
  Pending: 'pending',
  Progress: 'in_progress',
  Complete: 'completed',
  Skipped: 'skipped',
}

export function getDueDayOptions() {
  return [
    {
      label: i18n.t('One week before hire date'),
      value: DueDays.OneWeekBeforeHireDate,
    },
    {
      label: i18n.t('3 days before hire date'),
      value: DueDays.ThreeDaysBeforeHireDate,
    },
    {
      label: i18n.t('1 day before hire date'),
      value: DueDays.OneDayBeforeHireDate,
    },
    {
      label: i18n.t('On hire date'),
      value: DueDays.OnHireDate,
    },
    {
      label: i18n.t('1 day after hire date'),
      value: DueDays.OneDayAfterHireDate,
    },
    {
      label: i18n.t('3 days after hire date'),
      value: DueDays.ThreeDayAfterHireDate,
    },
    {
      label: i18n.t('1 week after hire date'),
      value: DueDays.OneWeekAfterHireDate,
    },
    {
      label: i18n.t('2 weeks after hire date'),
      value: DueDays.TwoWeeksAfterHireDate,
    },
    {
      label: i18n.t('1 month after hire date'),
      value: DueDays.OneMonthAfterHireDate,
    },
    {
      label: i18n.t('Custom'),
      value: DueDays.Custom,
    },
  ]
}

export function getAssigneeOptions() {
  return [
    {
      label: i18n.t('Employee (New Hire)'),
      shortLabel: i18n.t('Employee'),
      avatar: '/avatars/avatar3.png',
      nonEmployee: true,
      id: ChecklistTaskAssigneeTypes.Employee,
    },
    {
      label: i18n.t('Direct Manager (New Hire Manager)'),
      shortLabel: i18n.t('Direct Manager'),
      nonEmployee: true,
      avatar: '/avatars/avatar2.png',
      id: ChecklistTaskAssigneeTypes.DirectManager,
    },
  ]
}

export const taskStatusClassMap = {
  [TaskStatus.Pending]: 'bg-gray-50 hover:bg-gray-100 rounded-sm text-gray-700 border border-gray-600/10 py-1 px-2 font-medium text-sm min-w-[120px]',
  [TaskStatus.Progress]: 'bg-primary/5 hover:bg-primary/10 rounded-sm text-primary border border-primary/20 py-1 px-2 font-medium text-sm min-w-[120px]',
  [TaskStatus.Complete]: 'bg-green-50 hover:bg-green-100 rounded-sm text-green-700 border border-green-600/10 py-1 px-2 font-medium text-sm min-w-[120px]',
  [TaskStatus.Skipped]: 'bg-orange-50 hover:bg-orange-100 rounded-sm text-orange-700 border border-orange-600/10 py-1 px-2 font-medium text-sm min-w-[120px]',
}

export const taskIconMap = {
  [TaskStatus.Pending]: ClockIcon,
  [TaskStatus.Progress]: ArrowTrendingUpIcon,
  [TaskStatus.Complete]: CheckCircleIcon,
  [TaskStatus.Skipped]: ForwardIcon,
}

export async function _updateTaskStatus(id: string, status: string) {
  try {
    await axios.patch(`/restify/checklist-tasks/${id}`, {
      status,
    })
  } catch (err) {
    error(i18n.t('Could not update task status'))
  }
}
