<template>
  <div>
    <SendInvoiceDialog
      v-if="isModalOpen"
      v-model="isModalOpen"
      :invoice-id="invoiceId"
      @close="isModalOpen = false"
      @cancel="isModalOpen = false"
      @submit="updateInvoiceStatus"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import SendInvoiceDialog from "@/modules/invoices/components/SendInvoiceDialog.vue"
import { InvoiceStatus } from "@/modules/invoices/types/invoicEnums"

const props = defineProps({
  invoiceId: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
  },
})
const invoiceStore = useInvoiceStore()
const { t } = useI18n()
const sending = ref(false)
const route = useRoute()

const emit = defineEmits(['update:modelValue'])

const isModalOpen = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emit('update:modelValue', value)
  },
})

function updateInvoiceStatus() {
  invoiceStore.currentInvoice!.attributes.status = InvoiceStatus.Sent
  isModalOpen.value = false
}
</script>
