<template>
  <div class="h-full flex flex-col data-page">
    <BaseFormCard
      :submit-text="$t('Update')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
    >
      <FormCardSection
        v-if="hasAIAccess"
        :title="$t('Growee Assistant Context')"
      >
        <FormKit
          id="general_information"
          v-model="aiModel.general_information"
          type="html"
          :label="t('General company information')"
          :placeholder="$t('Details such as company vision, mission, values etc.')"
          :help="t('This context information will be used as a base context for the AI models. This information will be accessible to all roles. We recommend providing general details such as company mission, vision, values etc')"
          validation="length:0,4000"
          validation-visibility="dirty"
          outer-class="col-span-6 mt-2"
        />

        <FormKit
          id="sensitive_information"
          v-model="aiModel.sensitive_information"
          type="html"
          :label="t('Detailed company information')"
          :placeholder="$t('Details such as company policies, procedures, etc.')"
          :help="t('This context information will be used as a base context for the AI models. This information will be accessible to only for admins, managers and HR roles. We recommend providing more detailed information such as company policies, procedures, etc')"
          validation="length:0,4000"
          validation-visibility="dirty"
          outer-class="col-span-6 mt-6"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import axios from "axios"
import { onMounted, ref } from 'vue'
import { success } from "@/components/common/NotificationPlugin";

const { t } = useI18n()
const loading = ref(false)

const aiModel = ref({
  general_information: '',
  sensitive_information: '',
})

const initialAIModel = ref({ ...aiModel.value })
const hasAIAccess = ref(false)

async function initAIModel() {
  const { data } = await axios.get('/restify/tenants/getters/tenant-information')
  aiModel.value.general_information = data?.general_information || ''
  aiModel.value.sensitive_information = data?.sensitive_information || ''
  hasAIAccess.value = data?.has_ai_access || false
  initialAIModel.value = { ...aiModel.value }
}

async function onSubmit() {
  try {
    loading.value = true
    await axios.post(`/restify/tenants/actions?action=update-tenant`, {
      general_information: aiModel.value.general_information,
      sensitive_information: aiModel.value.sensitive_information,
    })
    success(t('Company AI context updated successfully'))
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  initAIModel()
})
</script>

<route lang="yaml">
name: Company AI Context
meta:
  permission: manageCompany
</route>
