<template>
  <div class="flex">
    <button
      type="button"
      class="btn btn-square btn-sm btn-outline rounded-r-none text-gray-600 border-gray-300 group"
      @click="emit('left-click')"
    >
      <ArrowLeftIcon class="w-4 h-4 text-gray-500 group-hover:text-gray-900" />
    </button>
    <button
      type="button"
      class="btn btn-square btn-sm btn-outline ml-[-1px] rounded-l-none text-gray-600 border-gray-300 group"
      @click="emit('right-click')"
    >
      <ArrowRightIcon class="w-4 h-4 text-gray-500 group-hover:text-gray-900" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '@heroicons/vue/24/outline'
const emit = defineEmits(['left-click', 'right-click'])
</script>
