<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="calendarsData.calendars"
    :data-loading="calendarsDataLoading"
    value-key="calendar_id"
    label-key="calendar_summary"
    :model-value="value"
    popper-class="!z-[5000]"
    :fetch-at-start="true"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { Providers, useCalendarIntegration } from "@/modules/settings/composables/useCalendarIntegration"

const props = defineProps({
  context: Object,
})

const { value, allAttrs, handleChange } = useFormKitInput(props)
const provider = props.context?.attrs?.provider || Providers.GoogleEmployee
const { calendarsData, calendarsDataLoading } = useCalendarIntegration(provider)
</script>
