<template>
  <FadeTransition>
    <BaseContentCard
      v-if="(tasks.length > 0 || showAll) && isModuleVisible(ModuleKeys.Onboarding)"
      class="max-h-[calc(100vh-60px)]"
    >
      <div class="flex items-center space-x-5 mb-5">
        <IconBox size="sm">
          <CheckBadgeIcon class="w-4 h-4"/>
        </IconBox>
        <div class="flex w-full items-center justify-between">
          <h3 class="leading-none">
            {{ $t('Assigned Tasks') }}
          </h3>
          <div v-if="!showAll">
            <BaseButton size="xs" outline @click="onSeeAll">
              {{ $t('See all tasks') }}
            </BaseButton>
          </div>
          <div v-if="showAll && can('manageEmployees')" class="-mt-8">
            <FormKit
              v-model="assigneeId"
              type="employeeSelect"
              :label="$t('Assigned To')"
              :placeholder="$t('Employee')"
              clearable="false"
              outer-class="col-span-6 md:col-span-2"
            />
          </div>
        </div>
      </div>
      <EmptyState
        v-if="tasks.length === 0 && !loading"
        :title="$t('No Tasks Found')"
        :icon="CheckBadgeIcon"
        class="mt-24"
      />
      <ConfettiAnimation v-if="completedTasksPercentage >= 100"/>
      <div
        ref="scrollableArea"
        class="overflow-y-scroll h-auto max-h-[calc(100%-20px)] pl-0.5"
      >
        <div
          v-for="(employeeTasks, employeeId) in groupedTasks"
          :key="employeeId"
          class="flex flex-col space-y-2 mb-4"
        >
          <div class="mb-2">
            <EmployeeLink :params="{ value: employeeId }"/>
          </div>
          <TaskCard
            v-for="task in employeeTasks"
            :key="task.id"
            :task="task"
            @update:field="(field, value) => task.attributes[field] = value"
          />
        </div>
      </div>
      <LoadingTable v-if="loading && tasks?.length > 0" class="mt-4"/>
    </BaseContentCard>
  </FadeTransition>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import axios from "axios"
import { groupBy } from "lodash-es"
import { useInfiniteScroll } from "@vueuse/core"
import { CheckBadgeIcon, SparklesIcon } from "@heroicons/vue/24/outline"
import { useAuth } from "@/modules/auth/composables/useAuth"
import TaskCard from "@/modules/settings/components/checklists/TaskCard.vue"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import { TaskStatus } from "@/modules/settings/components/checklists/utils"
import LoadingTable from "@/components/table/LoadingTable.vue"
import { can, isModuleVisible } from "@/plugins/permissionPlugin"
import ConfettiAnimation from "@/modules/dashboard/components/ConfettiAnimation.vue"
import FadeTransition from "@/components/common/transitions/FadeTransition.vue";
import BackgroundBubble from "@/modules/dashboard/components/news/BackgroundBubble.vue";
import { ModuleKeys } from "@/modules/auth/types/enums";

const props = defineProps({
  showAll: {
    type: Boolean,
    default: false,
  },
})

const tasks = ref([])
const tasksMeta = ref({})
const loading = ref(false)
const perPage = ref(15)
const page = ref(1)
const scrollableArea = ref()

useInfiniteScroll(
  scrollableArea,
  () => {
    page.value++
    getTasks()
  },
  { distance: 250 },
)

const groupedTasks = computed(() => {
  return groupBy(tasks.value, "attributes.employee_id")
})

const completedTasks = computed(() => {
  return tasks.value.filter(task => task.attributes.status === TaskStatus.Complete)
})

const completedTasksPercentage = computed(() => {
  return Math.round((completedTasks.value.length / tasks.value.length) * 100)
})

const { userEmployeeId } = useAuth()
const assigneeId = ref(userEmployeeId.value)

async function getTasks(force) {
  const pageParam = force ? 1 : page.value
  if (tasksMeta.value.last_page < pageParam) {
    return
  }
  try {
    loading.value = true
    const { data, meta } = await axios.get(`/restify/checklist-tasks`, {
      params: {
        "-status": props.showAll ? undefined : TaskStatus.Complete,
        "assignee_id": assigneeId.value,
        "sort": 'due_date',
        "perPage": perPage.value,
        "page": pageParam,
      },
    })
    tasksMeta.value = meta
    if (force) {
      tasks.value = data
      return
    } else {
      tasks.value = tasks.value.concat(data)
    }
  } finally {
    loading.value = false
  }
}

const router = useRouter()

function onSeeAll() {
  router.push('/onboarding-tasks')
}

watch(() => assigneeId.value, () => {
  getTasks(true)
})

onMounted(() => {
  getTasks()
})
</script>

<style>
</style>
