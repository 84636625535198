<template>
  <BaseContentCard
    v-if="history.length"
    class="mt-4 print:hidden"
  >
    <template #title>
      <h3>{{ $t('Invoice History') }}</h3>
    </template>
    <div class="divide-y divide-gray-200 border-t border-b border-gray-200 mt-4">
      <div
        v-for="item in history"
        :key="item.id"
        class="py-4"
      >
        <h5>{{ t(item.attributes.title) }}</h5>
        <div class="text-gray-600 flex items-start font-medium text-sm mt-2">
          <template v-if="item.attributes?.created_by">
            <EmployeeLink
              :params="{ value: getEmployeeForUserId(item.attributes?.created_by)?.id }"
            />
            <span class="mx-1"> {{ $t('on') }} </span>
          </template>
          <span> {{ formatDate(item.attributes.created_at, DATE_TIME_FORMAT) }}</span>
        </div>
      </div>
    </div>
  </BaseContentCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from "vue-i18n"
import { DATE_TIME_FORMAT, formatDate } from "../../common/utils/dateUtils"
import { useInvoiceStore } from "@/modules/invoices/store/invoiceStore"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import BaseContentCard from "@/components/common/BaseContentCard.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Employee = App.Domains.Employees.Models.Employee
import Data = API.Data
import { orderBy } from "lodash-es";

const eventTypes = {
  Created: 'created',
  Updated: 'updated',
  Sent: 'sent',
  Paid: 'paid',
  Late: 'late',
  Seen: 'seen',
}

const { t } = useI18n()
const eventTypeMapping = computed(() => {
  return {
    [eventTypes.Created]: t('Invoice created'),
    [eventTypes.Updated]: t('Invoice updated'),
    [eventTypes.Sent]: t('Invoice sent'),
    [eventTypes.Paid]: t('Invoice paid'),
    [eventTypes.Late]: t('Invoice late'),
    [eventTypes.Seen]: t('Invoice seen'),
  }
})
const invoiceStore = useInvoiceStore()

const history = computed(() => {
  const data = invoiceStore.currentInvoice?.relationships?.history || []
  return orderBy(data, 'attributes.created_at', 'desc')
})

const employeeStore = useEmployeeStore()
function getEmployeeForUserId(userId: string) {
  return employeeStore.allEmployees.find((employee: Data<Employee>) => employee.relationships?.user?.id === userId)
}
</script>

<style>
</style>
