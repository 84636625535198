<template>
  <div class="border border-gray-200 rounded-md px-2 py-2 flex space-x-2 post-news-input">
    <BaseAvatar
      class="h-8 w-8 rounded-full"
      :src="profile.avatar"
      :name="getEmployeeFullName(profile)"
    />
    <div class="flex-1">
      <HtmlEditor
        v-model:title="model.title"
        v-model="model.content"
        :show-top-bar="false"
        :show-footer="false"
        :enforce-title="true"
        :placeholder="getPlaceholder"
        class="flex-1 border-none overflow-auto"
      />
      <PendingFileInfo
        v-if="model.file"
        :key="model.file?.name"
        :file="model.file"
        :file-name="fileName"
        class="mt-2"
        @close="model.file = undefined"
      />
    </div>
    <div class="flex items-end">
      <BaseTooltip :content="$t('Add an attachment')" placement="bottom">
        <FileUploadButton
          class="hover:bg-gray-100 rounded-sm"
          @input="onFileChange"
        >
          <template #button>
            <BaseButton type="button" variant="link" size="xs" class="hover:bg-gray-100 hover:opacity-100">
              <PhotoIcon class="w-5 h-5 text-gray-600" />
            </BaseButton>
          </template>
        </FileUploadButton>
      </BaseTooltip>
      <BaseTooltip :content="$t('Save Post')" placement="bottom">
        <BaseButton
          :loading="loading"
          :disabled="!model.content"
          variant="link"
          size="xs"
          class="hover:bg-primary hover:opacity-100 hover:text-white"
          @click="createCompanyNews"
        >
          <PaperAirplaneIcon
            class="w-5 h-5"
          />
        </BaseButton>
      </BaseTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { PaperAirplaneIcon, PhotoIcon } from '@heroicons/vue/24/outline'
import { getEmployeeFullName } from "@/modules/clients/utils/employeeUtils"
import BaseAvatar from "@/components/common/BaseAvatar.vue"
import { useAuthStore } from "@/modules/auth/store/authStore"
import HtmlEditor from "@/components/formkit/html/HtmlEditor.vue"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore"
import { NewsStatus } from "@/modules/dashboard/components/news/newsUtils"
import PendingFileInfo from "@/modules/documents/components/PendingFileInfo.vue"
import FileUploadButton from "@/modules/invoices/components/FileUploadButton.vue"
import { error } from "@/components/common/NotificationPlugin"

const authStore = useAuthStore()
const dashboardStore = useDashboardStore()
const { profile } = storeToRefs(authStore)

const model = ref({
  title: '',
  content: '',
  emoji: '',
  status: NewsStatus.Draft,
  file: undefined as File | undefined,
})

const fileName = ref('')

const loading = ref(false)
const { t } = useI18n()

function getPlaceholder({ node, editor }: any) {
  if (node.type?.name === 'heading' && node.childCount === 0) {
    return t('Post title')
  }
  if (node.type?.name === 'paragraph' && node.childCount === 0) {
    return t('Post content')
  }
}

function onFileChange(event: Event) {
  const input = event.target as HTMLInputElement
  model.value.file = input.files?.[0]
  fileName.value = model.value.file?.name || ''
}

function getContentWithoutTitle() {
  const container = document.createElement('div')
  container.innerHTML = model.value.content
  const heading = container.querySelector('h1')
  heading?.remove()
  return container.innerHTML
}
function getFormData() {
  const formData = new FormData()
  if (model.value.title) {
    formData.append('title', model.value.title)
  }
  if (model.value.emoji) {
    formData.append('emoji', model.value.emoji)
  }
  formData.append('content', getContentWithoutTitle())
  formData.append('status', model.value.status)
  if (model.value.file) {
    formData.append('file', model.value.file)
  }
  return formData
}

function resetForm() {
  model.value = {
    title: '',
    content: '',
    emoji: '',
    status: NewsStatus.Draft,
    file: null,
  }
}

async function createCompanyNews() {
  if (!model.value.title) {
    error(t('Post title is required'))
    return
  }
  loading.value = true
  await dashboardStore.createCompanyNews(getFormData())
  resetForm()
  loading.value = false
}
</script>

<style lang="scss">
.post-news-input #text-editor .ProseMirror {
  min-height: 50px;
  @apply pb-1 pt-0 pl-4;

  h1:first-child.is-empty::before,
  h1.is-editor-empty::before {
    content: attr(data-placeholder);
  }
  p.is-editor-empty::before {
    content: attr(data-placeholder);
  }
  h1 {
    @apply text-xl;
  }
}
</style>
