<template>
  <SettingsCrudPage
    :title="$t('Onboarding Templates ')"
    :add-text="$t('Add template')"
    :edit-text="$t('Edit template')"
    view-url="/settings/checklist-templates/{id}/edit"
    edit-url="/settings/checklist-templates/{id}/edit"
    name-field="title"
    entity="checklist-templates"
    @add="router.push('/settings/checklist-templates/create')"
  />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue"

const router = useRouter()
</script>

<route lang="yaml">
name: Onboarding Templates List
meta:
  permission: manageChecklists
</route>
