<template>
  <Vue3Lottie
    v-if="showAnimation"
    background="transparent"
    :animation-link="lottieAnimationLink"
    :loop="false"
    :speed="2"
    style="position: absolute; z-index: 100;"
    @on-complete="showAnimation = false"
  />
</template>

<script setup lang="ts">
import { Vue3Lottie } from "vue3-lottie"
import { ref } from "vue"

const showAnimation = ref(true)
const lottieAnimationLink = `https://lottie.host/f0be69e7-d025-45f1-a30e-88845a728c02/J1zvVy1zPM.json`
</script>

<style>
</style>
