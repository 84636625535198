<template>
  <div
    :class="{
      'pb-4 cursor-pointer': hasLink,
      'pb-6': !hasLink,
    }"
    class="relative w-[180px] md:w-[260px] bg-white border border-base-200 rounded px-6 pt-8 pb-4 holiday-type-card"
  >
    <component
      :is="HolidayTypeIcons[type]"
      class="w-6 h-6 text-primary -mt-6 md:mt-0"
    />
    <component :is="hasLink ? 'RouterLink' : 'div'" :to="routePath" class="no-underline">
      <span
        v-if="hasBalancesForMultipleYears"
        class="absolute left-[10px] top-[10px] year-select"
      >
        <BaseSelect
          v-model="selectedYear"
          :options="balances"
          label-key="year"
          value-key="year"
        />
      </span>
      <span
        v-if="type === HolidayTypes.TimeOff"
        :class="{ 'animate-pulse': balanceLoading }"
        class="absolute flex items-center justify-center text-sm min-w-[110px] top-[10px] right-[10px] text-primary bg-primary/10 px-3 h-6"
      >
        <div v-if="!balanceLoading">
          {{ vacationBalance }} / {{ vacationInitialBalance }} {{ $t('a year') }}
        </div>
      </span>
      <h5 class="leading-none text-base-content mt-3">
        {{ $t(HolidayTypeLabels[type]) }}
      </h5>
      <p class="text-base-300 mt-2 text-sm hidden md:block">
        {{ $t(HolidayTypeDescriptions[type]) }}
      </p>
      <div v-if="hasLink" class="flex justify-end -mt-6 md:mt-2">
        <ArrowLongRightIcon class="w-6 h-6 text-primary" />
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref } from "vue"
import { ArrowLongRightIcon } from "@heroicons/vue/24/outline"
import {
  HolidayTypeDescriptions,
  HolidayTypeIcons,
  HolidayTypeLabels,
  HolidayTypes,
} from "@/modules/settings/types/settingTypes"
import ObjectValues = API.ObjectValues
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import BaseSelect from "@/components/form/BaseSelect.vue"
const props = defineProps({
  type: {
    type: String as PropType<HolidayTypeProp>,
    required: true,
  },
  hasLink: {
    type: Boolean,
    default: true,
  },
  balances: {
    type: Array,
    default: () => [],
  },
})
type HolidayTypeProp = ObjectValues<typeof HolidayTypes>
const settingsStore = useSettingsStore()
const selectedYear = ref(new Date().getFullYear()?.toString())

const hasBalancesForMultipleYears = computed(() => {
  return props.balances.length > 1
})

const selectedYearBalance = computed<any>(() => {
  return props.balances.find((balance: any) => balance.year?.toString() === selectedYear.value?.toString())
})

const vacationBalance = computed(() => {
  if (selectedYearBalance.value) {
    return selectedYearBalance.value?.balance
  }
  return settingsStore.getVacationPolicyBalance
})

const vacationInitialBalance = computed(() => {
  if (selectedYearBalance.value) {
    return selectedYearBalance.value?.initial_balance
  }
  return settingsStore.getVacationPolicyInitialBalance
})

const vacationPolicy = computed(() => {
  return settingsStore.getVacationPolicy
})

const balanceLoading = computed(() => {
  return settingsStore.holidayPoliciesLoading || settingsStore.holidayBalancesLoading
})

const routePath = computed(() => {
  return `/account/holidays/${props.type}`
})
</script>

<style lang="scss">
.holiday-type-card .year-select .base-select {
  width: 100px;
  .el-input .el-input__wrapper {
    @apply h-6 text-sm px-1 rounded-sm;
  }
}
</style>
