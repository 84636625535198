function resizeImageFile(file: File, maxDimension = 2000, callback: BlobCallback) {
  const fileReader = new FileReader()

  fileReader.onload = (event) => {
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      let width = img.width
      let height = img.height

      // Check if resizing is necessary
      if (width > maxDimension || height > maxDimension) {
        if (width > height) {
          height = Math.floor((height * maxDimension) / width)
          width = maxDimension
        } else {
          width = Math.floor((width * maxDimension) / height)
          height = maxDimension
        }
      }

      canvas.width = width
      canvas.height = height
      ctx?.drawImage(img, 0, 0, width, height)

      canvas.toBlob((blob) => {
        callback(blob)
      }, file.type)
    }
    img.src = event?.target?.result as string
  }

  fileReader.readAsDataURL(file)
}

export async function resizeImageAsync(file: File, maxDimension = 2000) {
  const blob = await new Promise<Blob>((resolve: any) => {
    resizeImageFile(file, maxDimension, resolve)
  })
  return new File([blob], file.name, { type: file.type })
}
