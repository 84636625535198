<template>
  <div>
    <PageTitle
      v-if="!isDialog"
      class="mb-2"
    >
      {{ title }}
    </PageTitle>
    <BaseFormCard
      :is-dialog="isDialog"
      :submit-text="isEdit ? $t('Update') : $t('Create')"
      :disabled="loading"
      @submit="createOrUpdateTemplate"
      @cancel="onCancel"
    >
      <FormCardSection v-if="!loading">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <Bars3BottomLeftIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Details') }}
          </h4>
        </div>

        <FormKit
          v-model="model.title"
          :label="t('Title')"
          :placeholder="t('Onboarding Template')"
          validation="length:0,100"
          outer-class="col-span-3"
        />

        <FormKit
          v-model="model.event_type"
          :label="t('Event Type')"
          :placeholder="t('Event Type')"
          :options="eventTypeOptions"
          type="customSelect"
          outer-class="col-span-3"
        />
      </FormCardSection>
      <FormCardSection v-if="!loading" container-class="max-w-full">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <CheckBadgeIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Template Tasks') }}
          </h4>
          <ChecklistTemplateTasks
            :template-id="data?.id"
          />
        </div>
      </FormCardSection>
      <FormCardLoading v-else />
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { Bars3BottomLeftIcon, CheckBadgeIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useHead } from "@vueuse/head"
import axios from "axios"
import FormCardSection from "@/components/form/FormCardSection.vue"
import { error, success } from "@/components/common/NotificationPlugin"
import PageTitle from "@/components/common/PageTitle.vue"
import FormCardLoading from "@/components/form/FormCardLoading.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { CheckListTemplateEventTypes } from "@/modules/settings/components/checklists/utils"
import ChecklistTemplateTasks from "@/modules/settings/components/checklists/ChecklistTemplateTasks.vue";

const props = defineProps({
  data: {
    type: Object,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  loading: Boolean,
})

const model = ref<any>({
  name: '',
  title: '',
  event_type: CheckListTemplateEventTypes.Onboarding,
})

watch(() => props.data, (data) => {
  if (!data?.id) {
    return
  }
  model.value = {
    ...model.value,
    ...data.attributes,
  }
}, { immediate: true })

const settingsStore = useSettingsStore()
const { t } = useI18n()

const eventTypeOptions = computed(() => {
  return [
    {
      value: CheckListTemplateEventTypes.Onboarding,
      label: t('Onboarding'),
    },
    {
      value: CheckListTemplateEventTypes.Offboarding,
      label: t('Offboarding (coming soon)'),
      disabled: true,
    },
  ]
})

const yesNoOptions = computed(() => {
  return [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ]
})

const isEdit = computed(() => {
  return props.data?.id
})
const emit = defineEmits(['save'])
const saving = ref(false)
const route = useRoute()
const router = useRouter()

async function createOrUpdateTemplate() {
  try {
    saving.value = true
    const templateId = props.data?.id
    let response
    if (isEdit.value) {
      response = await axios.put(`/restify/checklist-templates/${templateId}`, model.value)
    } else {
      response = await axios.post(`/restify/checklist-templates`, model.value)
    }
    success(t('Template saved'))
    if (!isEdit.value) {
      await router.push(`/settings/checklist-templates/${response.data.id}/edit`)
      return
    }
    if (props.isDialog) {
      emit('save', response.data)
    } else {
      await navigateBack()
    }
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not save the template'))
  } finally {
    saving.value = false
  }
}

async function navigateBack() {
  await router.push(`/settings/checklist-templates`)
}

async function onCancel() {
  await navigateBack()
}

const title = computed(() => {
  let baseTitle = isEdit.value ? t('Edit template') : t('Add template')
  if (isEdit.value) {
    baseTitle = `${baseTitle} ${props.data?.attributes?.title}`
  }
  return baseTitle
})

useHead({
  title,
})
</script>
