import { App } from 'vue'
import { useAuthStore } from "@/modules/auth/store/authStore"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const allPermissions = [
  'manageRoles',
  'managePositions',
  'manageEmployees',
  'manageGoals',
  'manageAssesments',
  'manageEvaluations',
  'manageFeedback',
  'seeSharedReceivedFeedback',
  'manageEvaluationTypes',
  'manageDocuments',
  'manageDocumentTypes',
  'manageTimesheets',
  'createDocuments',
  'manageTemplates',
  'manageChecklists',
  'manageInvoices',
  'manageExpenses',
  'manageProjects',
  'manageCompany',
  'manageBilling',
  'manageDepartments',
  'manageHolidays',
  'manageNews',
  'approveRejectHolidays',
  'manageHolidayPolicies',
  'manageHolidayBalances',
  'manageLegalHolidays',
  'createHolidaysRequest',
  'manageClients',
  'manageShareables',
  'useAi',
] as const
export type PermissionUnion = typeof allPermissions[number] | ''

/**
 * Check if user has a given permission
 * @param permission
 */
export function can(permission: PermissionUnion) {
  const authStore = useAuthStore()
  return authStore.hasPermission(permission)
}

export function isModuleVisible(moduleKey: string) {
  const settingsStore = useSettingsStore()
  return settingsStore.isModuleVisible(moduleKey)
}

export default {
  install(Vue: App) {
    Vue.config.globalProperties.$can = can
  },
}
